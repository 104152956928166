import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Box
} from '@mui/material';
import Page from '../../../components/Page';
import Loader from '../../Global/Loader';
import ApiErrorMessage from '../../Global/ApiErrorMessage';
import { AdminService } from '../../services/Services';
import DynamicForm from './DynamicForm';
import DailogBox from './DailogBox';

const { SMTPFileds, SaveSMTPData, SaveSMTPEmail } = AdminService;

function Stmp() {
  const [formState, setFormState] = useState({});
  const [openLoader, setOpenLoader] = useState(false);
  const [openApiError, setOpenApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openpopup, setOpenpop] = useState(false);
  const [emailData, setEmailData] = useState('');

  const getDetailsSTMP = async () => {
    setOpenLoader(true);
    try {
      const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));
      const token = localJWTtoken?.token?.jwt;
      const companyId = localJWTtoken?.token?.companyId;
      const responseData = await SMTPFileds(companyId, token);
      if (responseData.status === 'success') {
        setFormState(responseData?.data);
        console.log(responseData, 'line no 13');
      }
    } catch (error) {
      setErrorMessage('Failed to fetch SMTP details');
      setOpenApiError(true);
    } finally {
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    getDetailsSTMP();
  }, []);

  const inputChangeHandler = (e, key) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormState({
      ...formState,
      [key]: value
    });
  };

  const handleSubmit = async () => {
    setOpenLoader(true);
    try {
      const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));
      const token = localJWTtoken?.token?.jwt;
      const companyId = localJWTtoken?.token?.companyId;
      console.log(formState, 'line no 63');
      const credential = {
        protocolName: formState?.protocolName,
        portNo: formState?.portNo,
        id: formState?.id,
        hostName: formState?.hostName,
        senderEmail: formState?.senderEmail,
        senderPassword: formState?.senderPassword,
        starttlsEnabled: formState?.starttlsEnabled,
        userId: formState?.userId,
        debugEnabled: formState?.debugEnabled,
        authEnabled: formState?.authEnabled,
        smtpUserName: formState?.smtpUserName,
        debugOutput: formState?.debugOutput,
        smtpPassword: formState?.smtpPassword
      };
      const response = await SaveSMTPData(credential, token);

      if (response.status === 'success') {
        setOpenpop(true);
      } else {
        setErrorMessage(response.message ? response.message : response.msg);
        setOpenApiError(true);
      }
    } catch (error) {
      setErrorMessage('An error occurred');
      setOpenApiError(true);
    } finally {
      setOpenLoader(false);
    }
  };
  const handleSubmitEmail = async () => {
    setOpenLoader(true);
    try {
      const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));
      const token = localJWTtoken?.token?.jwt;
      const companyId = localJWTtoken?.token?.companyId;
      console.log(formState, 'line no 63');
      const credential = {
        email: emailData
      };
      const response = await SaveSMTPEmail(credential, token);

      if (response.status === 'success') {
        setOpenApiError(true);
        setErrorMessage(response.message ? response.message : response.msg);
        setOpenpop(false);
      } else {
        setErrorMessage(response.message ? response.message : response.msg);
        setOpenApiError(true);
      }
    } catch (error) {
      setErrorMessage('An error occurred');
      setOpenApiError(true);
    } finally {
      setOpenLoader(false);
    }
  };

  return (
    <Page title="LOS Admin - SMTP">
      <Container maxWidth="xl">
        <Box className="viewLeadCard" mb={3}>
          <Grid>
            <Grid container spacing={3}>
              <DynamicForm formState={formState} inputChangeHandler={inputChangeHandler} />
            </Grid>
          </Grid>
          <Box textAlign="right">
            <Button variant="contained" className="mainBtn" onClick={handleSubmit}>
              Save
            </Button>
          </Box>
        </Box>
      </Container>
      <DailogBox
        openpopup={openpopup}
        setOpenpop={setOpenpop}
        setEmailData={setEmailData}
        emailData={emailData}
        handleSubmitEmail={handleSubmitEmail}
      />
      <Loader openLoader={openLoader} />
      <ApiErrorMessage
        openApiError={openApiError}
        setOpenApiError={setOpenApiError}
        errorMessage={errorMessage}
      />
    </Page>
  );
}

export default Stmp;
