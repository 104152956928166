import React from 'react';
// material
import add from '@iconify/icons-ic/add';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { Box, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RootStyle, SearchStyle } from '../../Global/Styling';

function AdminUserToolBar(numSelected, filterName, onFilterName, parent, sentUrl) {
  console.log(filterName, 'line no 12');
  const navigate = useNavigate();
  const AddUserPermissions = sessionStorage.getItem('adminAccessDetails');
  const AddUserJourneyPermission = JSON.parse(AddUserPermissions);
  const isAddUserAdminAccess = AddUserJourneyPermission.filter(
    (item) => item?.adminPageCode === 'ADMIN_USER'
  );
  return (
    <RootStyle
      sx={{
        minHeight: 45,
        borderBottom: '1px solid #eaedf1'
      }}
    >
      <Box>
        <Tooltip
          disabled={isAddUserAdminAccess[0]?.addAccess === false ? true : false}
          title="Add New Admin Users"
          placement="top"
          arrow
        >
          <IconButton
            className="squareIconButton"
            onClick={(e) => {
              navigate(`/admin-user/add?u${sentUrl}`);
            }}
          >
            <Icon icon={add} width={16} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <SearchStyle
          // value={filterName}
          onChange={onFilterName}
          placeholder="Search Leads"
          classes={{
            root: 'searchHolder',
            input: 'searchInput'
          }}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
        <ToastContainer />
      </Box>
    </RootStyle>
  );
}

export default AdminUserToolBar;
