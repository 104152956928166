import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@mui/utils';
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  tableCellClasses
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
// import Loader from 'src/pages/Loader';

import Loader from '../../../pages/Global/Loader';

// styling
import { StyledTableCell } from '../../../pages/Global/Styling';
// ----------------------------------------------------------------------

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func
};

const useStyles = makeStyles((theme) => ({
  customActiveSortLabel: {
    '&.Mui-active': {
      color: '#0d4689' // Change this to your desired color
    }
  }
}));

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  isCheckBox,
  disableBoxTrue,
  disableBoxFalse,
  parent
}) {
  const classes = useStyles();
  const [openLoader, setOpenLoader] = useState(false);
  return (
    <TableHead>
      <TableRow>
        {isCheckBox && (
          <TableCell padding="checkbox">
            <Checkbox
              className={
                disableBoxTrue &&
                disableBoxFalse &&
                disableBoxTrue.length > 0 &&
                disableBoxFalse.length === 0
                  ? 'disabledDocument'
                  : 'enabledDocument'
              }
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected}
              onChange={onSelectAllClick}
              disabled={parent}
            />
          </TableCell>
        )}
        {console.log('headcell', headLabel)}
        {headLabel.map((headCell) => (
          <StyledTableCell
            className={classes.active}
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.customActiveSortLabel}
              hideSortIcon
              // active={orderBy === headCell.id}
              active={
                headCell.id === 'name' ||
                headCell.id === 'created' ||
                headCell.id === 'statusRecommendation' ||
                headCell.id === 'Status' ||
                headCell.id === 'modifiedOn' ||
                headCell.id === 'lastUpdate' ||
                headCell.id === 'status' ||
                headCell.id === 'statusLeads' ||
                headCell.id === 'sanctionedDate'
                  ? true
                  : false
              }
              // active={false}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={async (e) => {
                console.log('line no 106');
                setOpenLoader(true);
                await onRequestSort(headCell?.id);
                setOpenLoader(false);
              }}
            >
              {headCell.label}
              {(orderBy === headCell.id) === 'name' ||
              headCell.id === 'created' ||
              headCell.id === 'statusRecommendation' ||
              headCell.id === 'Status' ||
              headCell.id === 'modifiedOn' ||
              headCell.id === 'lastUpdate' ||
              headCell.id === 'status' ||
              headCell.id === 'statusLeads' ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'asc' ? 'sorted ascending' : '  sorted descending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>

      <Loader openLoader={openLoader} />
    </TableHead>
  );
}
