const initialState = {
  cardFieldsData: [],
  cardsList: [],
  fetchCardConfigs: {},
  fetchCardDetails: {},
  cardToEdit: {}
};
const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CARDS_FIELD_DATA':
      return {
        ...state,
        cardFieldsData: action?.payload?.data
      };
    case 'GET_CARDS_LIST':
      return {
        ...state,
        cardsList: action?.payload?.data
      };
    case 'FETCH_CARD_CONFIGS':
      return {
        ...state,
        fetchCardConfigs: action?.payload?.data
      };
    case 'FETCH_CARD_DETAILS':
      return {
        ...state,
        fetchCardDetails: action?.payload?.data
      };
    case 'CARD_TO_EDIT':
      return {
        ...state,
        cardToEdit: action?.payload?.data
      };
    default:
      return state;
  }
};

export default cardsReducer;
