import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';

import { sentenceCase } from 'change-case';

import { useNavigate } from 'react-router-dom';
// material
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
// components
import { fDateTime5 } from '../../../../utils/formatTime';
import BrudCrumbs from '../../../../components/BreadCrumbs';
import Label from '../../../../components/Label';
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { UserListHead, UserMoreMenu } from '../../../../components/_dashboard/user';
import PageTitle from '../../../../components/PageHeading';
import USERLIST from '../../../../_mocks_/user';
import { NOT_AVAILABLE } from '../../../Global/Constants';
// import { getConfigDetails } from '../../../../utils/getConfigdetails';
import ApiErrorMessage from '../../../Global/ApiErrorMessage';
import Loader from '../../../Global/Loader';
import { AdminService } from '../../../services/Services';
import { domainUrl } from '../../../services/globalApi';
import { getConfigDetails } from '../../../../utils/getConfigdetails';
// import UsersToolbar from '../../../pages/Recommendation';
// import UsersToolbar from 'src/pages/Admin/Users/UsersToolbar';
// import UsersToolbar from '../../Admin/Users/UsersToolbar';
import AdminUserToolBar from './AdminUserToolBar';

const TABLE_HEAD = [
  { id: 'id', label: 'USER ID', alignRight: false },
  { id: 'name', label: 'USER NAME', alignRight: false },
  { id: 'role', label: 'USER TYPE', alignRight: false },
  //   { id: 'category', label: 'USER ORGANIZATION', alignRight: false },
  { id: 'roleLavel', label: 'ACCESS LEVEL', alignRight: false },
  { id: 'created', label: 'CREATED ON', alignRight: false },
  { id: 'active', label: 'ACTIVE', alignRight: false },
  { id: '', label: 'ACTION' }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const { AdminListOfUsers, EditUser, AddUser, UserPermission, AdminDeleteUser } = AdminService;

function AddAdminUser({ sentUrl }) {
  console.log(sentUrl, 'line no 91');
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [refresh, setRefresh] = useState('');
  const [openLoader, setOpenLoader] = useState(false); // loader
  const [openApiError, setOpenApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [listOfUsersresponse, setListOfUsersresponse] = useState('');
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const AddUserPermissions = sessionStorage.getItem('adminAccessDetails');
  const AddUserJourneyPermission = JSON.parse(AddUserPermissions);
  console.log('userDetails12345', userDetails);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [currentRowId, setCurrentRowId] = useState('');
  const handleClickOpen = () => {
    setOpenDialogBox(true);
  };

  const handleClose = () => {
    setOpenDialogBox(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds =
        listOfUsersresponse?.length > 0 ? listOfUsersresponse?.map((n) => n.name) : '';
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (event.target.value !== '') {
      const filteredArray = listOfUsersresponse?.filter((element) =>
        element?.userFirstName?.toLowerCase()?.includes(event.target.value.toLowerCase())
      );
      setListOfUsersresponse(filteredArray);
    } else {
      userListApiFire();
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      textAlign: 'center'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'normal',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: 4
    }
  }));

  const handleDeleteIcon = async (userid) => {
    const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));
    const authToken =
      getConfigDetails()?.urlAppToken !== null && getConfigDetails()?.urlAppToken !== 'null'
        ? getConfigDetails()?.urlAppToken
        : null;
    const companyId =
      getConfigDetails()?.urlCompanyId !== null && getConfigDetails()?.urlCompanyId !== 'null'
        ? Number(getConfigDetails()?.urlCompanyId)
        : null;

    const deleteCredintial = {
      companyId: localJWTtoken?.token?.companyId,
      adminUserId: userid
    };
    const userDelete = await AdminDeleteUser(deleteCredintial, localJWTtoken?.token?.jwt);

    handleClose();
    setRefresh(new Date());
  };

  const userListApiFire = () => {
    setOpenLoader(true);

    const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));
    console.log(localJWTtoken, 'line no 221');

    const token = localJWTtoken?.token?.jwt;
    const authToken =
      getConfigDetails()?.urlAppToken !== null && getConfigDetails()?.urlAppToken !== 'null'
        ? getConfigDetails()?.urlAppToken
        : null;
    const companyId =
      getConfigDetails()?.urlCompanyId !== null && getConfigDetails()?.urlCompanyId !== 'null'
        ? Number(getConfigDetails()?.urlCompanyId)
        : null;
    const credentials = {
      companyId: localJWTtoken?.token?.companyId
    };
    const ListOfUsersApi = async () => {
      const ListOfUsersresponse = await AdminListOfUsers(credentials, token);
      if (ListOfUsersresponse.status === 'success') {
        setOpenLoader(false);
        setListOfUsersresponse(ListOfUsersresponse.data);
      } else {
        setOpenLoader(false);
        setListOfUsersresponse([]);
        setOpenApiError(true);
        setErrorMessage('Something went wrong !');
      }
    };
    ListOfUsersApi();
  };

  useEffect(() => {
    userListApiFire();
  }, [refresh]);
  return (
    <Page title="LOS Admin - Users">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="List of Users" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <AdminUserToolBar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="admin-user"
              sentUrl={sentUrl}
            />
            {/* <UsersToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="users"
              sentUrl={sentUrl}
            /> */}

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table border={1} borderColor="#ebedf2">
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={listOfUsersresponse?.length > 0 ? listOfUsersresponse?.length : ''}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {listOfUsersresponse?.length > 0
                      ? listOfUsersresponse?.map((userRow, index) => {
                          const isItemSelected = selected.indexOf(userRow.userFirstName) !== -1;
                          let active = '';
                          if (userRow.status === 'A') {
                            active = 'Yes';
                          } else {
                            active = 'No';
                          }

                          return (
                            <StyledTableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, name)}
                          />
                        </TableCell> */}

                              <StyledTableCell align="left">
                                {userRow?.userId ? userRow.userId : `${NOT_AVAILABLE}`}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {`${
                                  userRow?.userFirstName &&
                                  userRow?.userFirstName !== null &&
                                  userRow?.userFirstName !== undefined &&
                                  userRow?.userFirstName !== ''
                                    ? userRow?.userFirstName
                                    : ''
                                }`}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {userRow?.userTypeName ? userRow.userTypeName : `${NOT_AVAILABLE}`}
                              </StyledTableCell>
                              {/* <StyledTableCell align="left">
                                {userRow?.organizationName
                                  ? userRow.organizationName
                                  : `${NOT_AVAILABLE}`}
                              </StyledTableCell> */}
                              <StyledTableCell align="left">
                                {userRow?.adminAccessLevelName
                                  ? userRow.adminAccessLevelName
                                  : `${NOT_AVAILABLE}`}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {userRow?.createdOn &&
                                userRow.createdOn !== null &&
                                userRow.createdOn !== undefined &&
                                userRow.createdOn !== ''
                                  ? fDateTime5(userRow.createdOn)
                                  : `${NOT_AVAILABLE}`}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Label
                                  variant="ghost"
                                  color={
                                    (active === 'No' && 'error') ||
                                    (active === 'Yes' && 'success') ||
                                    'success'
                                  }
                                >
                                  {active ? sentenceCase(active) : `${NOT_AVAILABLE}`}
                                </Label>
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <UserMoreMenu
                                  rowId={userRow.userId}
                                  parent="admin-user"
                                  setCurrentRowId={setCurrentRowId}
                                  setOpenDialogBox={setOpenDialogBox}
                                  sentUrl={sentUrl}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      : ' '}
                    <Dialog
                      open={openDialogBox}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {' '}
                        Do you want to delete a user?
                      </DialogTitle>
                      <DialogActions>
                        <Button onClick={handleClose} color="error">
                          No
                        </Button>
                        <Button
                          onClick={(e) => {
                            handleDeleteIcon(currentRowId);
                          }}
                          color="success"
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {listOfUsersresponse?.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={USERLIST.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
          </Card>
        </Box>
        <Loader openLoader={openLoader} />
        <ApiErrorMessage
          openApiError={openApiError}
          setOpenApiError={setOpenApiError}
          errorMessage={errorMessage}
        />
      </Container>
    </Page>
  );
}

export default AddAdminUser;
