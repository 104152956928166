const initialState = {
  errorMsg: null,
  loader: false,
  journeyDependency: {},
  fetchDlpStatusInfo: []
};
const journeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DEPENDENCY':
      return {
        ...state,
        loader: action?.payload?.loader,
        journeyDependency: action?.payload?.data
      };
    case 'FETCH_DLP_STATUS_INFO':
      return {
        ...state,
        loader: action?.payload?.loader,
        fetchDlpStatusInfo: action?.payload?.data
      };
    case 'ERROR_MESSAGE':
      return { ...state, errorMsg: action?.payload?.data, loader: action?.payload?.loader };
    case 'LOADER_ONN_OFF':
      return { ...state, loader: action?.payload };
    default:
      return state;
  }
};

export default journeyReducer;
