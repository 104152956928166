/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Card,
  Checkbox,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { Box } from '@mui/system';

import { useNavigate, useParams } from 'react-router-dom';

import Loader from 'src/pages/Loader';
import BrudCrumbs from '../../../../components/BreadCrumbs';
import Page from '../../../../components/Page';
import PageTitle from '../../../../components/PageHeading';
import { AdminService } from '../../../services/Services';
import ApiErrorMessage from '../../../Global/ApiErrorMessage';

const { AdminOfAddAccessLevel, SuperAdminUpdateAccessLevel, SuperAdminCreateAccessLevel } =
  AdminService;

function AddEditAccess({ sentUrl }) {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'normal',
      padding: '10px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: 10
    }
  }));

  const navigate = useNavigate();
  const { rowId, rowModuleName } = useParams();
  console.log('row22222', rowId, rowModuleName);
  const [token, setToken] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [selectUserDepartment, setSelectUserDepartment] = useState(' ');
  const [selectUserModule, setSelectUserModule] = useState(' ');
  const [openLoader, setOpenLoader] = useState(false); // loader
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [openApiError, setOpenApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formChange, setFormChange] = useState({
    usertype: ' ',
    userName: '',
    userModule: ''
  });

  const [listOfAccessPagesAddEditResponse, setListOfAccessPagesAddEditResponse] = useState('');
  const [accessPagesMapping, setAccessPagesMapping] = useState([]);
  const [accessSuperAdmin, setAccessSuperAdmin] = useState([]);
  const [listOfReportAccessPagesAddEditResponse, setListOfReportAccessPagesAddEditResponse] =
    useState('');
  const [reportAccessPagesMapping, setReportAccessPagesMapping] = useState([]);
  const [createAccessLevelapi, setCreateAccessLevelapi] = useState('');

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accessChangeHandler = (e, property) => {
    setFormChange((prevState) => ({
      ...prevState,
      [property]: e.target.value
    }));
  };

  const getAccessList = async () => {
    setOpenLoader(true);
    const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));
    const authToken = localJWTtoken?.token?.jwt;
    const companyId = localJWTtoken?.token?.companyId;
    setToken(authToken);
    setCompanyId(companyId);
    const credentials = {
      companyId: localJWTtoken?.token?.companyId,
      userId: localJWTtoken.token.userId
    };

    if (rowId !== undefined) {
      credentials.adminAccessLevelId = Number(rowId);
    }

    const AddSuperAdminAccessLevel = await AdminOfAddAccessLevel(credentials, authToken);
    if (AddSuperAdminAccessLevel?.status === 'success') {
      setOpenLoader(false);
      AddSuperAdminAccessLevel.data.adminAccessPagesMappingModelList.forEach((item) => {
        if (item.isEnable === null) {
          item.isEnable = false;
        }
      });
      setAccessSuperAdmin(AddSuperAdminAccessLevel?.data.adminAccessPagesMappingModelList);

      if (rowId !== undefined) {
        setFormChange((prevState) => ({
          ...prevState,
          usertype: AddSuperAdminAccessLevel?.data?.accessLevelUserTypeId,
          userName: AddSuperAdminAccessLevel?.data?.accessLevelName,
          userModule: AddSuperAdminAccessLevel?.data?.accessModuleName
        }));
        setAccessPagesMapping(AddSuperAdminAccessLevel?.data?.accessPagesMapping);
        setRefreshComp(new Date());
      }
    }
    if (rowId !== undefined) {
      credentials.adminAccessLevelId = Number(rowId);
    }
    if (rowModuleName !== undefined && rowModuleName !== null && rowModuleName !== '') {
      setSelectUserModule(rowModuleName);
    }
  };

  const createAccessHandle = async (e) => {
    const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));
    console.log(accessSuperAdmin, 'line no 141');
    console.log(rowId, 'line no 142');
    if (rowId !== undefined) {
      const updateCredential = {
        adminAccessLevelId: Number(rowId),
        accessLevelCode: accessSuperAdmin?.accessLevelTypeId,
        accessLevelName: formChange?.userName,
        accessLevelDescription: null,
        companyId: localJWTtoken?.token?.companyId,
        createdOn: accessSuperAdmin.createdOn,
        lastUpdatedOn: '',
        status: 'A',
        adminAccessPagesMappingModelList: [...accessSuperAdmin]
      };
      setOpenLoader(true);
      const UpdateAccessLevelApi = await SuperAdminUpdateAccessLevel(updateCredential, token);
      if (UpdateAccessLevelApi?.status === 'success') {
        setOpenLoader(false);
        navigate(`/admin-access?u${sentUrl}`);
      } else {
        setOpenLoader(false);
        setOpenApiError(true);
        setErrorMessage(
          UpdateAccessLevelApi?.message ? UpdateAccessLevelApi?.message : 'Something went wrong!'
        );
      }
    } else {
      const createLevelCredential = {
        accessLevelName: formChange?.userName,
        companyId: localJWTtoken?.token?.companyId,
        // accessLevelUserTypeId: formChange?.usertype,
        adminAccessPagesMappingModelList: [...accessSuperAdmin]
      };
      setOpenLoader(true);
      const CreateAccessLevelapiResponse = await SuperAdminCreateAccessLevel(
        createLevelCredential,
        token
      );
      if (CreateAccessLevelapiResponse?.status === 'success') {
        setCreateAccessLevelapi(CreateAccessLevelapiResponse.data);
        setOpenLoader(false);
        navigate(`/admin-access?u${sentUrl}`);
      } else {
        setOpenLoader(false);
        setOpenApiError(true);
        setErrorMessage(
          CreateAccessLevelapiResponse?.message
            ? CreateAccessLevelapiResponse?.message
            : 'Something went wrong !'
        );
      }
    }
  };

  useEffect(() => {
    getAccessList();
  }, []);

  // isEnable;
  // useEffect(() => {
  //   const isEnableKey = accessSuperAdmin.map((item) => {
  //     if (item.isEnable === null) {
  //       item.isEnable = false;
  //     }
  //     if (
  //       item.editAccess === true ||
  //       item.viewAccess === true ||
  //       item.publishAccess === true ||
  //       item.addAccess === true
  //     ) {
  //       return item.isEnable === true;
  //     } else {
  //       return item.isEnable === false;
  //     }
  //   });
  //   console.log(isEnableKey, 'isENableKey111');
  // }, [accessSuperAdmin]);

  return (
    <Page title="LOS Admin - Manage Access">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info={rowId ? 'Edit Access Rights' : 'Set Up Access Rights'} />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Box className="viewLeadCard" mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="h6" className="formLabel">
                  ACCESS NAME
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  value={formChange.userName}
                  placeholder="Type user name..."
                  onChange={(e) => accessChangeHandler(e, 'userName')}
                />
              </Grid>
            </Grid>
          </Box>

          <Box>
            <TableContainer>
              <Table border={1} borderColor="#ebedf2">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Topics</StyledTableCell>
                    <StyledTableCell>Edit</StyledTableCell>
                    <StyledTableCell>Add</StyledTableCell>
                    <StyledTableCell>View</StyledTableCell>
                    <StyledTableCell>Publish</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accessSuperAdmin?.map((subTab, subTabIndex) => (
                    <StyledTableRow key={subTabIndex} hover role="checkbox">
                      <StyledTableCell align="left">
                        <strong>{subTab?.adminPageName}</strong>
                      </StyledTableCell>

                      <TableCell
                        padding="checkbox"
                        sx={{ textAlign: 'right', paddingRight: '1rem' }}
                      >
                        <Checkbox
                          sx={{
                            padding: '0',
                            color: '#4FBBC8',
                            '&.Mui-checked': {
                              color: '#4FBBC8'
                            }
                          }}
                          checked={subTab?.editAccess}
                          onClick={(e) => {
                            const prevData = accessSuperAdmin;
                            console.log(prevData, 'line no 289');
                            if (e.target.checked) {
                              prevData[subTabIndex].editAccess = true;
                              prevData[subTabIndex].viewAccess = true;
                              prevData[subTabIndex].isEnable = true;
                            } else {
                              prevData[subTabIndex].editAccess = false;
                              prevData[subTabIndex].isEnable = false;
                            }
                            setAccessSuperAdmin([...prevData]);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="checkbox"
                        sx={{ textAlign: 'right', paddingRight: '1rem' }}
                      >
                        <Checkbox
                          sx={{
                            padding: '0',
                            color: '#4FBBC8',
                            '&.Mui-checked': {
                              color: '#4FBBC8'
                            }
                          }}
                          checked={subTab?.addAccess}
                          onClick={(e) => {
                            const prevData = accessSuperAdmin;
                            console.log(prevData, 'line no 289');
                            if (e.target.checked) {
                              prevData[subTabIndex].addAccess = true;
                              prevData[subTabIndex].viewAccess = true;
                              prevData[subTabIndex].isEnable = true;
                            } else {
                              prevData[subTabIndex].addAccess = false;
                              prevData[subTabIndex].isEnable = false;
                            }
                            setAccessSuperAdmin([...prevData]);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="checkbox"
                        sx={{ textAlign: 'right', paddingRight: '1rem' }}
                      >
                        <Checkbox
                          sx={{
                            padding: '0',
                            color: '#4FBBC8',
                            '&.Mui-checked': {
                              color: '#4FBBC8'
                            }
                          }}
                          checked={subTab.viewAccess}
                          disabled={subTab?.viewAccess === null ? true : false}
                          onClick={(e) => {
                            const prevData = accessSuperAdmin;
                            console.log(prevData, 'line no 289');
                            // prevData[subTabIndex].viewAccess = e.target.checked;
                            // prevData[subTabIndex].isEnable = true;
                            if (e.target.checked) {
                              prevData[subTabIndex].viewAccess = true;
                              prevData[subTabIndex].isEnable = true;
                            } else {
                              prevData[subTabIndex].viewAccess = false;
                              prevData[subTabIndex].isEnable = false;
                              prevData[subTabIndex].editAccess = false;
                              // prevData[subTabIndex].isEditEnabled = false;
                              prevData[subTabIndex].addAccess = false;
                              prevData[subTabIndex].deleteAccess = false;
                              prevData[subTabIndex].publishAccess = false;
                            }
                            setAccessSuperAdmin([...prevData]);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="checkbox"
                        sx={{ textAlign: 'right', paddingRight: '1rem' }}
                      >
                        <Checkbox
                          sx={{
                            padding: '0',
                            color: '#4FBBC8',
                            '&.Mui-checked': {
                              color: '#4FBBC8'
                            }
                          }}
                          disabled={subTab?.publishAccess === null ? true : false}
                          checked={subTab?.publishAccess}
                          onClick={(e) => {
                            const prevData = accessSuperAdmin;
                            console.log(prevData, 'line no 289');
                            if (e.target.checked) {
                              prevData[subTabIndex].publishAccess = true;
                              prevData[subTabIndex].viewAccess = true;
                              prevData[subTabIndex].isEnable = true;
                            } else {
                              prevData[subTabIndex].publishAccess = false;
                              prevData[subTabIndex].isEnable = false;
                            }
                            setAccessSuperAdmin([...prevData]);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="checkbox"
                        sx={{ textAlign: 'right', paddingRight: '1rem' }}
                      >
                        <Checkbox
                          sx={{
                            padding: '0',
                            color: '#4FBBC8',
                            '&.Mui-checked': {
                              color: '#4FBBC8'
                            }
                          }}
                          disabled={subTab?.isEditEnabled}
                          checked={subTab?.deleteAccess}
                          onClick={(e) => {
                            const prevData = accessSuperAdmin;
                            console.log(prevData, 'line no 289');
                            if (e.target.checked) {
                              prevData[subTabIndex].deleteAccess = true;
                              prevData[subTabIndex].viewAccess = true;
                              prevData[subTabIndex].isEnable = true;
                            } else {
                              prevData[subTabIndex].deleteAccess = false;
                              prevData[subTabIndex].isEnable = false;
                            }
                            setAccessSuperAdmin([...prevData]);
                          }}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className="" textAlign="right">
            <Button
              className="mainBtn"
              variant="contained"
              onClick={(e) => {
                createAccessHandle(e);
                console.log(rowId, 'line no 390');
              }}
            >
              {rowId !== undefined ? 'Update' : 'Save'}
            </Button>
          </Box>
        </Box>
      </Container>
      <Loader openLoader={openLoader} />
      <ApiErrorMessage
        openApiError={openApiError}
        setOpenApiError={setOpenApiError}
        errorMessage={errorMessage}
      />
    </Page>
  );
}

export default AddEditAccess;
