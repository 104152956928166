import React from 'react';
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControlLabel
} from '@mui/material';

function DynamicForm({ formState, inputChangeHandler }) {
  const keyLabelMap = {
    protocolName: 'Protocol Name',
    senderEmail: 'Sender Email',
    senderPassword: 'Sender Password',
    hostName: 'Host Name',
    authEnabled: 'Auth Enabled',
    starttlsEnabled: 'StartTLS Enabled',
    debugEnabled: 'Debug Enabled',
    smtpUserName: 'User Name',
    smtpPassword: 'Password',
    portNo: 'Port No',
    debugOutput: 'Debug Output'
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field Name</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(formState)
            .filter(([key]) => key !== 'id' && key !== 'userId')
            .map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>
                  <Typography variant="h6" className="formLabel">
                    {keyLabelMap[key] || key}
                    <Typography component="span" color="error">
                      {' '}
                      *
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell>
                  {typeof value === 'boolean' ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(e) => inputChangeHandler(e, key)}
                          color="primary"
                        />
                      }
                      label=""
                    />
                  ) : (
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder={`Type your ${keyLabelMap[key]?.toLowerCase() || key}`}
                      value={value}
                      onChange={(e) => inputChangeHandler(e, key)}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DynamicForm;
