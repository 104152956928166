import { sentenceCase } from 'change-case';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
// components
import BrudCrumbs from '../../../../components/BreadCrumbs';
import Label from '../../../../components/Label';
import Page from '../../../../components/Page';
import PageTitle from '../../../../components/PageHeading';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import { UserListHead, UserMoreMenu } from '../../../../components/_dashboard/user';
//
import { AdminService } from '../../../services/Services';
import USERLIST from '../../../../_mocks_/user';
import { fDate, fDateTime2, fDateTime5 } from '../../../../utils/formatTime';
// import ManageAccessToolbar from './ManageAccessToolbar';
// import ManageAccessToolbar from 'src/pages/Admin/Manage/ManageAccessToolbar';
// import ManageAccessToolbar from '../../../Admin/Manage/ManageAccessToolbar';
import { NOT_AVAILABLE } from '../../../Global/Constants';
import Loader from '../../../Global/Loader';
import ApiErrorMessage from '../../../Global/ApiErrorMessage';
import AdminAcessToolbar from './AdminAcessToolbar';

// ----------------------------------------------------------------------

const { AdminOfAccessLevel, AccessLevelDelete, ReportAccessLevelDelete, AdminOfAccessLevelDelete } =
  AdminService;

const TABLE_HEAD = [
  { id: 'id', label: 'ACCESS ID', alignRight: false },
  { id: 'name', label: 'ACCESS NAME', alignRight: false },
  // { id: 'category', label: 'PRODUCT CATEGORY', alignRight: false },
  // { id: 'role', label: 'PRODUCT SUB CATEGORY', alignRight: false },
  { id: 'created', label: 'CREATED ON', alignRight: false },
  { id: 'modifiedOn', label: 'MODIFIED ON', alignRight: false },
  { id: 'active', label: 'ACTIVE', alignRight: false },
  { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function AdminAccess({ sentUrl }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [listAccessResponse, setListAccessResponse] = useState([]);
  const [refresh, setRefresh] = useState('');
  const [openLoader, setOpenLoader] = useState(false); // loader
  const [openApiError, setOpenApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [accessLevelId, setAccessLevelId] = useState();
  // delete dialog function
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [currentRowId, setCurrentRowId] = useState('');

  const handleClickOpen = () => {
    setOpenDialogBox(true);
  };

  const handleClose = () => {
    setOpenDialogBox(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (event.target.value !== '') {
      const filteredArray = listAccessResponse?.filter((element) =>
        element?.accessLevelName?.toLowerCase()?.includes(event.target.value.toLowerCase())
      );
      setListAccessResponse(filteredArray);
    } else {
      setRefresh(new Date());
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'normal',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: 4
    }
  }));

  // delete function
  const handleDeleteIcon = async (adminAccessLevelId, userid) => {
    console.log(userid, 'line no 172');
    const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));
    const authToken = localJWTtoken?.token?.jwt;

    const deleteCredintial = {
      companyId: localJWTtoken.token.companyId,
      userid: localJWTtoken.token.userId,
      adminAccessLevelId: adminAccessLevelId
    };

    const userDelete = await AdminOfAccessLevelDelete(deleteCredintial, authToken);
    if (userDelete.status === 'success') {
      handleClose();
      setRefresh(new Date());
    }
  };
  useEffect(() => {
    const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));
    console.log(localJWTtoken, 'line no 222');
    const authToken = localJWTtoken?.token?.jwt;
    const companyId = localJWTtoken?.token?.companyId;
    const credentials = {
      companyId: localJWTtoken?.token?.companyId,
      userId: localJWTtoken?.token?.userId
      // "accessLevelId":1
    };

    const listOfAccess = async () => {
      setOpenLoader(true);
      const listOfAccesspages = await AdminOfAccessLevel(credentials, authToken);
      if (listOfAccesspages?.status === 'success') {
        setListAccessResponse(listOfAccesspages.data);
        setOpenLoader(false);
      } else if (listOfAccesspages.error === 'error') {
        setOpenLoader(false);
        setOpenApiError(true);
        setErrorMessage('Something went wrong !');
      } else {
        setOpenLoader(false);
        setOpenApiError(true);
        setErrorMessage('Something went wrong !');
      }
    };
    listOfAccess();
  }, [refresh]);
  return (
    <Page title="LOS Admin - Admin Access">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info="Admin Access" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card>
            {/* <ManageAccessToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="admin-access"
              sentUrl={sentUrl}
            /> */}
            <AdminAcessToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="admin-access"
              sentUrl={sentUrl}
            />
            {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            parent="manage-access"
          /> */}

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, height: '70vh', overflow: 'auto' }}>
                <Table border={1} borderColor="#ebedf2">
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {listAccessResponse?.length > 0
                      ? listAccessResponse?.map((row, index) => {
                          console.log('listAccessResponse', row);
                          const isItemSelected = selected.indexOf(row.accessLevelName) !== -1;
                          let active = '';
                          if (row.status === 'A') {
                            active = 'Yes';
                          } else {
                            active = 'No';
                          }

                          return (
                            <>
                              <StyledTableRow
                                hover
                                key={index}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, name)}
                            />
                          </TableCell> */}

                                <StyledTableCell align="left">
                                  {row?.adminAccessLevelId
                                    ? row.adminAccessLevelId
                                    : `${NOT_AVAILABLE}`}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row?.accessLevelName ? row.accessLevelName : `${NOT_AVAILABLE}`}
                                </StyledTableCell>
                                {/* <StyledTableCell align="left">'category'</StyledTableCell> */}
                                {/* <StyledTableCell align="left">{role}</StyledTableCell> */}
                                <StyledTableCell align="left">
                                  {row?.createdOn &&
                                  row.createdOn !== null &&
                                  row.createdOn !== undefined &&
                                  row.createdOn !== ''
                                    ? fDateTime5(row.createdOn)
                                    : `${NOT_AVAILABLE}`}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row?.lastUpdatedOn &&
                                  row.lastUpdatedOn !== null &&
                                  row.lastUpdatedOn !== undefined &&
                                  row.lastUpdatedOn !== ''
                                    ? fDateTime5(row.lastUpdatedOn)
                                    : `${NOT_AVAILABLE}`}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Label
                                    variant="ghost"
                                    color={
                                      (active === 'No' && 'error') ||
                                      (active === 'Yes' && 'success') ||
                                      'success'
                                    }
                                  >
                                    {active ? sentenceCase(active) : `${NOT_AVAILABLE}`}
                                  </Label>
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  <UserMoreMenu
                                    rowId={row.adminAccessLevelId}
                                    rowModuleName={row?.accessLevelName}
                                    parent="admin-access"
                                    setCurrentRowId={setCurrentRowId}
                                    setOpenDialogBox={setOpenDialogBox}
                                    sentUrl={sentUrl}
                                  />
                                </StyledTableCell>
                              </StyledTableRow>
                              <Dialog
                                open={openDialogBox}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {' '}
                                  Do you want to delete the access level?
                                </DialogTitle>
                                <DialogActions>
                                  <Button onClick={handleClose} color="error">
                                    No
                                  </Button>
                                  <Button
                                    onClick={(e) => {
                                      handleDeleteIcon(currentRowId, row);
                                    }}
                                    color="success"
                                    autoFocus
                                  >
                                    Yes
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </>
                          );
                        })
                      : ''}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {listAccessResponse?.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Box>
      </Container>
      <Loader openLoader={openLoader} />
      <ApiErrorMessage
        openApiError={openApiError}
        setOpenApiError={setOpenApiError}
        errorMessage={errorMessage}
      />
    </Page>
  );
}

export default AdminAccess;
