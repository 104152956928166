import trash2Outline from '@iconify/icons-eva/trash-2-outline';
// import editFill from '@iconify/icons-ic/drag-handle';
import editFill from '@iconify/icons-eva/edit-fill';
import { Icon } from '@iconify/react';
import CryptoJS from 'crypto-js';
import { useState } from 'react';
import { AiFillEdit, AiOutlineCheck, AiFillTool } from 'react-icons/ai';
import { BiCloudDownload, BiCloudUpload, BiRun, BiShareAlt } from 'react-icons/bi';
import { BsFilePdf, BsFillReplyAllFill, BsLink } from 'react-icons/bs';
import { FaFileInvoice, FaFolderOpen, FaRegMoneyBillAlt, FaTools } from 'react-icons/fa';
import { FiEye, FiAnchor } from 'react-icons/fi';
import {
  MdAppRegistration,
  MdModeEdit,
  MdOutlineDateRange,
  MdOutlineVerifiedUser,
  MdPayments,
  MdPublishedWithChanges,
  MdReviews,
  MdUpdate
} from 'react-icons/md';
import { useSelector } from 'react-redux';

import { RiFileCopyLine, RiFileShield2Line, RiFlowChart } from 'react-icons/ri';
import { TbCalculator, TbFileReport, TbReport, TbReportMoney } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
// material
import styled from '@emotion/styled';
import { Box, IconButton, Tooltip } from '@mui/material';
// ----------------------------------------------------------------------
import { domainUrl } from '../../../pages/services/globalApi';
import { partnerService } from '../../../pages/services/Services';
import { getConfigDetails } from '../../../utils/getConfigdetails';

const { OnBoardReview } = partnerService;

export default function UserMoreMenu({
  moduleName,
  partner,
  rowId,
  rowModuleName,
  assignedTo,
  parent,
  setOpenStatus,
  UpdatePartnerStatusApi,
  partnerState,
  setOpenOpportunity,
  setOpenSanctioned,
  setOpenRejected,
  setOpenSendMsg,
  pageMenu,
  companyId,
  applicationId,
  applicationStatus,
  statusId,
  statusName,
  item,
  index,
  handleChequeUpload,
  itemObj,
  handleDelete,
  handleOpenDelete,
  bankId,
  bankName,
  setOpenPayment,
  setBranchEdit,
  setIndividualCommissionEdit,
  setVolumeCommissionEdit,
  setDeleteConfirm,
  itemBranchIndex,
  branchIndex,
  branchEditDialogOpen,
  branchDeleteDialogOpen,
  itemList,
  indexValue,
  individualEditDialogOpen,
  individualDeleteDialogOpen,
  itemListVolume,
  indexValueVolume,
  volumeEditDialogOpen,
  volumeDeleteDialogOpen,
  setEndAgreement,
  handlePublish,
  openEndAgreementDialog,
  // viewPolicyAgreementId,
  aggrementIsActive,
  revenueId,
  leadId,
  handleCopyDialog,
  templateName,
  setSharePopUp,
  setDocumentPreview,
  recommendationApiFire,
  docPrevHandler,
  verifyPopup,
  setVerifyPopup,
  deleteDocPopup,
  setDeleteDocPopup,
  handleViewSupportDocs,
  viewUploadedCheque,
  setOpenSyncCersai,
  setOpenCreateCharge,
  handleFileUpload,
  setUploadImage,
  setViewPopup,
  convertBytecodeToPDF,
  uploadedCheque,
  handleLegalDocumentUpload,
  viewPopup,
  handleDownload,
  parentComponent,
  sentUrl,
  setCurrentRowId,
  setOpenDialogBox,
  reportJourneyId,
  fieldVisitType,
  auditRatingEvaluationId,
  securityEvaluationId,
  evaluationType
}) {
  const getUserPermissions = sessionStorage.getItem('userPermission');
  let leadDetails = sessionStorage.getItem('leadDetails');
  leadDetails = JSON.parse(leadDetails);

  const getUserPermission = JSON.parse(getUserPermissions);
  const [partnerReviewData, setPartnerReviewData] = useState({});
  const myState = useSelector((state) => state?.legal_verification_details);
  const userDetails = useSelector((state) => state?.loginReducer?.loginInfo);
  const AddUserPermissions = sessionStorage.getItem('adminAccessDetails');
  const AddUserJourneyPermission = JSON.parse(AddUserPermissions);
  const isAddAccess = AddUserJourneyPermission?.filter((item) => item?.adminPageCode === 'JOURNEY');
  const isReportDisabled = AddUserJourneyPermission?.filter(
    (item) => item?.adminPageCode === 'REPORTS'
  );
  const isUserListDisabled = AddUserJourneyPermission?.filter(
    (item) => item?.adminPageCode === 'USERS'
  );
  const isManageAccessDisabled = AddUserJourneyPermission?.filter(
    (item) => item?.adminPageCode === 'MANAGE_ACCESS'
  );
  const isAdminAccessDisabled = AddUserJourneyPermission?.filter(
    (item) => item?.adminPageCode === 'ADMIN_ACCESS'
  );
  const isUserAccessDisabled = AddUserJourneyPermission?.filter(
    (item) => item?.adminPageCode === 'ADMIN_USER'
  );

  // console.log('rowIdrowIdrowIdsentUrl', rowId);
  // console.log('partnerReviewData', partnerReviewData);
  const [token, setToken] = useState('');
  // OnBoardReview Veiw Api
  const OnBoardReviewApi = async (rowId) => {
    const localJWTtoken = JSON.parse(userDetails);
    console.log('localJWTtoken', localJWTtoken);
    const companyId = localJWTtoken?.companyId;
    const authToken = localJWTtoken?.jwt;
    const partnerId = JSON.parse(sessionStorage.getItem('Partner Id'));
    setToken(authToken);
    const statusViewCredential = {
      companyId: companyId,
      partnerId: partnerId
    };
    const OnBoardReviewResponse = await OnBoardReview(statusViewCredential, authToken);
    console.log('OnBoardReviewResponse', OnBoardReviewResponse);
    if (OnBoardReviewResponse.status === 'success') {
      setPartnerReviewData(OnBoardReviewResponse.data);
    } else {
      setPartnerReviewData({});
    }
  };
  // OnBoardReview Veiw Api end

  const navigate = useNavigate();
  // Styled icon button in Action
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));

  if (parent === 'leads') {
    return (
      <>
        <Tooltip
          title="Application"
          placement="top"
          arrow
          onClick={(e) => {
            console.log('itemObj', itemObj);
            sessionStorage.removeItem('savedApplicationId');
            sessionStorage.removeItem('leadId');
            sessionStorage.removeItem('tabValue');
            const localJWTtoken = userDetails;

            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const appToken = CryptoJS.AES.encrypt(
              applicationId ? String(applicationId) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(
              companyId ? String(companyId) : 'null',
              'companyToken'
            ).toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(String(rowId), 'leadToken').toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(itemObj?.loanId),
              'loanIdToken'
            ).toString();
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(itemObj?.loanTypeId),
              'loanTypeIdToken'
            ).toString();
            // new id added in url, load id, sub loan id, emp id, sub emp id

            const loanIdNewToken = CryptoJS.AES.encrypt(
              String(itemObj?.loanTypeNewId),
              'loanIdNewToken'
            ).toString();

            const subLoanIdToken = CryptoJS.AES.encrypt(
              String(itemObj?.loanTypeId),
              'subLoanIdToken'
            ).toString();

            const empTypeNewIdToken = CryptoJS.AES.encrypt(
              String(itemObj?.empTypeNewId),
              'empTypeNewIdToken'
            ).toString();

            const subEmpTypeIdToken = CryptoJS.AES.encrypt(
              String(itemObj?.employmentTypeId),
              'subEmpTypeIdToken'
            ).toString();

            const locationValue = CryptoJS.AES.encrypt(String('leads'), 'locationUrl').toString();
            const isEdit = 'true';
            window.open(
              `${domainUrl}leads/lead-application?u=${encodeURIComponent(
                userToken
              )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                appToken
              )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                userIdToken
              )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                loanTypeIdToken
              )}&lt=${encodeURIComponent(loanIdNewToken)}&lts=${encodeURIComponent(
                subLoanIdToken
              )}&et=${encodeURIComponent(empTypeNewIdToken)}&ets=${encodeURIComponent(
                subEmpTypeIdToken
              )}&awn=${itemObj?.activeWizardNumber}&applicationCreationEntryPlatform=${
                itemObj?.applicationCreationEntryPlatform
              }&activeWizardCode=${itemObj?.activeWizardCode}&location=${encodeURIComponent(
                locationValue
              )}&isEdit=${isEdit}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdAppRegistration size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Process"
          placement="top"
          arrow
          disabled={applicationId !== null ? false : true}
          onClick={(e) => {
            sessionStorage.removeItem('tabValue');
            const leadObj = {
              leadesItem: [itemObj]
            };
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            navigate(`/${parent}/process`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <RiFlowChart size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }

  if (parent === 'view-recommendation') {
    return (
      <>
        <Tooltip title="View" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={() => {
              const obj = {
                recommendationId: rowId.partnerRecommendationId,
                name: rowId.name,
                applicationCreationEntryPlatform: rowId.applicationCreationEntryPlatform
              };
              sessionStorage.removeItem('savedRecommendationApplicationId');
              sessionStorage.setItem('viewRecommendation', JSON.stringify(obj));
              sessionStorage.removeItem('savedRecommendationApplicationId');
              sessionStorage.setItem('viewRecommendation', JSON.stringify(obj));
              console.log('rowIdrowId', rowId);
              const localJWTtoken = userDetails;

              const apiCredentialsObj = {
                activeWizardCode: rowId?.activeWizardCode ? rowId.activeWizardCode : '',
                activeWizardNumber: rowId?.activeWizardNumber ? rowId?.activeWizardNumber : 0,
                companyId: rowId?.loanType?.companyId
                  ? rowId?.loanType?.companyId
                  : localJWTtoken?.companyId,
                employmentSubTypeId: {
                  employmentSubcategoryId: rowId?.empSubType?.employmentSubcategoryId,
                  companyId: rowId?.empSubType?.companyId
                    ? rowId?.empSubType?.companyId
                    : localJWTtoken?.companyId,
                  employmentCategoryId: rowId?.empSubType?.employmentCategoryId,
                  employmentSubcategoryCode: rowId?.empSubType?.employmentSubcategoryCode,
                  employmentSubcategoryName: rowId?.empSubType?.employmentSubcategoryName,
                  employmentSubcategoryDescription:
                    rowId?.empSubType?.employmentSubcategoryDescription,
                  employmentSubcategoryIsActive: rowId?.empSubType?.employmentSubcategoryIsActive,
                  employmentSubcategoryDefaultLogo:
                    rowId?.empSubType?.employmentSubcategoryDefaultLogo,
                  employmentSubcategoryCustomLogo:
                    rowId?.empSubType?.employmentSubcategoryCustomLogo,
                  employmentSubcategoryDefaultSeq:
                    rowId?.empSubType?.employmentSubcategoryDefaultSeq,
                  employmentSubcategoryCustomSeq: rowId?.empSubType?.employmentSubcategoryCustomSeq
                },
                employmentTypeId: {
                  employmentCategoryId: rowId?.empType?.employmentCategoryId,
                  companyId: rowId?.empType?.companyId
                    ? rowId?.empType?.companyId
                    : localJWTtoken?.companyId,
                  employmentCategoryCode: rowId?.empType?.employmentCategoryCode,
                  employmentCategoryName: rowId?.empType?.employmentCategoryName,
                  employmentCategoryDescription: rowId?.empType?.employmentCategoryDescription,
                  employmentCategoryDefaultLogo: rowId?.empType?.employmentCategoryDefaultLogo,
                  employmentCategoryCustomLogo: rowId?.empType?.employmentCategoryCustomLogo,
                  employmentCategoryDefaultSeq: rowId?.empType?.employmentCategoryDefaultSeq,
                  employmentCategoryCustomSeq: rowId?.empType?.employmentCategoryCustomSeq,
                  employmentCategoryIsActive: rowId?.empType?.employmentCategoryIsActive
                },
                journeyId: rowId?.journeyId,
                loanSubTypeId: {
                  compId: rowId?.loanSubType?.compId
                    ? rowId?.loanSubType?.compId
                    : localJWTtoken?.companyId,
                  parentLoanId: rowId?.loanSubType?.parentLoanId,
                  loanId: rowId?.loanSubType?.loanId,
                  loanCode: rowId?.loanSubType?.loanCode,
                  loanName: rowId?.loanSubType?.loanName,
                  loanNameToDisplay: rowId?.loanSubType?.loanNameToDisplay,
                  loanDescription: rowId?.loanSubType?.loanDescription,
                  iconPath: rowId?.loanSubType?.iconPath,
                  defaultIconPath: rowId?.loanSubType?.defaultIconPath,
                  isActive: rowId?.loanSubType?.isActive,
                  currentSequence: rowId?.loanSubType?.currentSequence,
                  defaultSequence: rowId?.loanSubType?.defaultSequence
                },
                loanTypeId: {
                  companyId: rowId?.loanType?.companyId
                    ? rowId?.loanType?.companyId
                    : localJWTtoken?.companyId,
                  parentLoanId: rowId?.loanType?.parentLoanId,
                  loanCode: rowId?.loanType?.loanCode,
                  parentLoanName: rowId?.loanType?.parentLoanName,
                  parentLoanDescription: rowId?.loanType?.parentLoanDescription,
                  iconPath: rowId?.loanType?.iconPath,
                  defaultIconPath: rowId?.loanType?.defaultIconPath,
                  isActive: rowId?.loanType?.isActive,
                  currentSequence: rowId?.loanType?.currentSequence,
                  defaultSequence: rowId?.loanType?.defaultSequence
                },
                userId: localJWTtoken?.userId,
                pageCode: localJWTtoken?.jwt
              };
              sessionStorage.setItem('apiCredentials', JSON.stringify(apiCredentialsObj));
              sessionStorage.setItem('apiCredentials', JSON.stringify(apiCredentialsObj));
              navigate(`${parent}`);
            }}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Share" placement="top" arrow>
          <StyledIconButton
            disabled
            className="squareIconButton actionButton"
            onClick={(e) => setSharePopUp(true)}
          >
            <BiShareAlt size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }

  if (parent === 'print-download') {
    return (
      <Tooltip title="View" placement="top" arrow onClick={(e) => setDocumentPreview(true)}>
        <StyledIconButton className="squareIconButton actionButton">
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }

  if (parent === 'print-download-leads') {
    return (
      <Tooltip
        title="View"
        placement="top"
        arrow
        onClick={() => {
          const imgDetailsObj = {};
          imgDetailsObj.src = rowId.path;
          imgDetailsObj.alt = rowId.documentName;
          imgDetailsObj.contentType = rowId.contentType;
          docPrevHandler('open', JSON.stringify(imgDetailsObj));
        }}
      >
        <StyledIconButton className="squareIconButton actionButton">
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'login') {
    return (
      <>
        <Tooltip
          title="Process Application"
          placement="top"
          arrow
          disabled={rowId.applicationId !== null ? false : true}
          onClick={(e) => {
            sessionStorage.removeItem('tabValue');
            const leadObj = {
              leadesItem: [rowId]
            };
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            navigate(`/${parent}/process`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <RiFlowChart size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          disabled={
            rowId?.loginStatusCode &&
            rowId.loginStatusCode !== 'CAM_GENERATED' &&
            rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED'
            // &&
            // rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED' &&
            // rowId.loginStatusCode !== 'DOCUCHECK_APPROVED' &&
            // rowId.loginStatusCode !== 'APPLICATION_APPROVED_WDC'
          }
          onClick={(e) => {
            const leadObj = { leadesItem: [rowId] };
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.setItem('dlpType', JSON.stringify('Allotment'));
            console.log('rowId', rowId);
            sessionStorage.setItem('loanTypeName', rowId?.parentLoanTypeCode);
            sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            console.log('rowId-localJWTtoken', localJWTtoken);
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            console.log('rowId-companyId', companyId, String(companyId));

            const appToken = CryptoJS.AES.encrypt(
              rowId.applicationId ? String(rowId.applicationId) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(String(rowId.leadId), 'leadToken').toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(rowId.leadId),
              'loanIdToken'
            ).toString();
            const module = 'login';
            const dlpType = 'Allotment';
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(rowId.loanTypeId),
              'loanTypeIdToken'
            ).toString();
            const accessLevelCode = userDetails?.accessLevelCode;
            window.open(
              rowId.applicationId !== null
                ? `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                    loanTypeIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${dlpType}`
                : `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${dlpType}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  // if (parent === 'revenue') {
  //   return (
  //     <>
  //       {pageMenu.length > 0 &&
  //         pageMenu.map((pageElement, index) => (
  //           <>
  //             {pageElement.subPageCode === 'VIEW' && pageElement.isEnable === true ? (
  //               <Tooltip
  //                 title="View"
  //                 placement="top"
  //                 arrow
  //                 onClick={(e) => navigate(`/${parent}/view`)}
  //               >
  //                 <StyledIconButton className="squareIconButton actionButton">
  //                   <FiEye size={14} />
  //                 </StyledIconButton>
  //               </Tooltip>
  //             ) : pageElement.subPageCode === 'SEND_MESSAGE' && pageElement.isEnable === true ? (
  //               <Tooltip
  //                 title="Send Message"
  //                 placement="top"
  //                 arrow
  //                 onClick={(e) => {
  //                   setOpenSendMsg(true);
  //                 }}
  //               >
  //                 <StyledIconButton className="squareIconButton actionButton">
  //                   <MdOutlineSendToMobile size={14} />
  //                 </StyledIconButton>
  //               </Tooltip>
  //             ) : (
  //               ''
  //             )}
  //             {}
  //           </>
  //         ))}
  //       &nbsp;
  //     </>
  //   );
  // }
  if (parent === 'revenue') {
    return (
      <>
        <Tooltip title="View File" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/${parent}/view`)}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Generate Invoice" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/${parent}/invoice`)}
          >
            <FaFileInvoice size={14} />
          </StyledIconButton>
        </Tooltip>
        {/* <Tooltip
          title="Send Message"
          placement="top"
          arrow
          onClick={(e) => {
            setOpenSendMsg(true);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdOutlineSendToMobile size={14} />
          </StyledIconButton>
        </Tooltip> */}
      </>
    );
  }
  if (parent === 'revenue-receipt') {
    return (
      <Tooltip title="View File" placement="top" arrow>
        <StyledIconButton
          className="squareIconButton actionButton"
          onClick={(e) => {
            const revenueIdData = {
              revenueId: revenueId,
              leadId: leadId,
              applicationId: applicationId
            };
            sessionStorage.setItem('revenueIdData', JSON.stringify(revenueIdData));
            sessionStorage.setItem('revenueIdData', JSON.stringify(revenueIdData));
            navigate(`/revenue/view-receipt`);
          }}
        >
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'revenue-approved') {
    return (
      <Tooltip title="View File" placement="top" arrow>
        <StyledIconButton
          className="squareIconButton actionButton"
          onClick={(e) => {
            const revenueIdData = {
              revenueId: revenueId,
              leadId: leadId,
              applicationId: applicationId
            };
            sessionStorage.setItem('revenueIdData', JSON.stringify(revenueIdData));
            sessionStorage.setItem('revenueIdData', JSON.stringify(revenueIdData));
            navigate(`/revenue/view-approved`);
          }}
        >
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'revenue-disbursed') {
    return (
      <Tooltip title="View File" placement="top" arrow>
        <StyledIconButton
          className="squareIconButton actionButton"
          onClick={(e) => {
            const revenueIdData = {
              revenueId: revenueId,
              leadId: leadId,
              applicationId: applicationId
            };
            sessionStorage.setItem('revenueIdData', JSON.stringify(revenueIdData));
            sessionStorage.setItem('revenueIdData', JSON.stringify(revenueIdData));
            navigate(`/revenue/view-disbursed`);
          }}
        >
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'payout') {
    return (
      <>
        <Tooltip title="View File" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              const payoutIdData = {
                applicationId: rowId.applicationId,
                partnerId: rowId.partnerId,
                partnerPayoutId: rowId.partnerPayoutId
              };
              sessionStorage.setItem('payoutIdData', JSON.stringify(payoutIdData));
              sessionStorage.setItem('payoutIdData', JSON.stringify(payoutIdData));
              navigate(`/${parent}/view`);
            }}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Confirm Invoice" placement="top" arrow>
          <StyledIconButton
            classes={{
              disabled: 'disableButton'
            }}
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/${parent}/invoice`)}
            disabled
          >
            <FaFileInvoice size={14} />
          </StyledIconButton>
        </Tooltip>
        {/* <Tooltip
          title="Send Message"
          placement="top"
          arrow
          onClick={(e) => {
            setOpenSendMsg(true);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdOutlineSendToMobile size={14} />
          </StyledIconButton>
        </Tooltip> */}
      </>
    );
  }

  // if (parent === 'call-center') {
  //   return (
  //     <>
  //       {
  //         pageMenu.map((pageElement, index) => (
  //           <>
  //             {pageElement.subPageCode === 'COMPLETE_FORM' && pageElement.isEnable === true ? (
  //               <Tooltip title="Complete Form" placement="top" arrow>
  //                 <StyledIconButton className="squareIconButton actionButton">
  //                   <FaWpforms size={14} />
  //                 </StyledIconButton>
  //               </Tooltip>
  //             ) : pageElement.subPageCode === 'UPDATE_STATUS' && pageElement.isEnable === true ? (
  //               <Tooltip title="Update Status" placement="top" arrow>
  //                 <StyledIconButton
  //                   className="squareIconButton actionButton"
  //                   onClick={(e) => setOpenStatus(true)}
  //                 >
  //                   <BsCheckCircle size={14} />
  //                 </StyledIconButton>
  //               </Tooltip>
  //             ) : pageElement.subPageCode === 'CLOSE' && pageElement.isEnable === true ? (
  //               <Tooltip title="Close" placement="top" arrow>
  //                 <StyledIconButton className="squareIconButton actionButton">
  //                   <AiOutlineClose size={14} />
  //                 </StyledIconButton>
  //               </Tooltip>
  //             ) : (
  //               ''
  //             )}
  //             {}
  //           </>
  //         ))}
  //       &nbsp;
  //     </>
  //   );
  // }
  if (parent === 'call-center') {
    return (
      <>
        <Tooltip title="View" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              sessionStorage.setItem('viewCallCenterId', rowId);
              sessionStorage.setItem('viewCallCenterId', rowId);
              navigate(`/call-center/view`);
            }}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Recommendation" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              window.open(`http://calc.kalolytic.com/`, `_blank`);
            }}
          >
            <TbCalculator size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'on-boarding') {
    return (
      <Box sx={{ display: 'flex' }}>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'ADD_NEW' && pageElement.isEnable === true ? (
                <Tooltip
                  title="Edit"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    sessionStorage.setItem('partnerId', rowId);
                    sessionStorage.setItem('partnerId', rowId);
                    navigate(`/onboarding/edit/${rowId}`);
                  }}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <MdModeEdit size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'REVIEW' && pageElement.isEnable === true ? (
                <Tooltip
                  title="Review"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    sessionStorage.setItem('partnerId', rowId);
                    sessionStorage.setItem('partnerId', rowId);
                    navigate(`/onboarding/review`);
                    // OnBoardReviewApi(rowId);
                  }}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <MdReviews size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'SET_COMMISION' && pageElement.isEnable === true ? (
                <Tooltip
                  title="Set Commission"
                  placement="top"
                  arrow
                  onClick={(e) => {
                    sessionStorage.setItem('partnerId', rowId);
                    sessionStorage.setItem('partnerId', rowId);
                    navigate(`/onboarding/set-commission`);
                    // OnBoardReviewApi(rowId);
                  }}
                  // onClick={(e) => navigate(`/onboarding/set-commission`)}
                >
                  <StyledIconButton className="squareIconButton actionButton">
                    <FaRegMoneyBillAlt size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'ACTIVATE' && pageElement.isEnable === true ? (
                <Tooltip title="Activate" placement="top" arrow>
                  <StyledIconButton
                    classes={{
                      disabled: 'disableButton'
                    }}
                    className="squareIconButton actionButton"
                    onClick={(e) => {
                      UpdatePartnerStatusApi(rowId, partner);
                    }}
                    disabled={
                      partnerState === null ||
                      partner.partnerStatus.typeName === 'Rejected' ||
                      partner.partnerStatus.typeName === 'Inactive'
                        ? true
                        : false
                    }
                  >
                    <AiOutlineCheck size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {}
            </>
          ))}
      </Box>
    );
  }
  if (parent === 'invoice') {
    return (
      <>
        &nbsp;
        <Box sx={{ display: 'flex' }}>
          {pageMenu.length > 0 &&
            pageMenu.map((pageElement, index) => (
              <>
                {pageElement.subPageCode === 'RAISE_INVOICE' && pageElement.isEnable === true ? (
                  <>
                    <Tooltip
                      title="Raise Invoice"
                      placement="top"
                      arrow
                      onClick={(e) => navigate(`/${parent}/raise-invoice`)}
                    >
                      <StyledIconButton className="squareIconButton actionButton">
                        <FaFileInvoice size={14} />
                      </StyledIconButton>
                    </Tooltip>
                    &nbsp;
                  </>
                ) : pageElement.subPageCode === 'PAYMENT' && pageElement.isEnable === true ? (
                  <Tooltip
                    title="Payment"
                    placement="top"
                    arrow
                    onClick={(e) => navigate(`/${parent}/payment-invoice`)}
                  >
                    <StyledIconButton className="squareIconButton actionButton">
                      <MdPayments size={14} />
                    </StyledIconButton>
                  </Tooltip>
                ) : (
                  ''
                )}
                {}
              </>
            ))}
        </Box>
      </>
    );
  }
  if (parent === 'receivable-file') {
    return (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="View" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/receivable/view-file`)}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
      </Box>
    );
  }
  if (parent === 'receivable-invoice') {
    return (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Pay" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              setOpenPayment(true);
            }}
          >
            <MdPayments size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Invoice as Pdf" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/receivable/invoice-generate`)}
          >
            <BsFilePdf size={14} />
          </StyledIconButton>
        </Tooltip>
      </Box>
    );
  }
  if (parent === 'receivable-multiple-file') {
    return (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            // onClick={(e) => navigate(`/receivable/view`)}
          >
            <AiFillEdit size={14} />
          </StyledIconButton>
        </Tooltip>
      </Box>
    );
  }
  if (parent === 'payable') {
    return (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Invoice as Pdf" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate('/payable/invoice')}
          >
            <BsFilePdf size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="View" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/payable/view`)}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Pay" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              setOpenPayment(true);
            }}
          >
            <MdPayments size={14} />
          </StyledIconButton>
        </Tooltip>
      </Box>
    );
  }

  if (parent === 'prospect') {
    return (
      <Tooltip
        title="View"
        placement="top"
        arrow
        onClick={(e) => {
          sessionStorage.setItem('prospectLeadId', rowId);
          sessionStorage.setItem('prospectLeadId', rowId);
          navigate(`/${parent}/view`);
        }}
      >
        <StyledIconButton className="squareIconButton actionButton">
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }

  if (parent === 'u_sanction') {
    return (
      <>
        {/* <Tooltip title="View" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip> */}
        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          onClick={(e) => {
            console.log('rowId33', rowId);

            const localJWTtoken = userDetails;
            rowId.companyId = localJWTtoken?.companyId;
            console.log('roefdsfsd', rowId);
            const leadObj = {
              leadesItem: [rowId]
            };
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.setItem('dlpType', JSON.stringify('Allotment'));
            sessionStorage.removeItem('SignMappId');
            sessionStorage.setItem('loanTypeName', rowId?.parentLoanTypeCode);

            console.log('rowId-localJWTtoken', localJWTtoken);
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            console.log('rowId-companyId', companyId, String(companyId));

            const appToken = CryptoJS.AES.encrypt(
              rowId.applicationId ? String(rowId.applicationId) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(String(rowId.leadId), 'leadToken').toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(rowId.leadId),
              'loanIdToken'
            ).toString();
            const module = 'usanction';
            const dlpType = 'Allotment';
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(rowId.loanTypeId),
              'loanTypeIdToken'
            ).toString();
            window.open(
              rowId.applicationId !== null
                ? `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                    loanTypeIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&module=${encodeURIComponent(module)}&dlpType=${dlpType}`
                : `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&module=${encodeURIComponent(module)}&dlpType=${dlpType}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }

  if (parent === 'master') {
    return (
      <>
        <Tooltip title="Delete" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="View" placement="top" arrow onClick={(e) => navigate(`/${parent}/add`)}>
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'agreement') {
    return (
      <>
        <Tooltip
          title="View &#38; Edit"
          placement="top"
          arrow
          onClick={(e) => {
            sessionStorage.setItem('viewAgreementId', rowId);
            sessionStorage.removeItem('reviewData');
            sessionStorage.removeItem('commisssionType');
            sessionStorage.setItem('viewAgreementId', rowId);
            sessionStorage.removeItem('reviewData');
            sessionStorage.removeItem('commisssionType');
            navigate(`/${parent}/view`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        {/* <Tooltip
          title="Add Policy"
          placement="top"
          arrow
          onClick={(e) => {
            sessionStorage.removeItem('reviewData');
            sessionStorage.removeItem('commisssionType');
            sessionStorage.setItem('viewAgreementId', rowId);
            sessionStorage.setItem(
            sessionStorage.removeItem('reviewData');
            sessionStorage.removeItem('commisssionType');
            sessionStorage.setItem('viewAgreementId', rowId);
            sessionStorage.setItem(
              'bankNameDetails',
              JSON.stringify({
                bankName: bankName,
                bankId: bankId
              })
            );
            navigate(`/${parent}/policy`);
          }}
        >
          <StyledIconButton
            classes={{
              disabled: 'disableButton'
            }}
            className="squareIconButton actionButton"
            disabled={aggrementIsActive === true ? false : true}
          >
            <AiOutlineFileAdd size={14} />
          </StyledIconButton>
        </Tooltip> */}
        <Tooltip
          title="End Agreement"
          placement="top"
          arrow
          onClick={(e) => {
            // setEndAgreement(true);
            openEndAgreementDialog(e, rowId);
          }}
        >
          <StyledIconButton
            classes={{
              disabled: 'disableButton'
            }}
            className="squareIconButton actionButton"
            disabled={aggrementIsActive === null || aggrementIsActive === true ? false : true}
          >
            <MdOutlineDateRange size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'add-branch-agreement') {
    return (
      <>
        <Tooltip
          title="Edit"
          placement="top"
          arrow
          // onClick={(e) => {
          //   setBranchEdit(true);
          // }}
          onClick={(e) => branchEditDialogOpen(e, itemBranchIndex, branchIndex)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <AiFillEdit size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="top"
          arrow
          onClick={(e) => {
            branchDeleteDialogOpen(e, itemBranchIndex, branchIndex);
            // setDeleteConfirm(true);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'individual-agreement') {
    return (
      <>
        <Tooltip
          title="Edit"
          placement="top"
          arrow
          // onClick={(e) => setIndividualCommissionEdit(true)}
          onClick={(e) => individualEditDialogOpen(e, itemList, indexValue)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <AiFillEdit size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="top"
          arrow
          onClick={(e) => {
            individualDeleteDialogOpen(e, itemList, indexValue);
            // setDeleteConfirm(true);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'volume-agreement') {
    return (
      <>
        <Tooltip
          title="Edit"
          placement="top"
          arrow
          onClick={(e) => {
            // setVolumeCommissionEdit(true);
            volumeEditDialogOpen(e, itemListVolume, indexValueVolume);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <AiFillEdit size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="top"
          arrow
          onClick={(e) => {
            volumeDeleteDialogOpen(e, itemListVolume, indexValueVolume);
            // setDeleteConfirm(true);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'policy') {
    return (
      <>
        <Tooltip
          title="View"
          placement="top"
          arrow
          onClick={(e) => {
            sessionStorage.setItem('aggrementPolicyId', rowId);
            // sessionStorage.setItem('viewPolicyAgreementId', viewPolicyAgreementId);
            sessionStorage.setItem('aggrementPolicyId', rowId);
            // sessionStorage.setItem('viewPolicyAgreementId', viewPolicyAgreementId);
            navigate(`/${parent}/view-policy`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Edit"
          placement="top"
          arrow
          onClick={(e) => {
            sessionStorage.setItem('aggrementPolicyId', rowId);
            // sessionStorage.setItem('viewPolicyAgreementId', viewPolicyAgreementId);
            sessionStorage.setItem('aggrementPolicyId', rowId);
            // sessionStorage.setItem('viewPolicyAgreementId', viewPolicyAgreementId);
            navigate(`/${parent}/edit-policy`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <AiFillEdit size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'journey') {
    return (
      <>
        <Tooltip
          disabled={isAddAccess?.length > 0 && isAddAccess[0]?.editAccess === false ? true : false}
          title="Edit"
          placement="top"
          arrow
          onClick={(e) => {
            sessionStorage.setItem('viewJourneyId', rowId.journeyId);
            sessionStorage.removeItem('cardTypeListAll');
            sessionStorage.removeItem('journeyDetailsId');
            sessionStorage.removeItem('fldListAll');
            sessionStorage.removeItem('journeybyIdData');
            sessionStorage.setItem('viewJourneyId', rowId.journeyId);
            sessionStorage.removeItem('cardTypeListAll');
            sessionStorage.removeItem('journeyDetailsId');
            sessionStorage.removeItem('fldListAll');
            sessionStorage.removeItem('journeybyIdData');
            navigate(`/${parent}/add?u${sentUrl}`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <AiFillEdit size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          disabled={
            isAddAccess?.length > 0 && isAddAccess[0]?.publishAccess === false ? true : false
          }
          title="Publish"
          placement="top"
          arrow
          onClick={() => handlePublish('Publish', rowId.journeyId)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdPublishedWithChanges size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          disabled={isAddAccess?.length > 0 && isAddAccess[0]?.deleteAccess === true ? false : true}
          title="Delete"
          placement="top"
          arrow
          onClick={() => handlePublish('Delete', rowId.journeyId)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          disabled={isAddAccess?.length > 0 && isAddAccess[0]?.viewAccess === false ? true : false}
          title="View"
          placement="top"
          arrow
          onClick={(e) => {
            sessionStorage.setItem('viewJourneyId', rowId.journeyId);
            sessionStorage.removeItem('cardTypeListAll');
            sessionStorage.removeItem('journeyDetailsId');
            sessionStorage.removeItem('fldListAll');
            sessionStorage.removeItem('journeybyIdData');
            sessionStorage.setItem('viewJourneyId', rowId.journeyId);
            sessionStorage.removeItem('cardTypeListAll');
            sessionStorage.removeItem('journeyDetailsId');
            sessionStorage.removeItem('fldListAll');
            sessionStorage.removeItem('journeybyIdData');
            navigate(`/${parent}/view?u${sentUrl}`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Configure"
          placement="top"
          arrow
          onClick={(e) => {
            console.log('journeyconfiguredata', rowId);
            sessionStorage.setItem('viewJourneyId', rowId.journeyId);

            navigate(
              `/${parent}/configure?u${sentUrl}&journeyId=${encodeURIComponent(rowId.journeyId)}`
            );
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <AiFillTool size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'journey-template') {
    return (
      <>
        <Tooltip
          title="Copy"
          placement="top"
          arrow
          onClick={(e) => {
            handleCopyDialog(e, rowId, templateName);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <RiFileCopyLine size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="View"
          placement="top"
          arrow
          onClick={(e) => {
            sessionStorage.setItem('journeyTemplateId', rowId);
            sessionStorage.setItem('journeyTemplateId', rowId);
            navigate(`/journey/viewTemplate?u${sentUrl}`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  // if (parent === 'payable') {
  //   return (
  //     <Box sx={{ display: 'flex' }}>
  //       <Tooltip title="Invoice as Pdf" placement="top" arrow>
  //         <StyledIconButton className="squareIconButton actionButton">
  //           <BsFilePdf size={14} />
  //         </StyledIconButton>
  //       </Tooltip>
  //       <Tooltip title="View" placement="top" arrow>
  //         <StyledIconButton
  //           className="squareIconButton actionButton"
  //           onClick={(e) => navigate(`/payable`)}
  //         >
  //           <FiEye size={14} />
  //         </StyledIconButton>
  //       </Tooltip>
  //       <Tooltip title="Pay" placement="top" arrow>
  //         <StyledIconButton
  //           className="squareIconButton actionButton"
  //           onClick={(e) => navigate(`/payable`)}
  //         >
  //           <MdPayments size={14} />
  //         </StyledIconButton>
  //       </Tooltip>
  //     </Box>
  //   );
  // }
  if (parent === 'users') {
    return (
      <>
        <Tooltip title="Delete" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Edit"
          placement="top"
          arrow
          onClick={(e) => navigate(`/${parent}/edit/${rowId}`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdModeEdit size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'customer') {
    return (
      <>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'DELETE' && pageElement.isEnable === true ? (
                <Tooltip title="Delete" placement="top" arrow>
                  <StyledIconButton className="squareIconButton actionButton">
                    <Icon icon={trash2Outline} width={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'EDIT' && pageElement.isEnable === true ? (
                <Tooltip title="Edit" placement="top" arrow>
                  <StyledIconButton className="squareIconButton actionButton">
                    <MdModeEdit size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {}
            </>
          ))}
        &nbsp;
      </>
    );
  }

  if (parent === 'audit-pre-sanction') {
    return (
      <>
        <Tooltip title="View & Approve" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) =>
              navigate('view-approve', {
                state: { applicationId: applicationId, applicationStatus: applicationStatus }
              })
            }
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          disabled={
            rowId?.loginStatusCode &&
            rowId.loginStatusCode !== 'CAM_GENERATED' &&
            rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED'
            // &&
            // rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED' &&
            // rowId.loginStatusCode !== 'DOCUCHECK_APPROVED' &&
            // rowId.loginStatusCode !== 'APPLICATION_APPROVED_WDC'
          }
          onClick={(e) => {
            const leadObj = { leadesItem: [rowId] };
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));

            console.log('rowId', rowId);
            sessionStorage.setItem('loanTypeName', rowId?.parentLoanTypeCode);
            sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            console.log('rowId-localJWTtoken', localJWTtoken);
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            console.log('rowId-companyId', companyId, String(companyId));

            const appToken = CryptoJS.AES.encrypt(
              applicationId ? String(applicationId) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(
              String(rowId?.assignedToUserId),
              'leadToken'
            ).toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(rowId?.assignedToUserId),
              'loanIdToken'
            ).toString();
            const module = 'audit';
            const dlpType = 'Allotment';
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(rowId.loanTypeId),
              'loanTypeIdToken'
            ).toString();
            const accessLevelCode = userDetails?.accessLevelCode;
            window.open(
              applicationId !== null
                ? `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                    loanTypeIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`
                : `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>

        {/* <Tooltip title="Query" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <MdQueryStats size={14} />
          </StyledIconButton>
        </Tooltip> */}
      </>
    );
  }
  if (parent === 'audit-post-sanctioned') {
    return (
      <>
        <Tooltip title="View & Approve" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) =>
              navigate('view-approve', {
                state: {
                  applicationId: applicationId,
                  applicationStatus: applicationStatus,
                  auditRatingEvaluationId: auditRatingEvaluationId,
                  type: 'postsanction',
                  evaluationType: evaluationType
                }
              })
            }
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          disabled={
            rowId?.loginStatusCode &&
            rowId.loginStatusCode !== 'CAM_GENERATED' &&
            rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED'
            // &&
            // rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED' &&
            // rowId.loginStatusCode !== 'DOCUCHECK_APPROVED' &&
            // rowId.loginStatusCode !== 'APPLICATION_APPROVED_WDC'
          }
          onClick={(e) => {
            const leadObj = { leadesItem: [rowId] };
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));

            console.log('rowId', rowId);
            sessionStorage.setItem('loanTypeName', rowId?.parentLoanTypeCode);
            sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            console.log('rowId-localJWTtoken', localJWTtoken);
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            console.log('rowId-companyId', companyId, String(companyId));

            const appToken = CryptoJS.AES.encrypt(
              applicationId ? String(applicationId) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(
              String(rowId?.assignedToUserId),
              'leadToken'
            ).toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(rowId?.assignedToUserId),
              'loanIdToken'
            ).toString();
            const module = 'audit';
            const dlpType = 'Disbursement';
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(rowId.loanTypeId),
              'loanTypeIdToken'
            ).toString();
            const accessLevelCode = userDetails?.accessLevelCode;
            window.open(
              applicationId !== null
                ? `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                    loanTypeIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`
                : `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>

        {/* <Tooltip title="Query" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <MdQueryStats size={14} />
          </StyledIconButton>
        </Tooltip> */}
      </>
    );
  }
  if (parent === 'audit-post-disbursement') {
    return (
      <>
        <Tooltip title="View & Approve" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) =>
              navigate('view-approve', {
                state: { applicationId: applicationId, applicationStatus: applicationStatus }
              })
            }
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          disabled={
            rowId?.loginStatusCode &&
            rowId.loginStatusCode !== 'CAM_GENERATED' &&
            rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED'
            // &&
            // rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED' &&
            // rowId.loginStatusCode !== 'DOCUCHECK_APPROVED' &&
            // rowId.loginStatusCode !== 'APPLICATION_APPROVED_WDC'
          }
          onClick={(e) => {
            const leadObj = { leadesItem: [rowId] };
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.setItem('dlpType', JSON.stringify('Allotment'));
            console.log('rowId', rowId);
            sessionStorage.setItem('loanTypeName', rowId?.parentLoanTypeCode);
            sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            console.log('rowId-localJWTtoken', localJWTtoken);
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            console.log('rowId-companyId', companyId, String(companyId));

            const appToken = CryptoJS.AES.encrypt(
              applicationId ? String(applicationId) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(
              String(rowId?.assignedToUserId),
              'leadToken'
            ).toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(rowId?.assignedToUserId),
              'loanIdToken'
            ).toString();
            const module = 'audit';
            const dlpType = 'Disbursement';
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(rowId.loanTypeId),
              'loanTypeIdToken'
            ).toString();
            const accessLevelCode = userDetails?.accessLevelCode;
            window.open(
              applicationId !== null
                ? `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                    loanTypeIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`
                : `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>

        {/* <Tooltip title="Query" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <MdQueryStats size={14} />
          </StyledIconButton>
        </Tooltip> */}
      </>
    );
  }
  // if (parent === 'rating') {
  //   return (
  //     <Tooltip
  //       title="View Rating Report"
  //       placement="top"
  //       arrow
  //       // onClick={(e) => {
  //       //   window.open(`https://disburse.kalolytic.com/decision`, `_blank`);
  //       // }}
  //     >
  //       <StyledIconButton className="squareIconButton actionButton">
  //         <FiEye size={14} />
  //       </StyledIconButton>
  //     </Tooltip>
  //   );
  // }
  // if (parent === 'rating') {
  //   return (
  //     <Tooltip title="View Rating" placement="top" arrow>
  //       <StyledIconButton
  //         className="squareIconButton actionButton"
  //         onClick={(e) => navigate('/rating/view-rating')}
  //       >
  //         <FiEye size={14} />
  //       </StyledIconButton>
  //     </Tooltip>
  //   );
  // }
  if (parent === 'rating') {
    return (
      <Tooltip
        title="View Rating Report"
        placement="top"
        arrow
        disabled={applicationId !== null ? false : true}
        onClick={(e) => {
          sessionStorage.removeItem('tabValue');
          const leadObj = {
            ratingsItem: [itemObj]
          };
          sessionStorage.setItem('ratingDetails', JSON.stringify(leadObj));
          // sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
          navigate(`/${parent}/view-rating`, {
            state: { applicationId: applicationId, applicationStatus: applicationStatus }
          });
        }}
      >
        <StyledIconButton className="squareIconButton actionButton">
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  // if (parent === 'securities') {
  //   return (
  //     <Tooltip
  //       title="View Security"
  //       placement="top"
  //       arrow
  //       disabled={applicationId !== null ? false : true}
  //       onClick={(e) => {
  //         sessionStorage.removeItem('tabValue');
  //         const securitiesObj = {
  //           securitiesItem: [itemObj]
  //         };
  //         sessionStorage.setItem('securitiesDetails', JSON.stringify(securitiesObj));
  //         navigate(`/pre-sanction-form`, {
  //           state: { applicationId: applicationId, applicationStatus: applicationStatus }
  //         });
  //       }}
  //     >
  //       <StyledIconButton className="squareIconButton actionButton">
  //         <FiEye size={14} />
  //       </StyledIconButton>
  //     </Tooltip>
  //   );
  // }
  if (parent === 'legal-pre-sanctioned-securities') {
    return (
      <>
        <Tooltip
          title="View Security"
          placement="top"
          arrow
          disabled={applicationId !== null ? false : true}
          onClick={(e) => {
            sessionStorage.removeItem('tabValue');
            const securitiesObj = {
              securitiesItem: [itemObj]
            };
            sessionStorage.setItem('securitiesDetails', JSON.stringify(securitiesObj));
            // fetch report typeId and sending into navigation
            const localStorageCam = sessionStorage.getItem('isCamReportAvailable');
            const localStorageParseData =
              localStorageCam !== null && localStorageCam !== undefined
                ? JSON.parse(localStorageCam)
                : null;
            const reportTypeId = localStorageParseData?.data?.accessLevelReportMapping?.filter(
              (item) => item?.reportCode === 'LEGAL_PRE_REPORT_TYPE'
            );
            // console.log('reportTypeId', reportTypeId);
            // fetch report typeId and sending into navigation
            navigate(`/pre-sanction-form`, {
              state: {
                applicationId: applicationId
                  ? applicationId
                  : leadDetails?.leadesItem[0]?.applicationId,
                applicationStatus: applicationStatus,
                reportTypeId: reportTypeId && reportTypeId[0]?.reportTypeId,
                parent: 'Pre'
              }
            });
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          disabled={
            rowId?.loginStatusCode &&
            rowId.loginStatusCode !== 'CAM_GENERATED' &&
            rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED'
            // &&
            // rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED' &&
            // rowId.loginStatusCode !== 'DOCUCHECK_APPROVED' &&
            // rowId.loginStatusCode !== 'APPLICATION_APPROVED_WDC'
          }
          onClick={(e) => {
            const leadObj = { leadesItem: [itemObj] };
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.setItem('taskId111', applicationId);
            console.log('rowId', itemObj);
            sessionStorage.setItem('loanTypeName', itemObj?.parentLoanTypeCode);
            sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            console.log('rowId-localJWTtoken', localJWTtoken);
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            console.log('rowId-companyId', companyId, String(companyId));

            const appToken = CryptoJS.AES.encrypt(
              applicationId ? String(applicationId) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(
              String(itemObj?.assignedToUserId),
              'leadToken'
            ).toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(itemObj?.assignedToUserId),
              'loanIdToken'
            ).toString();
            const module = 'legal';
            const dlpType = 'Allotment';
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(itemObj?.loanSubTypeId),
              'loanTypeIdToken'
            ).toString();
            const accessLevelCode = userDetails?.accessLevelCode;
            window.open(
              applicationId !== null
                ? `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                    loanTypeIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`
                : `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'legal-post-sanctioned-securities') {
    return (
      <>
        <Tooltip
          title="View Security"
          placement="top"
          arrow
          disabled={applicationId !== null ? false : true}
          onClick={(e) => {
            sessionStorage.removeItem('tabValue');
            const securitiesObj = {
              securitiesItem: [itemObj]
            };
            sessionStorage.setItem('securitiesDetails', JSON.stringify(securitiesObj));
            // fetch report typeId and sending into navigation
            const localStorageCam = sessionStorage.getItem('isCamReportAvailable');
            const localStorageParseData =
              localStorageCam !== null && localStorageCam !== undefined
                ? JSON.parse(localStorageCam)
                : null;
            const reportTypeId = localStorageParseData?.data?.accessLevelReportMapping?.filter(
              (item) => item?.reportCode === 'LEGAL_POST_REPORT_TYPE'
            );
            // console.log('reportTypeId', reportTypeId);
            // fetch report typeId and sending into navigation
            navigate(`/pre-sanction-form`, {
              state: {
                applicationId: applicationId,
                applicationStatus: applicationStatus,
                // reportTypeId: reportTypeId[0]?.reportTypeId,
                reportTypeId: reportTypeId && reportTypeId[0]?.reportTypeId,
                parent: 'Post'
              }
            });
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          disabled={
            rowId?.loginStatusCode &&
            rowId.loginStatusCode !== 'CAM_GENERATED' &&
            rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED'
            // &&
            // rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED' &&
            // rowId.loginStatusCode !== 'DOCUCHECK_APPROVED' &&
            // rowId.loginStatusCode !== 'APPLICATION_APPROVED_WDC'
          }
          onClick={(e) => {
            const leadObj = { leadesItem: [itemObj] };
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));

            console.log('rowId', itemObj);
            sessionStorage.setItem('loanTypeName', itemObj?.parentLoanTypeCode);
            sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            console.log('rowId-localJWTtoken', localJWTtoken);
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            console.log('rowId-companyId', companyId, String(companyId));

            const appToken = CryptoJS.AES.encrypt(
              applicationId ? String(applicationId) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(
              String(rowId?.assignedToUserId),
              'leadToken'
            ).toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(itemObj?.assignedToUserId),
              'loanIdToken'
            ).toString();
            const module = 'legal';
            const dlpType = 'Disbursement';
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(itemObj?.loanSubTypeId),
              'loanTypeIdToken'
            ).toString();
            const accessLevelCode = userDetails?.accessLevelCode;
            window.open(
              applicationId !== null
                ? `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                    loanTypeIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`
                : `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'valuation-report') {
    return (
      <>
        <Tooltip
          title="View Security"
          placement="top"
          arrow
          disabled={applicationId !== null ? false : true}
          onClick={(e) => {
            sessionStorage.removeItem('tabValue');
            const securitiesObj = {
              securitiesItem: [itemObj]
            };
            sessionStorage.setItem('securitiesDetails', JSON.stringify(securitiesObj));
            // fetch report typeId and sending into navigation
            const localStorageCam = sessionStorage.getItem('isCamReportAvailable');
            const localStorageParseData =
              localStorageCam !== null && localStorageCam !== undefined
                ? JSON.parse(localStorageCam)
                : null;
            const reportTypeId = localStorageParseData?.data?.accessLevelReportMapping?.filter(
              (item) => item?.reportCode === 'VALUATION_REPORT_TYPE'
            );
            // console.log('reportTypeId', reportTypeId);
            // fetch report typeId and sending into navigation
            navigate(`/pre-sanction-form`, {
              state: {
                applicationId: applicationId,
                applicationStatus: applicationStatus,
                // reportTypeId: reportTypeId[0]?.reportTypeId,
                reportTypeId: reportTypeId && reportTypeId[0]?.reportTypeId,
                parent: 'Valuation Pre'
              }
            });
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          disabled={
            rowId?.loginStatusCode &&
            rowId.loginStatusCode !== 'CAM_GENERATED' &&
            rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED'
            // &&
            // rowId.loginStatusCode !== 'CAM_BRANCH_APPROVED' &&
            // rowId.loginStatusCode !== 'DOCUCHECK_APPROVED' &&
            // rowId.loginStatusCode !== 'APPLICATION_APPROVED_WDC'
          }
          onClick={(e) => {
            const leadObj = { leadesItem: [itemObj] };
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));

            console.log('rowId', itemObj);
            sessionStorage.setItem('loanTypeName', itemObj?.parentLoanTypeCode);
            sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            console.log('rowId-localJWTtoken', localJWTtoken);
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            console.log('rowId-companyId', companyId, String(companyId));

            const appToken = CryptoJS.AES.encrypt(
              applicationId ? String(applicationId) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(
              String(itemObj?.assignedToUserId),
              'leadToken'
            ).toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(itemObj?.assignedToUserId),
              'loanIdToken'
            ).toString();
            const module = 'valuation';
            const dlpType = 'Allotment';
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(itemObj.loanSubTypeId),
              'loanTypeIdToken'
            ).toString();
            const accessLevelCode = userDetails?.accessLevelCode;
            window.open(
              applicationId !== null
                ? `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                    loanTypeIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`
                : `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&accessLevelCode=${accessLevelCode}&module=${encodeURIComponent(
                    module
                  )}&dlpType=${encodeURIComponent(dlpType)}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }

  if (parent === 'partner') {
    return (
      <>
        {pageMenu.length > 0 &&
          pageMenu.map((pageElement, index) => (
            <>
              {pageElement.subPageCode === 'DELETE' && pageElement.isEnable === true ? (
                <Tooltip title="Delete" placement="top" arrow>
                  <StyledIconButton className="squareIconButton actionButton">
                    <Icon icon={trash2Outline} width={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : pageElement.subPageCode === 'EDIT' && pageElement.isEnable === true ? (
                <Tooltip title="Edit" placement="top" arrow>
                  <StyledIconButton className="squareIconButton actionButton">
                    <MdModeEdit size={14} />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {}
            </>
          ))}
        &nbsp;
      </>
    );
  }

  if (parent === 'field-visits') {
    return (
      <Tooltip
        title="View Field Visit Form"
        placement="top"
        arrow
        onClick={(e) => {
          if (fieldVisitType === 'Pre Sanction Inspection') {
            window.open(
              `http://field-visits.kalolytic.com/field-visits/pre-sanction-inspection`,
              `_blank`
            );
          }
          if (fieldVisitType === 'Post Sanction Inspection') {
            window.open(
              `http://field-visits.kalolytic.com/field-visits/post-sanction-inspection`,
              `_blank`
            );
          } else {
            window.open(
              `http://field-visits.kalolytic.com/field-visits/security-inspection`,
              `_blank`
            );
          }
        }}
      >
        <StyledIconButton className="squareIconButton actionButton">
          <FaFolderOpen size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'valuation') {
    return (
      <Tooltip
        title="View Field Visit Form"
        placement="top"
        arrow
        onClick={(e) => {
          window.open(
            `http://field-visits.kalolytic.com/legal-valuation/valuation-application`,
            `_blank`
          );
        }}
      >
        <StyledIconButton className="squareIconButton actionButton">
          <FaFolderOpen size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'security-valuation') {
    return (
      <Tooltip
        title="View Valuation Form"
        placement="top"
        arrow
        // onClick={(e) => {
        //   window.open(
        //     `http://field-visits.kalolytic.com/legal-valuation/legal-verification-application`,
        //     `_blank`
        //   );
        // }}
        onClick={(e) => window.open(`/valuation-form`, `_blank`)}
      >
        <StyledIconButton className="squareIconButton actionButton">
          <FaFolderOpen size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'security-legal-pres') {
    return (
      <Tooltip
        title="View Pre Sanction Form"
        placement="top"
        arrow
        onClick={(e) => window.open(`/pre-sanction-form`, `_blank`)}
      >
        <StyledIconButton className="squareIconButton actionButton">
          <FaFolderOpen size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'security-legal-posts') {
    return (
      <Tooltip
        title="View Post Sanctioned Form"
        placement="top"
        arrow
        onClick={(e) => window.open(`/post-sanctioned-form`, `_blank`)}
      >
        <StyledIconButton className="squareIconButton actionButton">
          <FaFolderOpen size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }

  if (parent === 'bankops') {
    return (
      <>
        <Tooltip
          title="View"
          placement="top"
          arrow
          onClick={(e) => {
            sessionStorage.setItem('selectedItem', JSON.stringify(rowId));
            sessionStorage.setItem('selectedItem', JSON.stringify(rowId));
            navigate(`/${parent}/view`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Decision"
          placement="top"
          arrow
          className={
            rowId.logInStatusName !== 'Sanctioned' && rowId.logInStatusName !== 'Rejected'
              ? 'disabledDocument'
              : ''
          }
          onClick={(e) => {
            sessionStorage.setItem('selectedItem', JSON.stringify(rowId));
            sessionStorage.setItem('selectedItem', JSON.stringify(rowId));
            navigate(`/${parent}/decision`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <img
              src="../static/icons/decision.svg"
              alt="Decision"
              className="decisionImg"
              height={14}
            />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'disburse') {
    return (
      <>
        <Tooltip
          title="View Disbursement"
          placement="top"
          arrow
          onClick={(e) => {
            console.log('rowIdrowId', rowId);
            // sessionStorage.removeItem('SignMappId');
            // sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            const appData = {
              loanTypeId: rowId?.loanTypeId,
              employmentTypeId: rowId?.employmentTypeId,
              loanTypeName: rowId?.loanType
            };
            // sessionStorage.removeItem('SignMappId');
            // sessionStorage.removeItem('SignMappId');

            sessionStorage.setItem('dlpType', JSON.stringify('Disbursement'));
            sessionStorage.setItem('appData', JSON.stringify(appData));
            sessionStorage.setItem('loanTypeName', rowId?.loanTypeName);
            const appToken = CryptoJS.AES.encrypt(
              rowId.loanAccountNumber ? String(rowId.loanAccountNumber) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const module = 'disburse';
            window.open(
              `${domainUrl}sanctioned/decision?u=${encodeURIComponent(
                userToken
              )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                appToken
              )}&ui=${encodeURIComponent(userIdToken)}&module=${module}`,
              `_blank`
            );
          }}

          // onClick={(e) => {
          //   sessionStorage.setItem('selectedItem', JSON.stringify(rowId));
          //   sessionStorage.setItem('selectedItem', JSON.stringify(rowId));
          //   navigate(`/${parent}/view`);
          // }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        {/* <Tooltip title="Approve Disbursement" placement="top" arrow>
          <StyledIconButton
            disabled
            className={
              rowId.status?.toLowerCase() === 'encashment'
                ? 'squareIconButton actionButton'
                : 'disabledDocument squareIconButton actionButton'
            }
            onClick={(e) => {
              sessionStorage.setItem('selectedItem', JSON.stringify(rowId));
              sessionStorage.setItem('selectedItem', JSON.stringify(rowId));
              navigate(`/${parent}/process`);
            }}
          >
            <MdUpdate size={14} />
          </StyledIconButton>
        </Tooltip> */}
        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          onClick={(e) => {
            const leadObj = {
              leadesItem: [rowId]
            };
            console.log('rowIdviewcam', rowId);
            sessionStorage.setItem('loanTypeName', rowId?.parentLoanTypeCode);
            sessionStorage.setItem('dlpType', JSON.stringify('Allotment'));
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.removeItem('SignMappId');
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            const appToken = CryptoJS.AES.encrypt(
              rowId.loanAccountNumber ? String(rowId.loanAccountNumber) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(String(rowId.leadId), 'leadToken').toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(rowId.leadId),
              'loanIdToken'
            ).toString();
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(rowId.loanTypeId),
              'loanTypeIdToken'
            ).toString();
            const module = 'disburse';
            const dlpType = 'Allotment';
            window.open(
              rowId.applicationId !== null
                ? `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                    loanTypeIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&module=${encodeURIComponent(module)}&dlpType=${dlpType}`
                : `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&module=${encodeURIComponent(module)}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'u-disburse') {
    return (
      <>
        {/* <Tooltip title="Activity Tracker" placement="top" arrow>
          <StyledIconButton disabled className="squareIconButton actionButton">
            <BiRun size={14} />
          </StyledIconButton>
        </Tooltip> */}
        <Tooltip
          title="View Disbursement"
          placement="top"
          arrow
          onClick={(e) => {
            console.log('rowIdrowId', rowId);
            // sessionStorage.removeItem('SignMappId');
            // sessionStorage.removeItem('SignMappId');

            const localJWTtoken = userDetails;
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            const appData = {
              loanTypeId: rowId?.loanTypeId,
              employmentTypeId: rowId?.employmentTypeId,
              loanTypeName: rowId?.loanType
            };
            // sessionStorage.removeItem('SignMappId');
            // sessionStorage.removeItem('SignMappId');
            sessionStorage.setItem('dlpType', JSON.stringify('Disbursement'));
            sessionStorage.setItem('appData', JSON.stringify(appData));
            sessionStorage.setItem('loanTypeName', rowId?.loanTypeName);
            const appToken = CryptoJS.AES.encrypt(
              rowId.loanAccountNumber ? String(rowId.loanAccountNumber) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const module = 'decisionudisburse';
            window.open(
              `${domainUrl}sanctioned/decision?u=${encodeURIComponent(
                userToken
              )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                appToken
              )}&ui=${encodeURIComponent(userIdToken)}&module=${module}`,
              `_blank`
            );
          }}

          // onClick={(e) => navigate(`/${parent}/view`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          onClick={(e) => {
            const leadObj = {
              leadesItem: [rowId]
            };
            console.log('rowIdviewcam', rowId);
            sessionStorage.setItem('loanTypeName', rowId?.parentLoanTypeCode);
            sessionStorage.setItem('dlpType', JSON.stringify('Allotment'));
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.removeItem('SignMappId');
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            const appToken = CryptoJS.AES.encrypt(
              rowId.loanAccountNumber ? String(rowId.loanAccountNumber) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(String(rowId.leadId), 'leadToken').toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(rowId.leadId),
              'loanIdToken'
            ).toString();
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(rowId.loanTypeId),
              'loanTypeIdToken'
            ).toString();
            const module = 'udisburse';
            const dlpType = 'Allotment';
            window.open(
              rowId.applicationId !== null
                ? `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                    loanTypeIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&module=${encodeURIComponent(module)}&dlpType=${dlpType}`
                : `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&module=${encodeURIComponent(module)}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'sanctioned') {
    return (
      <>
        {/* <Tooltip title="Activity Tracker" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <BiRun size={14} />
          </StyledIconButton>
        </Tooltip> */}
        <Tooltip
          title="Process Disbursement"
          placement="top"
          arrow
          onClick={(e) => {
            console.log('rowIdrowId', rowId);
            const appData = {
              loanTypeId: rowId?.loanTypeId,
              employmentTypeId: rowId?.employmentTypeId,
              loanTypeName: rowId?.loanType
            };
            // sessionStorage.removeItem('SignMappId');
            // sessionStorage.removeItem('SignMappId');
            sessionStorage.setItem('appData', JSON.stringify(appData));
            sessionStorage.setItem('dlpType', JSON.stringify('Disbursement'));
            sessionStorage.setItem('taskId111', rowId?.loanAccountNumber);
            sessionStorage.setItem('loanTypeName', rowId?.loanTypeName);
            const localJWTtoken = userDetails;
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;

            const appToken = CryptoJS.AES.encrypt(
              rowId.loanAccountNumber ? String(rowId.loanAccountNumber) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            // const module = 'decisionudisburse';
            const module = 'decisionudisburse';
            const dlpType = 'Disbursement';
            window.open(
              `${domainUrl}sanctioned/decision?u=${encodeURIComponent(
                userToken
              )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                appToken
              )}&ui=${encodeURIComponent(userIdToken)}&module=${encodeURIComponent(
                module
              )}&dlpType=${encodeURIComponent(dlpType)}`,
              `_blank`
            );
          }}
          // onClick={(e) => navigate(`/${parent}/process`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <RiFlowChart size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          onClick={(e) => {
            const leadObj = {
              leadesItem: [rowId]
            };
            console.log('rowIdviewcam', rowId);
            sessionStorage.setItem('loanTypeName', rowId?.parentLoanTypeCode);
            sessionStorage.setItem('dlpType', JSON.stringify('Allotment'));
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.removeItem('SignMappId');
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            const appToken = CryptoJS.AES.encrypt(
              rowId.loanAccountNumber ? String(rowId.loanAccountNumber) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(String(rowId.leadId), 'leadToken').toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(rowId.leadId),
              'loanIdToken'
            ).toString();
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(rowId.loanTypeId),
              'loanTypeIdToken'
            ).toString();
            const module = 'sanctioned';
            const dlpType = 'Allotment';
            window.open(
              rowId.applicationId !== null
                ? `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                    loanTypeIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&module=${encodeURIComponent(module)}&dlpType=${dlpType}`
                : `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                    userToken
                  )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                    appToken
                  )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                    userIdToken
                  )}&applicationCreationEntryPlatform=${
                    rowId?.applicationCreationEntryPlatform
                  }&module=${encodeURIComponent(module)}&dlpType=${dlpType}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }

  if (parent === 'rejected') {
    return (
      <>
        <Tooltip
          title="Re Apply"
          placement="top"
          arrow
          className="disabledDocument"
          onClick={(e) => navigate(`/${parent}/reapply`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <BsFillReplyAllFill size={14} />
          </StyledIconButton>
        </Tooltip>

        <Tooltip
          title="View"
          placement="top"
          className="disabledDocument"
          arrow
          onClick={(e) => navigate(`/${parent}/view`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>

        <Tooltip
          title="View CAM"
          placement="top"
          arrow
          onClick={(e) => {
            const leadObj = {
              leadesItem: [rowId]
            };
            console.log('rowIdviewcam', rowId);
            sessionStorage.setItem('loanTypeName', rowId?.parentLoanTypeCode);
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.removeItem('SignMappId');
            sessionStorage.setItem('leadDetails', JSON.stringify(leadObj));
            sessionStorage.removeItem('SignMappId');
            const localJWTtoken = userDetails;
            const authToken = localJWTtoken?.jwt;
            const userId = localJWTtoken?.userId;
            const companyId = localJWTtoken?.companyId;
            const appToken = CryptoJS.AES.encrypt(
              rowId.applicationId ? String(rowId.applicationId) : 'null',
              'appToken'
            ).toString();
            const companyToken = CryptoJS.AES.encrypt(String(companyId), 'companyToken').toString();
            const userToken = CryptoJS.AES.encrypt(authToken, 'userToken').toString();
            const leadToken = CryptoJS.AES.encrypt(String(rowId.leadId), 'leadToken').toString();
            const userIdToken = CryptoJS.AES.encrypt(String(userId), 'userId').toString();
            const loanIdToken = CryptoJS.AES.encrypt(
              String(rowId.leadId),
              'loanIdToken'
            ).toString();
            const loanTypeIdToken = CryptoJS.AES.encrypt(
              String(rowId.loanTypeId),
              'loanTypeIdToken'
            ).toString();
            const module = 'rejected';
            const dlpType = 'Allotment';
            window.open(
              `${domainUrl}login/recommendation-cam?u=${encodeURIComponent(
                userToken
              )}&c=${encodeURIComponent(companyToken)}&a=${encodeURIComponent(
                appToken
              )}&l=${encodeURIComponent(leadToken)}&ui=${encodeURIComponent(
                userIdToken
              )}&li=${encodeURIComponent(loanIdToken)}&lti=${encodeURIComponent(
                loanTypeIdToken
              )}&applicationCreationEntryPlatform=${
                rowId?.applicationCreationEntryPlatform
              }&module=${encodeURIComponent(module)}&dlpType=${dlpType}`,
              `_blank`
            );
          }}
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={
            //   rowId?.applicationCreationEntryPlatform?.toLowerCase() !== 'sales' ? true : false
            // }
          >
            <FaFolderOpen size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }

  if (parent === 'document') {
    return (
      <>
        <Tooltip title="View" placement="top" arrow>
          <StyledIconButton
            disabled
            className="squareIconButton actionButton"
            onClick={(e) => {
              handleViewSupportDocs(e, rowId);
            }}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Upload" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            // classes={{
            //   disabled: 'disableButton'
            // }}
            disabled
            // disabled={rowId.verifiedStatusLabel === 'Approved' ? true : false}
            aria-label="upload picture"
            component="label"
          >
            <BiCloudUpload size={14} />
            <input
              accept="image/png, image/gif, image/jpeg, application/pdf"
              className="fileInput"
              id="contained-button-file1"
              type="file"
              onChange={(e) => {
                handleFileUpload(e, null, setUploadImage, rowId);
                e.target.value = null;
              }}
            />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Verify" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            // classes={{
            //   disabled: 'disableButton'
            // }}
            disabled
            // disabled={rowId.verifiedStatusLabel === 'Approved' ? true : false}
            onClick={(e) => {
              const prevData = verifyPopup;
              prevData.flag = true;
              prevData.rowData = rowId;
              setVerifyPopup({ ...prevData });
            }}
          >
            <MdOutlineVerifiedUser size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="top" arrow>
          <StyledIconButton
            disabled
            className="squareIconButton actionButton"
            onClick={(e) => {
              const prevData = deleteDocPopup;
              prevData.flag = true;
              prevData.rowData = rowId;
              setDeleteDocPopup({ ...prevData });
            }}
          >
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'repaymentPDC') {
    return (
      <>
        <Tooltip title="View" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              // viewUploadedCheque(e, rowId);
              console.log('rowIdrowId', rowId);
              const prevData = viewPopup;
              prevData.open = true;
              prevData.url = rowId.docAsBytes;
              prevData.contentType = rowId.contentType;
              setViewPopup({ ...prevData });
            }}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Upload" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            aria-label="upload picture"
            component="label"
          >
            <BiCloudUpload size={14} />
            <input
              accept="image/png, image/gif, image/jpeg, application/pdf"
              className="fileInput"
              id="contained-button-file1"
              type="file"
              name="pdcUploadChequeImage"
              onChange={(e) => {
                handleChequeUpload(e, null, setUploadImage, rowId?.amortizationDetailId, index);
                e.target.value = null;
              }}
            />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Download" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            disabled={rowId.docAsBytes === null ? true : false}
            aria-label="download picture"
            component="label"
            onClick={(e) => {
              // convertBytecodeToPDF(e, rowId);
              handleDownload(e, rowId);
            }}
          >
            <BiCloudDownload size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'LegalMortgage') {
    return (
      <>
        <Tooltip title="View" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              viewUploadedCheque(e, rowId);
              // setViewPopup(true);
            }}
          >
            <FiEye size={14} />
          </StyledIconButton>
        </Tooltip>
        {/* <Tooltip title="Upload" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            disabled={rowId.verifiedStatusLabel === 'Approved' ? true : false}
            aria-label="upload picture"
            component="label"
          >
            <BiCloudUpload size={14} />
            <input
              accept="image/png, image/gif, image/jpeg, application/pdf"
              className="fileInput"
              id="contained-button-file1"
              type="file"
              name="pdcUploadChequeImage"
              onChange={(e) => {
                handleChequeUpload(e, null, setUploadImage, rowId?.amortizationDetailId, index);
                e.target.value = null;
              }}
            />
          </StyledIconButton>
        </Tooltip> */}
        <Tooltip title="Upload" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            aria-label="upload picture"
            component="label"
            // disabled={rowId?.responseDocument !== null || myState?.disbSecurityLegalHeadId !== null}
          >
            <input
              accept="image/*, application/pdf"
              className="fileInput"
              multiple
              id="contained-button-file1"
              type="file"
              name="responseDocument"
              onChange={(e) => {
                handleLegalDocumentUpload(e, rowId);
              }}
            />
            <BsLink size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Download" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            classes={{
              disabled: 'disableButton'
            }}
            // disabled={rowId.verifiedStatusLabel === 'Approved' ? true : false}
            aria-label="download picture"
            component="label"
            onClick={(e) => {
              convertBytecodeToPDF(e, rowId);
            }}
          >
            <BiCloudDownload size={14} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'mortgage') {
    return (
      <>
        <Tooltip title="Charge Creation Details" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) =>
              setOpenCreateCharge({ flag: true, disbSecurityId: rowId?.disbSecurityId })
            }
          >
            <TbReportMoney size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="View Valuation Report" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={() => {
              sessionStorage.setItem('disburseSecurityDetails', JSON.stringify(rowId));
              sessionStorage.setItem('disburseSecurityDetails', JSON.stringify(rowId));
              window.open(
                `${domainUrl}login/trigger-for-Valuation${
                  getConfigDetails()?.cryptedUrlQuery
                }&${parentComponent}`,
                `_blank`
              );
            }}
          >
            <TbReport size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="View Legal Report" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={() => {
              sessionStorage.setItem('disburseSecurityDetails', JSON.stringify(rowId));
              window.open(
                `${domainUrl}login/legal-application${
                  getConfigDetails()?.cryptedUrlQuery
                }&${parentComponent}`,
                `_blank`
              );
            }}
          >
            <RiFileShield2Line size={14} />
          </StyledIconButton>
        </Tooltip>
        {/* <Tooltip title="Create Charge" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => setOpenCreateCharge(true)}
          >
            <MdOutlineAdd size={14} />
          </StyledIconButton>
        </Tooltip> */}
      </>
    );
  }

  if (parent === 'borrower') {
    return (
      <Tooltip title="File Report" placement="top" arrow>
        <StyledIconButton className="squareIconButton actionButton">
          <TbFileReport size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  // if (parent === 'mortgage') {
  //   return (
  //     <>
  //       <Tooltip title="Send for Mortgage" placement="top" arrow>
  //         <StyledIconButton
  //           className="squareIconButton actionButton"
  //           aria-label="upload picture"
  //           component="label"
  //         >
  //           <MdSend size={14} />
  //         </StyledIconButton>
  //       </Tooltip>
  //       <Tooltip title="Upload Original Document" placement="top" arrow>
  //         <StyledIconButton
  //           className="squareIconButton actionButton"
  //           aria-label="upload picture"
  //           component="label"
  //         >
  //           <BiCloudUpload size={14} />
  //           <input hidden accept="image/*" type="file" />
  //         </StyledIconButton>
  //       </Tooltip>
  //       <Tooltip title="Verified Document" placement="top" arrow>
  //         <StyledIconButton
  //           className="squareIconButton actionButton"
  //           // onClick={(e) => setVerifyPopup(true)}
  //         >
  //           <MdOutlineVerifiedUser size={14} />
  //         </StyledIconButton>
  //       </Tooltip>
  //     </>
  //   );
  // }
  if (parent === 'task') {
    return (
      <Tooltip
        title="View"
        placement="top"
        arrow
        onClick={(e) => {
          sessionStorage.setItem('taskId', rowId.taskId);
          sessionStorage.setItem('taskId', rowId.taskId);
          navigate(`/sanctioned/task/view-task?u${sentUrl}`);
        }}
      >
        <StyledIconButton className="squareIconButton actionButton">
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }

  if (parent === 'credit-task') {
    return (
      <Tooltip title="View" placement="top" arrow>
        <StyledIconButton
          className="squareIconButton actionButton"
          onClick={() => {
            console.log('rowId', rowId);
            sessionStorage.setItem('taskId', rowId.taskId);
            sessionStorage.setItem('taskId111', rowId.applicationId);
            if (moduleName === 'dashboard') {
              navigate(`/credit-task/view`);
            } else {
              navigate(`/login/credit-task/view?u${sentUrl}`);
            }
          }}
        >
          <FiEye size={14} />
        </StyledIconButton>
      </Tooltip>
    );
  }

  // Admin
  if (parent === 'manage-access') {
    return (
      <>
        <Tooltip
          disabled={
            isManageAccessDisabled?.length > 0 && isManageAccessDisabled[0]?.deleteAccess === false
              ? true
              : false
          }
          title="Delete"
          placement="top"
          arrow
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              setOpenDialogBox(true);
              setCurrentRowId(rowId?.accessLevelId);
            }}
          >
            <Icon icon={trash2Outline} width={12} />
          </StyledIconButton>
        </Tooltip>
        {/* {parent !== 'users' ? ( */}
        <Tooltip
          disabled={
            isManageAccessDisabled?.length > 0 && isManageAccessDisabled[0]?.editAccess === false
              ? true
              : false
          }
          title="Edit"
          placement="top"
          arrow
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/${parent}/edit/${rowId}/${rowModuleName}?u${sentUrl}`)}
          >
            <Icon icon={editFill} width={12} />
          </StyledIconButton>
        </Tooltip>
        {/* ) : (
          ''
        )} */}
      </>
    );
  }

  if (parent === 'reports') {
    return (
      <>
        <Tooltip
          disabled={
            isReportDisabled?.length > 0 && isReportDisabled[0]?.deleteAccess === false
              ? true
              : false
          }
          title="Delete"
          placement="top"
          arrow
          onClick={() => handlePublish('Delete', reportJourneyId)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          disabled={
            isReportDisabled?.length > 0 && isReportDisabled[0]?.publishAccess === false
              ? true
              : false
          }
          title={rowId.isPublish === true ? 'Unpublish' : 'Publish'}
          placement="top"
          arrow
          onClick={() =>
            handlePublish(rowId.isPublish === true ? 'Unpublish' : 'Publish', reportJourneyId)
          }
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdPublishedWithChanges size={14} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          disabled={
            isReportDisabled?.length && isReportDisabled[0]?.editAccess === false ? true : false
          }
          title="Edit"
          placement="top"
          arrow
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              sessionStorage.removeItem('firstWizardResponse');
              // sessionStorage.removeItem('adminReportTypeId');

              console.log('rowId222', rowId);
              navigate(`/${parent}/add?u${sentUrl}`);
              sessionStorage.setItem('reportJourneyId', rowId.reportJourneyId);
              if (rowId.reportType.typeId) {
                sessionStorage.setItem('adminReportTypeId', rowId.reportType.typeId);
              }
            }}
          >
            <Icon icon={editFill} width={12} />
          </StyledIconButton>
        </Tooltip>
        {/* ) : (
          ''
        )} */}
      </>
    );
  }

  if (parent === 'admin-access') {
    return (
      <>
        <Tooltip title={rowModuleName} placement="top" arrow>
          <StyledIconButton
            disabled={
              (isAdminAccessDisabled.length > 0 &&
                isAdminAccessDisabled[0]?.deleteAccess === false) ||
              (rowModuleName === 'Super Admin' ? true : false)
            }
            className="squareIconButton actionButton"
            onClick={(e) => {
              setOpenDialogBox(true);
              setCurrentRowId(rowId);
            }}
          >
            <Icon icon={trash2Outline} width={12} />
          </StyledIconButton>
        </Tooltip>
        {/* {parent !== 'users' ? ( */}
        <Tooltip title="Edit" placement="top" arrow>
          <StyledIconButton
            disabled={
              isAdminAccessDisabled?.length > 0 && isAdminAccessDisabled[0]?.editAccess === false
                ? true
                : false
            }
            className="squareIconButton actionButton"
            onClick={(e) => navigate(`/${parent}/edit/${rowId}/${rowModuleName}?u${sentUrl}`)}
          >
            <Icon icon={editFill} width={12} />
          </StyledIconButton>
        </Tooltip>
        {/* ) : (
          ''
        )} */}
      </>
    );
  }

  if (parent === 'usersAdmin') {
    return (
      <>
        <Tooltip title="Delete" placement="top" arrow>
          <StyledIconButton
            disabled={
              isUserListDisabled?.length > 0 && isUserListDisabled[0]?.deleteAccess === false
                ? true
                : false
            }
            className="squareIconButton actionButton"
            onClick={(e) => {
              setOpenDialogBox(true);
              setCurrentRowId(rowId);
            }}
          >
            <Icon icon={trash2Outline} width={12} />
          </StyledIconButton>
        </Tooltip>
        {/* {parent !== 'users' ? ( */}
        <Tooltip title="Edit" placement="top" arrow>
          <StyledIconButton
            disabled={
              isUserListDisabled?.length > 0 && isUserListDisabled[0]?.editAccess === false
                ? true
                : false
            }
            className="squareIconButton actionButton"
            onClick={(e) => {
              console.log('sentUrl3333', sentUrl);
              navigate(`/users/edit/${rowId}?u${sentUrl}`);
            }}
          >
            <Icon icon={editFill} width={12} />
          </StyledIconButton>
        </Tooltip>
        {/* ) : (
          ''
        )} */}
      </>
    );
  }
  if (parent === 'admin-user') {
    return (
      <>
        <Tooltip
          disabled={
            isUserAccessDisabled?.length > 0 && isUserAccessDisabled[0]?.deleteAccess === false
              ? true
              : false
          }
          title="Delete"
          placement="top"
          arrow
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              setOpenDialogBox(true);
              setCurrentRowId(rowId);
            }}
          >
            <Icon icon={trash2Outline} width={12} />
          </StyledIconButton>
        </Tooltip>
        {/* {parent !== 'users' ? ( */}
        <Tooltip
          disabled={
            isUserAccessDisabled?.length > 0 && isUserAccessDisabled[0]?.editAccess === false
              ? true
              : false
          }
          title="Edit"
          placement="top"
          arrow
        >
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => {
              console.log('sentUrl3333', sentUrl);
              navigate(`/admin-user/edit/${rowId}?u${sentUrl}`);
            }}
          >
            <Icon icon={editFill} width={12} />
          </StyledIconButton>
        </Tooltip>
        {/* ) : (
          ''
        )} */}
      </>
    );
  }

  return (
    <>
      <Tooltip title="Delete" placement="top" arrow>
        <StyledIconButton className="squareIconButton actionButton">
          <Icon icon={trash2Outline} width={14} />
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="Edit" placement="top" arrow>
        <StyledIconButton className="squareIconButton actionButton">
          <Icon icon={editFill} width={14} />
        </StyledIconButton>
      </Tooltip>
    </>
  );
}
