import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import CryptoJS from 'crypto-js';
import { OutlinedInput } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BrudCrumbs from '../../../../components/BreadCrumbs';
import Page from '../../../../components/Page';
import PageTitle from '../../../../components/PageHeading';
import { getConfigDetails } from '../../../../utils/getConfigdetails';
import ApiErrorMessage from '../../../Global/ApiErrorMessage';
import Loader from '../../../Global/Loader';
import { AdminService } from '../../../services/Services';

const {
  UserTypeList,
  AdminOfAccessLevel,
  AdminUpdateUser,
  AdminCreateUser,
  DepartmentList,
  DepartmentLevelList,
  BranchList,
  ListOfAccessLevel,
  ListOfReportAccessLevel,
  ReportingManager,
  DesignationList,
  ProductModule,
  CreateUser,
  InternalSalesManager,
  ViewUser,
  AdminViewUser,
  UpdateUser,
  OrganizationList,

  AdminOfAddAccessLevel
} = AdminService;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function AddEditAdminUser({ sentUrl }) {
  const navigate = useNavigate();
  const { rowId } = useParams();
  const inputRef = useRef();
  const [token, setToken] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [checkRefresh, setCheckRefresh] = useState(null);
  const [showHide, setShowHide] = useState(50);
  // const [userType, setUserType] = useState(' ');
  // apis response list
  const [userTypeListsresponse, setUserTypeListsresponse] = useState('');
  const [departmentListresponse, setDepartmentListresponse] = useState('');
  const [departmentLevelListresponse, setDepartmentLevelListresponse] = useState('');
  const [branchListresponse, setBranchListresponse] = useState({
    homeBranch: [],
    coverBranches: []
  });
  const [listOfAccessLevelresponse, setListOfAccessLevelresponse] = useState([]);
  const [listOfReportAccessLevelresponse, setListOfReportAccessLevelresponse] = useState('');
  const [organizationListresponse, setOrganizationListresponse] = useState('');
  const [reportingManagerresponse, setReportingManagerresponse] = useState('');
  const [designationListResponse, setDesignationListResponse] = useState('');
  const [internalSalesManagerresponse, setInternalSalesManagerresponse] = useState('');
  const [productModuleresponse, setProductModuleresponse] = useState('');
  const [productModule, setProductModule] = useState([]);
  const [viewSingleData, setViewSingleData] = useState('');
  const [createUserResponse, setcreateUserResponse] = useState('');
  const [updateUserResponse, setUpdateUserResponse] = useState('');
  const [defalutPass, setDefaultPass] = useState('');
  const [disableEditPass, setDisableEditPass] = useState(true);
  const [openLoader, setOpenLoader] = useState(false); // loader
  const [selectedModuleMapping, setSelectedModuleMapping] = useState(false);
  const [firstClick, setFirstClick] = useState(true);
  const [selectbranch, setSelectBranch] = useState([]);

  console.log(listOfAccessLevelresponse, 'line no 96');

  const [createUserForm, setCreateUserForm] = useState({
    userFirstName: '',
    userMiddleName: '',
    userLastName: '',
    contactNumber: '',
    branchId: [],
    homeBranchId: ' ',
    departmentId: ' ',
    departmentLevelId: ' ',
    reportingManagerId: ' ',
    adminAccessLevelId: ' ',
    accessLevelReportId: ' ',
    designationId: ' ',
    emailAddress: '',
    password: '',
    userEmailId: ''
  });

  console.log('createUserForm33', createUserForm);
  console.log('createUserForm33-branchListresponse', branchListresponse);

  const [createUserExternal, setCreateUserExternal] = useState({
    organizationId: '',
    userFirstName: '',
    contactNumber: '',
    departmentId: '',
    departmentLevelId: '',
    reportingManagerId: '',
    internalSalesManagerId: '',
    adminAccessLevelId: '',
    accessLevelReportId: '',
    designationId: '',
    emailAddress: '',
    password: '',
    userEmailId: ''
  });

  // random password state
  const [password, setPassword] = useState('');
  const [length, setLength] = useState(10);
  const [isLowerCase, setIsLowerCase] = useState(true);
  const [isUpperCase, setIsUpperCase] = useState(true);
  const [isNumbers, setIsNumbers] = useState(true);
  const [isSymbols, setIsSymbols] = useState(true);
  const [openApiError, setOpenApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const externalChangeHandler = (e, property) => {
    setCreateUserExternal((prevState) => ({
      ...prevState,
      [property]: e.target.value
    }));
  };

  const inputChangeHandler = (e, property) => {
    console.log(createUserForm, 'line 136', property, e.target.value);
    if (property === 'contactNumber') {
      const input = e.target.value;
      if (input.length <= 10) {
        setCreateUserForm((prevState) => ({
          ...prevState,
          [property]: input
        }));
      }
    } else if (property === 'homeBranchId') {
      const homeBranchValue = e.target.value;

      setCreateUserForm((prevState) => ({
        ...prevState,
        [property]: homeBranchValue,
        branchId: [homeBranchValue]
      }));

      branchListresponse.coverBranches.forEach((item) => {
        if (item.branchId === homeBranchValue) {
          item.isActive = true;
        }
        return item.branchId;
      });

      console.log('branchListresponse', branchListresponse);
    } else if (property === 'branchId') {
      const selectedBranchId = e.target.value;
      const homeData = createUserForm?.homeBranchId;

      const updatedBranches = [createUserForm?.homeBranchId, ...selectedBranchId];
      console.log(updatedBranches, 'line no 167');
      const updateData = new Set(updatedBranches);
      console.log(updateData, 'line no 169');
      setCreateUserForm((prevState) => ({
        ...prevState,
        [property]: [...updateData]
      }));
    } else {
      console.log('propertyinput', property, e.target.value);
      setCreateUserForm((prevState) => ({
        ...prevState,
        [property]: e.target.value
      }));
      setViewSingleData((prevState) => ({
        ...prevState,
        [property]: e.target.value
      }));
    }
  };
  const handleShowHide = (event) => {
    const getUserType = event.target.value;
    setShowHide(getUserType);
  };

  const createUserHandler = async (e) => {
    const emailRegex =
      /^(?=.{1,64}@)[a-z0-9_-]+(\.[a-z0-9_-]+)*@[^-][a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/;
    const mobileRegex =
      /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;

    // if (userType === ' ') {
    //   setOpenApiError(true);
    //   setErrorMessage('Please select user type');
    //   return;
    // }
    if (createUserForm.userFirstName === '') {
      setOpenApiError(true);
      setErrorMessage('Please enter your first name');
      return;
    }
    // if (createUserForm.userLastName === '') {
    //   setOpenApiError(true);
    //   setErrorMessage('Please enter your last name');
    //   return;
    // }
    // if (!mobileRegex.test(createUserForm.contactNumber)) {
    if (createUserForm.contactNumber === '') {
      setOpenApiError(true);
      setErrorMessage('Please enter your mobile number');
      return;
    }
    if (createUserForm.contactNumber.length < 10) {
      setOpenApiError(true);
      setErrorMessage('Please enter 10 digit mobile number');
      return;
    }
    // if (createUserForm.branchId.length === 0) {
    //   setOpenApiError(true);
    //   setErrorMessage('Please select branch');
    //   return;
    // }
    // if (createUserForm.homeBranchId.length === 0) {
    //   setOpenApiError(true);
    //   setErrorMessage('Please select branch');
    //   return;
    // }
    // if (createUserForm.departmentId === ' ') {
    //   setOpenApiError(true);
    //   setErrorMessage('Please select department');
    //   return;
    // }
    if (createUserForm.adminAccessLevelId === ' ') {
      setOpenApiError(true);
      setErrorMessage('Please select access');
      return;
    }
    // if (createUserForm.accessLevelReportId === ' ') {
    //   setOpenApiError(true);
    //   setErrorMessage('Please select report access');
    //   return;
    // }
    // if (createUserForm.emailAddress === '') {
    //   setOpenApiError(true);
    //   setErrorMessage('Please enter your email address');
    //   return;
    // }
    if (!emailRegex.test(createUserForm.emailAddress)) {
      setOpenApiError(true);
      setErrorMessage('Please enter valid email address');
      return;
    }
    if (!emailRegex.test(createUserForm.userEmailId)) {
      setOpenApiError(true);
      setErrorMessage('Please enter valid user email');
      return;
    }
    if (rowId === undefined && createUserForm.password === '') {
      setOpenApiError(true);
      setErrorMessage('Please enter your password');
      return;
    }

    const filteredArray =
      productModule.length > 0 && productModule.filter((item, index) => item.isEnable);
    if (filteredArray.length === 0) {
      setOpenApiError(true);
      setErrorMessage('Please select atleast one module mapping');
      return;
    }

    // creating first middle last name from full  name
    const names = createUserForm?.userFirstName?.trim().split(' ');
    // if legnth is greater then 1 , mean string has more then one space
    let firstName = '';
    let middleName = '';
    let lastName = '';

    for (let i = 0; i < names.length; i++) {
      if (i === 0) {
        firstName = names[i].trim();
      } else if (i === 1 && i === names.length) {
        lastName = names[i].trim();
      } else if (i === 1) {
        middleName = names[i].trim();
      } else if (i > 1) {
        lastName += ` ${names[i].trim()}`;
      }
    }
    lastName = lastName.trim();

    // for external user type
    // creating first middle last name from full  name
    const names1 = createUserExternal?.userFirstName?.trim().split(' ');
    // if legnth is greater then 1 , mean string has more then one space
    let firstName1 = '';
    let middleName1 = '';
    let lastName1 = '';

    for (let i = 0; i < names1.length; i++) {
      if (i === 0) {
        firstName1 = names1[i].trim();
      } else if (i === 1 && i === names1.length) {
        lastName1 = names1[i].trim();
      } else if (i === 1) {
        middleName1 = names1[i].trim();
      } else if (i > 1) {
        lastName1 += ` ${names1[i].trim()}`;
      }
    }
    lastName1 = lastName.trim();
    const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));
    const createCredential = {
      // adminAccessLevelName: createUserForm.
      // userTypeId: Number(userType),
      adminAccessLevelId: createUserForm?.adminAccessLevelId
        ? createUserForm?.adminAccessLevelId
        : createUserExternal?.adminAccessLevelId,

      companyId: localJWTtoken?.token?.companyId,
      // companyId:
      //   getConfigDetails()?.urlCompanyId !== null && getConfigDetails()?.urlCompanyId !== 'null'
      //     ? Number(getConfigDetails()?.urlCompanyId)
      //     : null,
      // departmentId: createUserForm?.departmentId
      // ? createUserForm?.departmentId
      // : createUserExternal?.departmentId,
      // departmentLevelId:
      //   createUserForm?.departmentLevelId?: createUserExternal?.departmentLevelId,
      // // branchIds: userType === 50 ? createUserForm?.branchId : [],
      // coverBranchIds: Array.isArray(createUserForm?.branchId) ? createUserForm.branchId : [],

      // homeBranchId: createUserForm.homeBranchId ? createUserForm.homeBranchId : ' ',
      // designationId: createUserForm?.designationId ? createUserForm?.designationId : ' ',
      userFirstName: createUserForm.userFirstName ? createUserForm.userFirstName : firstName1,
      userMiddleName:
        createUserForm.userMiddleName !== '' ? createUserForm.userMiddleName : middleName1,
      userLastName: createUserForm.userLastName ? createUserForm.userLastName : lastName1,
      // userFirstName: firstName,
      // userMiddleName: middleName,
      // userLastName: lastName,
      password: createUserForm.password !== '' ? createUserForm.password : null,
      contactNumber: createUserForm?.contactNumber
        ? createUserForm?.contactNumber
        : createUserExternal?.contactNumber,
      emailAddress: createUserForm.emailAddress,
      userEmailId: createUserForm.userEmailId,
      ...productModuleresponse
    };
    if (rowId !== undefined) {
      setOpenLoader(true);
      createCredential.userAccessLevelMapId = viewSingleData.userAccessLevelMapId;
      createCredential.userAccessLevelReportMapId = viewSingleData.userAccessLevelReportMapId;
      createCredential.userId = viewSingleData.userId;
      createCredential.reportingManagerId = viewSingleData?.reportingManagerId;

      const token1 =
        getConfigDetails()?.urlAppToken !== null && getConfigDetails()?.urlAppToken !== 'null'
          ? getConfigDetails()?.urlAppToken
          : null;

      const UpdateUserApi = await AdminUpdateUser(createCredential, localJWTtoken?.token?.jwt);

      if (UpdateUserApi?.status === 'success') {
        setOpenLoader(false);
        setUpdateUserResponse(UpdateUserApi.data);
        navigate(`/admin-user?u${sentUrl}`);
      } else if (UpdateUserApi?.status === 'error') {
        setOpenLoader(false);
        setOpenApiError(true);
        setErrorMessage(UpdateUserApi.message);
      } else {
        setOpenLoader(false);
        setOpenApiError(true);
        setErrorMessage('Something went wrong !');
      }
    } else {
      setOpenLoader(true);
      const CreateUserApi = await AdminCreateUser(createCredential, localJWTtoken?.token?.jwt);

      if (CreateUserApi?.status === 'success') {
        setOpenLoader(false);
        setcreateUserResponse(CreateUserApi.data);
        navigate(`/admin-user?u${sentUrl}`);
      } else if (CreateUserApi?.status === 'error') {
        setOpenLoader(false);
        setOpenApiError(true);
        setErrorMessage(CreateUserApi?.message);
      } else {
        setOpenLoader(false);
        setOpenApiError(true);
        setErrorMessage('Something went wrong !');
      }
    }
  };
  const addMoreMasterValue = () => {
    const prevValue = masterValue;
    const obj = { masterValue: '' };
    prevValue.push(obj);
    setMasterValue(prevValue);
    setRefreshComp(new Date());
  };
  const removeMasterValue = (index) => {
    const prevValue = masterValue;
    const removedItem = prevValue.filter((elm, ind) => ind !== index);
    setMasterValue(removedItem);
    setRefreshComp(new Date());
  };

  const fireExternalApi = (e, userTypeId2) => {
    const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));

    const authToken2 =
      getConfigDetails()?.urlAppToken !== null && getConfigDetails()?.urlAppToken !== 'null'
        ? getConfigDetails()?.urlAppToken
        : null;
    const companyId2 =
      getConfigDetails()?.urlCompanyId !== null && getConfigDetails()?.urlCompanyId !== 'null'
        ? Number(getConfigDetails()?.urlCompanyId)
        : null;

    // OrganizationList api
    const OrganizationCredential = {
      companyId: companyId2
    };
    const OrganizationListApi = async () => {
      const OrganizationListresponse = await OrganizationList(OrganizationCredential, authToken2);

      if (OrganizationListresponse?.status === 'success') {
        setOrganizationListresponse(OrganizationListresponse?.data);
      }
    };
    OrganizationListApi();

    // InternalSalesManager api
    const InternalCredential = {
      companyId: companyId2
    };
    const InternalSalesManagerApi = async () => {
      const InternalSalesManagerresponse = await InternalSalesManager(
        InternalCredential,
        authToken2
      );
      if (InternalSalesManagerresponse?.status === 'success') {
        setInternalSalesManagerresponse(InternalSalesManagerresponse.data);
      }
    };
    InternalSalesManagerApi();
  };

  const addEditUserApiFire = () => {
    let cancelSub = false;
    const localJWTtoken = JSON.parse(sessionStorage.getItem('userDetails'));
    const authTokenForAdmin = localJWTtoken.token.jwt;
    const authToken =
      getConfigDetails()?.urlAppToken !== null && getConfigDetails()?.urlAppToken !== 'null'
        ? getConfigDetails()?.urlAppToken
        : null;
    const companyId =
      getConfigDetails()?.urlCompanyId !== null && getConfigDetails()?.urlCompanyId !== 'null'
        ? Number(getConfigDetails()?.urlCompanyId)
        : null;
    setToken(authTokenForAdmin);
    setCompanyId(companyId);

    const credentials = {
      companyId: localJWTtoken.token?.companyId
    };

    // ListOfAccessLevel api
    const ListOfAccessLevelApi = async () => {
      const newCred = {
        companyId: localJWTtoken.token?.companyId,
        removeAccessLevelReports: true
      };
      const ListOfAccessLevelresponse = await AdminOfAccessLevel(
        newCred,
        localJWTtoken?.token?.jwt
      );
      if (ListOfAccessLevelresponse?.status === 'success') {
        if (cancelSub) return;
        setListOfAccessLevelresponse(ListOfAccessLevelresponse.data);
      }
    };
    ListOfAccessLevelApi();

    // ListOfReportAccessLevel api
    const ListOfReportAccessLevelApi = async () => {
      const ListOfReportAccessLevelresponse = await ListOfReportAccessLevel(
        credentials,
        localJWTtoken?.token?.jwt
      );
      if (ListOfReportAccessLevelresponse?.status === 'success') {
        if (cancelSub) return;
        setListOfReportAccessLevelresponse(ListOfReportAccessLevelresponse.data);
      }
    };
    ListOfReportAccessLevelApi();

    //  designation List api
    // const DesignationCredentail = {
    //   companyId: companyId
    // };
    // const DesignationListApi = async () => {
    //   const DesignationListResponse = await DesignationList(DesignationCredentail, authToken);
    //   if (DesignationListResponse.status === 'success') {
    //     setDesignationListResponse(DesignationListResponse.data);
    //   } else {
    //     setDesignationListResponse('');
    //   }
    // };
    // DesignationListApi();

    // ProductModule api
    // const ProductModuleApi = async () => {
    //   const ProductModuleresponse = await ProductModule(credentials, authToken);
    //   if (ProductModuleresponse.status === 'success') {
    //     if (cancelSub) return;
    //     setProductModuleresponse(ProductModuleresponse.data);
    //     setProductModule(ProductModuleresponse.data.modules);
    //   }
    // };
    // ProductModuleApi();

    if (rowId !== undefined) {
      //  setting random pass
      const pwd = {
        length: length,
        lowercase: isLowerCase,
        uppercase: isUpperCase,
        numbers: isNumbers,
        symbols: isSymbols
      };
      setPassword(pwd);
      // setDefaultPass(pwd);
      setDefaultPass('hhhhhhhhhhhh');

      const Editcredentials = {
        companyId: localJWTtoken?.token?.companyId,
        // companyId:
        //   getConfigDetails()?.urlCompanyId !== null && getConfigDetails()?.urlCompanyId !== 'null'
        //     ? Number(getConfigDetails()?.urlCompanyId)
        //     : null,
        adminUserId: Number(rowId)
      };
      (async (e) => {
        const ViewUserApi = await AdminViewUser(Editcredentials, localJWTtoken?.token?.jwt);
        if (ViewUserApi?.status === 'success') {
          if (cancelSub) return;
          const viewSingleData = ViewUserApi.data;
          console.log(viewSingleData, 'line no 506');
          setViewSingleData(viewSingleData);

          if (viewSingleData.userTypeId === 52) {
            fireExternalApi(e, viewSingleData.userTypeId);
          }

          setCreateUserForm((prevState) => ({
            ...prevState,
            userFirstName:
              viewSingleData?.userFirstName &&
              viewSingleData?.userFirstName !== null &&
              viewSingleData?.userFirstName !== undefined &&
              viewSingleData?.userFirstName !== ''
                ? viewSingleData?.userFirstName
                : '',
            userMiddleName:
              viewSingleData?.userMiddleName &&
              viewSingleData?.userMiddleName !== null &&
              viewSingleData?.userMiddleName !== undefined &&
              viewSingleData?.userMiddleName !== ''
                ? viewSingleData?.userMiddleName
                : '',
            userLastName:
              viewSingleData?.userLastName &&
              viewSingleData?.userLastName !== null &&
              viewSingleData?.userLastName !== undefined &&
              viewSingleData?.userLastName !== ''
                ? viewSingleData?.userLastName
                : '',
            contactNumber:
              viewSingleData?.contactNumber &&
              viewSingleData?.contactNumber !== null &&
              viewSingleData?.contactNumber !== undefined &&
              viewSingleData?.contactNumber !== ''
                ? viewSingleData?.contactNumber
                : '',
            departmentId:
              viewSingleData?.departmentId &&
              viewSingleData?.departmentId !== null &&
              viewSingleData?.departmentId !== undefined &&
              viewSingleData?.departmentId !== ''
                ? viewSingleData?.departmentId
                : ' ',
            adminAccessLevelId:
              viewSingleData?.adminAccessLevelId &&
              viewSingleData?.adminAccessLevelId !== null &&
              viewSingleData?.adminAccessLevelId !== undefined &&
              viewSingleData?.adminAccessLevelId !== ''
                ? viewSingleData?.adminAccessLevelId
                : ' ',
            accessLevelReportId:
              viewSingleData?.accessLevelReportId &&
              viewSingleData?.accessLevelReportId !== null &&
              viewSingleData?.accessLevelReportId !== undefined &&
              viewSingleData?.accessLevelReportId !== ''
                ? viewSingleData?.accessLevelReportId
                : ' ',
            designationId:
              viewSingleData?.designationId &&
              viewSingleData?.designationId !== null &&
              viewSingleData?.designationId !== undefined &&
              viewSingleData?.designationId !== ''
                ? viewSingleData?.designationId
                : ' ',
            emailAddress:
              viewSingleData?.emailAddress &&
              viewSingleData?.emailAddress !== null &&
              viewSingleData?.emailAddress !== undefined &&
              viewSingleData?.emailAddress !== ''
                ? viewSingleData?.emailAddress
                : '',

            userEmailId:
              viewSingleData?.userEmailId &&
              viewSingleData.userEmailId !== null &&
              viewSingleData.userEmailId !== undefined &&
              viewSingleData.userEmailId !== ''
                ? viewSingleData.userEmailId
                : '',
            branchId:
              viewSingleData?.coverBranchIds &&
              viewSingleData?.coverBranchIds !== null &&
              viewSingleData?.coverBranchIds !== undefined &&
              viewSingleData?.coverBranchIds.length > 0
                ? viewSingleData?.coverBranchIds
                : ' ',
            homeBranchId:
              viewSingleData?.homeBranchId &&
              viewSingleData?.homeBranchId !== null &&
              viewSingleData?.homeBranchId !== undefined &&
              viewSingleData?.homeBranchId !== ''
                ? viewSingleData?.homeBranchId
                : ' ',

            reportingManagerId:
              viewSingleData?.reportingManagerId &&
              viewSingleData?.reportingManagerId !== null &&
              viewSingleData?.reportingManagerId !== undefined &&
              viewSingleData?.reportingManagerId !== ''
                ? viewSingleData?.reportingManagerId
                : ' ',
            password: ''
          }));
          setCreateUserExternal((prevState) => ({
            ...prevState,
            organizationId:
              viewSingleData.organizationId !== null ? viewSingleData.organizationId : '',
            userFirstName: `${viewSingleData.userFirstName} ${viewSingleData.userMiddleName} ${viewSingleData.userLastName}`,
            contactNumber: viewSingleData.contactNumber,
            departmentId: viewSingleData.departmentId,
            departmentLevelId: viewSingleData.departmentLevelId,
            reportingManagerId: viewSingleData.reportingManagerId,
            internalSalesManagerId: viewSingleData.internalSalesManagerId,
            adminAccessLevelId: viewSingleData.adminAccessLevelId,
            accessLevelReportId: viewSingleData.accessLevelReportId,
            designationId: viewSingleData.designationId,
            emailAddress: viewSingleData.emailAddress,
            userEmailId: viewSingleData.userEmailId
            // password: ''
          }));
        }
      })();
    }
    return () => {
      cancelSub = true;
    };
  };

  useEffect(() => {
    addEditUserApiFire();
  }, [refreshComp]);

  useEffect(() => {
    if (rowId === undefined) {
      setCreateUserForm({
        userFirstName: '',
        userMiddleName: '',
        userLastName: '',
        contactNumber: '',
        branchId: [],
        homeBranchId: ' ',
        departmentId: ' ',
        departmentLevelId: ' ',
        reportingManagerId: ' ',
        adminAccessLevelId: ' ',
        accessLevelReportId: ' ',
        designationId: ' ',
        emailAddress: '',
        password: '',
        userEmailId: ''
      });
    }
  }, []);
  return (
    <Page title="LOS Admin - Users">
      <Container maxWidth="xl">
        <Box pt={3} pb={3} mb={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="pageHeading"
          >
            <PageTitle info={rowId ? 'Edit user' : 'Add new user'} />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Box>
            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              <strong>General</strong>
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography variant="h6" className="formLabel">
                    First Name
                    <Typography variant="span" color="error">
                      {' '}
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type your first name"
                    value={createUserForm.userFirstName}
                    onChange={(e) => inputChangeHandler(e, 'userFirstName')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" className="formLabel">
                    Middle Name
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type your middle name"
                    value={createUserForm.userMiddleName}
                    onChange={(e) => inputChangeHandler(e, 'userMiddleName')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" className="formLabel">
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type your last name"
                    value={createUserForm.userLastName}
                    onChange={(e) => inputChangeHandler(e, 'userLastName')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" className="formLabel">
                    Mobile
                    <Typography variant="span" color="error">
                      {' '}
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    type="number"
                    placeholder="Type mobile number"
                    value={createUserForm.contactNumber}
                    onChange={(e) => {
                      inputChangeHandler(e, 'contactNumber');
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="h6" className="formLabel">
                    Admin Access
                    <Typography variant="span" color="error">
                      {' '}
                      *
                    </Typography>
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: `textInput customSelectBox dissabledMenu${createUserForm.adminAccessLevelId}`
                    }}
                    value={createUserForm.adminAccessLevelId}
                    onChange={(e) => inputChangeHandler(e, 'adminAccessLevelId')}
                  >
                    <MenuItem value=" " disabled>
                      Select access
                      {createUserForm.adminAccessLevelId}
                    </MenuItem>
                    {listOfAccessLevelresponse?.length > 0 &&
                      listOfAccessLevelresponse?.map((accesslevel, index) => (
                        <MenuItem key={index} value={accesslevel.adminAccessLevelId}>
                          {accesslevel.accessLevelName}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
            </Box>

            <Typography variant="body1" className="headingLineUp headingLineUpOut">
              <strong>User ID Creation</strong>
            </Typography>
            <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      User ID
                      <Typography variant="span" color="error">
                        {' '}
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      type="text"
                      disabled={rowId ? disableEditPass : false}
                      placeholder="Write your email"
                      autoComplete="new-password"
                      // value={rowId ? createUserExternal.emailAddress : createUserForm.emailAddress}
                      value={createUserForm.emailAddress}
                      onChange={(e) => inputChangeHandler(e, 'emailAddress')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      User Email
                      <Typography variant="span" color="error">
                        {' '}
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      type="text"
                      disabled={rowId ? disableEditPass : false}
                      placeholder="Write your email"
                      autoComplete="new-password"
                      // value={rowId ? createUserExternal.emailAddress : createUserForm.emailAddress}
                      value={createUserForm.userEmailId}
                      onChange={(e) => inputChangeHandler(e, 'userEmailId')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  {rowId ? (
                    <Box className="formRowHolder fullrow">
                      <Typography variant="h6" className="formLabel">
                        Password
                        {/* <Typography variant="span" color="error">
                        {' '}
                        *
                      </Typography> */}
                      </Typography>
                      <Box sx={{ display: 'flex' }}>
                        <TextField
                          type="password"
                          autoComplete="new-password"
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Write your password"
                          value={
                            rowId && createUserForm.password === '' && firstClick
                              ? defalutPass
                              : createUserForm.password
                          }
                          inputRef={inputRef}
                          onChange={(e) => {
                            setFirstClick(false);
                            if (inputRef.current.clicked) {
                              setDefaultPass('');
                              const prevData = createUserForm;
                              prevData.password = e.target.value;
                              setCreateUserForm({ ...prevData });
                            }
                            inputRef.current.clicked = true;
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box className="formRowHolder fullrow">
                      <Typography variant="h6" className="formLabel">
                        Password
                        <Typography variant="span" color="error">
                          {' '}
                          *
                        </Typography>
                      </Typography>
                      <TextField
                        fullWidth
                        type="password"
                        autoComplete="new-password"
                        inputProps={{
                          className: 'textInput'
                        }}
                        placeholder="write your password"
                        value={createUserForm.password}
                        onChange={(e) => {
                          inputChangeHandler(e, 'password');
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>

            {/* <Typography variant="body1" className="headingLineUp headingLineUpOut">
            <strong>Module Mapping</strong>
          </Typography>
          <Box className="viewLeadCard cardTopMinusMargin" mb={3}>
            <Grid container spacing={3}>
              {productModuleresponse?.modules !== undefined &&
              productModuleresponse?.modules?.length > 0
                ? productModuleresponse?.modules?.map((module, index) => (
                    <Grid key={index} item xs={3}>
                      <Checkbox
                        checked={module.isEnable}
                        onClick={(e) => {
                          const prevModule = productModuleresponse;
                          prevModule.modules[index].isEnable = e.target.checked;
                          setProductModule(prevModule.modules);
                          setProductModuleresponse(prevModule);
                          setCheckRefresh(new Date());
                        }}
                      />
                      {module.moduleName}
                    </Grid>
                  ))
                : ''}
            </Grid>
          </Box> */}

            <Box className="" textAlign="right">
              <Button variant="" className="mainBtn" onClick={(e) => createUserHandler(e)}>
                {rowId !== undefined ? 'Update' : 'Save'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Loader openLoader={openLoader} />
      <ApiErrorMessage
        openApiError={openApiError}
        setOpenApiError={setOpenApiError}
        errorMessage={errorMessage}
      />
    </Page>
  );
}

export default AddEditAdminUser;
