const initialState = {
  errorMsg: null,
  loader: false,
  active_user: [],
  countryApiData: [],
  fetch_list_api: false,
  valuation_data: {
    disbSecurityValuationId: null,
    disbSecurityId: null,
    valuationTriggeredOn: '',
    formattedValuationTriggeredOn: '',
    valuationReceivedOn: '',
    formattedValuationReceivedOn: '',
    valuationApproachId: null,
    valuationReportLink: '',
    valuationPanelId: null,
    fairMarketValue: null,
    realizableValue: null,
    distressSaleValue: null,
    surveyNo: '',
    tauzi: '',
    khataNo: '',
    surveyKhasraNo: '',
    developerName: '',
    landUse: '',
    propertyUsedBy: '',
    noOfFloors: null,
    circleRateOfPropertyWithUnit: null,
    provideTable: '',
    particularOfItems: '',
    plinthAreaSqft: null,
    propertyDocuments: [],
    roofHtFt: null,
    ageOfBuildingMonths: null,
    constructionCost: null,
    replacementCost: null,
    depreciationCost: null,
    netValue: null,
    disbSecurityDetails: {
      disbSecurityId: null,
      applicationId: null,
      securityAddress: '',
      securityOwner: '',
      securityType: '',
      branchName: '',
      superAreaSqft: null,
      builtUpAraaSqft: null,
      securityEvaluationStatusId: ''
    }
  },
  field_visit_state: {
    fieldVisitId: null,
    personMet: null,
    fieldVisitBy: ' ',
    otherFacilitiesEnjoying: false,
    securityFoundInGoodCondition: false,
    dpMatchesWithStockStatement: false,
    fieldVisitObservation: null,
    fieldReportApproverSignFile: null,
    fieldVisitorSignFile: null,
    fieldVisitPhotosCapture: []
  },
  legal_verification_details: {},

  legal_verification_details_doc: {},
  trigger_for_legal_state: {
    disbSecurityLegalId: null
  },
  save_charge_creation_details: {
    titleClearanceReportStatusArray: [],
    appointmentModeArray: [],
    titleClearanceReportStatus: ' '
  },
  save_evaluation_mode_type_details: {
    evaluationModeTypeArray: []
  },
  disbSecurityDetailsResponse: [],
  chargeCreationDetailsResponse: [],
  disbursementReportTopicTabs: {},
  fetchTabData: [],
  imageArray: []
};
const disburseReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_COUNTRY_DATA':
      return {
        ...state,
        loader: action?.payload?.loader,
        countryApiData: action?.payload?.data
      };

    case 'FETCH_LEGAL_VERIFICATION':
      return {
        ...state,
        loader: action?.payload?.loader,
        legal_verification_details: action?.payload?.data
      };
    case 'FETCH_LEGAL_VERIFICATION_DOC':
      return {
        ...state,
        loader: action?.payload?.loader,
        legal_verification_details_doc: action?.payload?.data
      };
    case 'SAVE_LEGAL_VERIFICATION':
      return {
        ...state,
        loader: action?.payload?.loader,
        legal_verification_details: action?.payload?.data
      };
    case 'FETCH_LIST_API':
      return {
        ...state,
        fetch_list_api: action?.payload
      };

    case 'FETCH_FIELD_VISIT_API':
      return {
        ...state,
        loader: action?.payload?.loader,
        field_visit_state: action?.payload?.data
      };

    case 'FETCH_DISB_SECURITY_DETAILS':
      return {
        ...state,
        loader: action?.payload?.loader,
        disbSecurityDetailsResponse: action?.payload?.data
      };

    case 'FETCH_CHARGE_CREATION_DETAILS_WITH_DISBSECURITYID':
      return {
        ...state,
        loader: action?.payload?.loader,
        save_charge_creation_details: {
          ...state?.save_charge_creation_details,
          cersaiDate: action?.payload?.data?.cersaiDate,
          cersaiReferenceNo: action?.payload?.data?.cersaiReferenceNo,
          titleClearanceReportDate: action?.payload?.data?.titleClearanceReportDate,
          titleClearanceReportStatusId: action?.payload?.data?.titleClearanceReportStatus?.typeId,
          panelAdvocate: action?.payload?.data?.panelAdvocate,
          valuationDate: action?.payload?.data?.valuationDate,
          valuationAmount: action?.payload?.data?.valuationAmount,
          valuerName: action?.payload?.data?.valuerName,
          chargeCreatedAmount: action?.payload?.data?.chargeCreatedAmount,
          dateOfEquitableMortgage: action?.payload?.data?.dateOfEquitableMortgage,
          equitableMortgageReferenceNo: action?.payload?.data?.equitableMortgageReferenceNo,
          appointmentModeTypeId: action?.payload?.data?.appointmentModeType?.typeId,
          appointmentDate: action?.payload?.data?.dateOfEquitableMortgage,
          appointmentSchedulerNumber: action?.payload?.data?.equitableMortgageReferenceNo,
          disbSecurityChargeId: action?.payload?.data?.disbSecurityChargeId
        }
      };

    case 'FETCH_CHARGE_CREATION_DETAILS':
      return {
        ...state,
        loader: action?.payload?.loader,
        chargeCreationDetailsResponse: action?.payload?.data
      };

    case 'GET_ALL_USER_LIST':
      return { ...state, active_user: action?.payload?.data?.users?.userDetailsResponseModelList };

    case 'CHARGE_CREATION_DETAILS_DROPDOWN':
      return {
        ...state,
        [action?.payload.parentKey]: {
          ...state[action?.payload.parentKey],
          [action?.payload.childKey]: action?.payload?.value?.data?.masterTypes
        }
      };
    case 'EVALUATION_MODE_TYPE_DROPDOWN':
      return {
        ...state,
        [action?.payload.parentKey]: {
          ...state[action?.payload.parentKey],
          [action?.payload.childKey]: action?.payload?.value?.data?.masterTypes
        }
      };

    case 'HANDLE_ONCHANGE':
      return {
        ...state,
        [action?.payload.parentKey]: {
          ...state[action?.payload.parentKey],
          [action?.payload.childKey]: action?.payload.value
        }
      };
    case 'HANDLE_ONCHANGE_AOF':
      return {
        ...state,
        [action?.payload?.parentKey]: {
          ...state[action?.payload?.parentKey],
          [action?.payload.childKey]: state[action?.payload?.parentKey][
            action?.payload.childKey
          ].map((obj) => {
            if (obj.disbSecurityLegalHeadId === action?.payload?.id) {
              return { ...obj, [action?.payload?.key]: action?.payload?.value };
            }
            return obj;
          })
        }
      };
    case 'HANDLE_ONCHANGE_IMAGE_UPLOAD':
      return {
        ...state,
        [action?.payload.parentKey]: {
          ...state[action?.payload.parentKey],
          [action?.payload.childKey]: [
            ...state[action?.payload.parentKey][action?.payload.childKey],
            { fileName: action?.payload.fileName, files: action?.payload.value }
          ]
        }
      };
    case 'HANDLE_ONCHANGE_IMAGE_UPLOAD_VALUATION':
      return {
        ...state,
        [action?.payload.parentKey]: {
          ...state[action?.payload.parentKey],
          [action?.payload.childKey]: {
            ...state[action?.payload.childKey],
            [action?.payload.key]: [
              ...state[action?.payload.childKey][action?.payload.key],
              { fileName: action?.payload.fileName, files: action?.payload.value }
            ]
          }
        }
      };

    case 'FETCH_VALUATION':
      return {
        ...state,
        loader: action?.payload?.loader,
        valuation_data: action?.payload?.data
      };
    case 'DISBURSEMENT_TOPICS_TABS':
      return {
        ...state,
        disbursementReportTopicTabs: action?.payload?.data
      };
    case 'FETCH_TAB_DATA':
      return {
        ...state,
        fetchTabData: action?.payload?.data?.data,
        imageArray: action?.payload?.data?.data?.filter(
          (item) => item?.tabCardType === 'DOCUMENT_UPLOAD'
        )[0]?.values
      };
    case 'IMAGE_UPLOAD_DISBURSE':
      return {
        ...state,
        imageArray: action?.payload
      };
    case 'ERROR_MESSAGE':
      return { ...state, errorMsg: action?.payload?.data, loader: action?.payload?.loader };
    case 'POPUP_ONN_OFF':
      return { ...state, popup_onn_off: action?.payload };
    case 'LOADER_ONN_OFF':
      return { ...state, loader: action?.payload };
    default:
      return state;
  }
};

export default disburseReducer;
