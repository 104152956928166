import React from 'react';
import jsPDF from 'jspdf';

async function genratePdfErrorHandler(response, payload) {
  console.log(response, 'line no 4');
  console.log(payload, 'line no 5');

  try {
    if (response.status === 'error') {
      const data = JSON.stringify(payload);
      const responseData = JSON.stringify(response);
      generatePdfWithError(data, responseData);
    } else {
      console.error('Unexpected error:', response.status);
    }
  } catch (error) {
    console.error('Error handling server error:', error);
  }

  async function generatePdfWithError(data, responseData) {
    const apiName = JSON.parse(responseData);

    try {
      // Create a basic HTML string for the PDF content
      const htmlContent = `
          <style>
          /* Add your custom CSS styles here */
          body {
            font-family: 'Arial', sans-serif;
            color: #333;
          }
          h1 {
            color: #0066cc;
          }
          p {
              white-space: pre-wrap;
              word-wrap: break-word;
              max-width: 500px;
              display:block;
              border:2px solid #0066cc;

          }
          /* Add more styles as needed */
        </style>
          <h1>Payload:</h1>
          <p  style="display: block; white-space: wrap; word-wrap: break-word; width: 500px; border: 1px solid #333;"  >${data} </p>

         <hr/>
          <h1>Api Response:</h1>
          <p style="display: block; white-space: wrap; word-wrap: break-word; width: 500px; border: 1px solid #333;">${responseData}</p>
        `;

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF();
      const startX = 10;
      const startY = 10;
      const contentWidth = 180; // Adjust this value based on your layout requirements

      // Add HTML content to the PDF
      pdf.fromHTML(htmlContent, startX, startY, {
        width: contentWidth
      });

      // Save the PDF
      pdf.save(`${apiName.endpoint}.pdf`);

      console.log('PDF generated successfully');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }
}

export default genratePdfErrorHandler;
