import { combineReducers } from 'redux';
import disburseReducer from './disburseReducer';
import loginReducer from './loginReducer';
import camReducer from './camReducer';
import cardsReducer from './cardsReducer';
import journeyReducer from './journeyReducer';
import globalReducer from './globalReducer';

const rootReducer = combineReducers({
  disburseReducer,
  loginReducer,
  camReducer,
  cardsReducer,
  journeyReducer,
  globalReducer
  // Add more reducers as needed
});

export default rootReducer;
