export const DEPLOYMENT_ENV_TYPE_PROD = 'PROD';
export const DEPLOYMENT_ENV_TYPE_UAT = 'UAT';
export const DEPLOYMENT_ENV_TYPE_DEV = 'DEV';

// DEVELOPMENT environment properties
export const ACTIVE_DEPLOYED_ENV = DEPLOYMENT_ENV_TYPE_DEV;

export const domainUrl = `${window.location.origin}/`;
const hostName = window.location.hostname;

const devURL = 'http://91.203.134.20:8080';
const uatURL = 'http://91.203.134.20:8082';

const devURLKlass = `${devURL}/kl-dev-klass-api`;
const devURLAdmin = `${devURL}/kl-dev-los-admin-api`;
const pbURL = 'https://pb.kreditgro.com';
const knsbSutexURL = 'http://91.203.132.66:8080';
const sutexURL = 'https://st.kreditgro.com';

// common api for sutex and sarvodaya for kalolytic
const baseURL = 'https://los-api.kalolytic.com';
// common api for sutex and sarvodaya

const kcbURL = 'https://kcb.kalolytic.com';
// const amit = 'http://192.168.1.16:8082';

export function dynamicKlassAdminUrl() {
  let klassUrl = uatURL;
  let adminUrl = uatURL;

  let env;
  let bankName;

  try {
    const data = JSON.parse(sessionStorage.getItem('devEnvDetails'));
    env = data.clientId;
    bankName = data.hostName;
  } catch (error) {
    console.log();
  }

  if (hostName === 'kreditgro-klassdev.kalolytic.com') {
    klassUrl = devURLKlass;
    adminUrl = devURLAdmin;
  }
  if (hostName === 'klass-uat.kalolytic.com') {
    klassUrl = `${uatURL}/kl-uat-klass-api`;
    adminUrl = `${uatURL}/kl-uat-los-admin-api`;
  }
  // for prime bank dev environment ---
  if (hostName === 'pb-dev.kalolytic.com' || (env === 'Dev' && bankName === 'Prime')) {
    klassUrl = `${devURL}/pb-dev-klass-api`;
    adminUrl = `${devURL}/pb-dev-los-admin-api`;
  }
  //  for prime bank uat environment ---
  if (hostName === 'pb-uat.kalolytic.com' || (env === 'UAT' && bankName === 'Prime')) {
    klassUrl = `${uatURL}/kcb-uat-klass-api`;
    adminUrl = `${uatURL}/kcb-uat-los-admin-api`;
  }
  // pb kreditgro ---
  if (hostName === 'pb.kreditgro.com' || (env === 'Prod' && bankName === 'Prime')) {
    klassUrl = `${pbURL}/pb-prod-klass-api`;
    adminUrl = `${pbURL}/pb-prod-los-admin-api`;
  }
  //  for prime bank environment ---
  if (hostName === 'pb.kalolytic.com') {
    klassUrl = `${pbURL}/pb-prod-klass-api`;
    adminUrl = `${pbURL}/pb-prod-los-admin-api`;
  }
  //  for kalupur dev environment ---
  if (hostName === 'kcb-dev.kalolytic.com' || (env === 'Dev' && bankName === 'Kalupur')) {
    klassUrl = `${devURL}/kcb-dev-klass-api`;
    adminUrl = `${devURL}/kcb-dev-los-admin-api`;
  }
  //  for kalupur  uat environment ---
  if (hostName === 'kcb-uat.kalolytic.com' || (env === 'UAT' && bankName === 'Kalupur')) {
    klassUrl = `${uatURL}/kl-uat-klass-api`;
    adminUrl = `${uatURL}/kl-uat-los-admin-api`;
  }
  //  for kalupur environment ---
  if (hostName === 'kcb.kalolytic.com' || (env === 'Prod' && bankName === 'Kalupur')) {
    klassUrl = `${kcbURL}/kcb-prod-klass-api`;
    adminUrl = `${kcbURL}/kcb-prod-los-admin-api`;
  }
  //  for kalupur onpremise uat environment ---
  if (hostName === 'myloan.kalupurbank.com') {
    klassUrl = `https://myloan.kalupurbank.com/kcb-prod-klass-api`;
    adminUrl = `https://myloan.kalupurbank.com/kcb-prod-los-admin-api`;
  }
  //  for kalupur onpremise prod environment
  if (hostName === 'myloanuat.kalupurbank.com') {
    klassUrl = `https://myloanuat.kalupurbank.com/kcb-prod-klass-api`;
    adminUrl = `https://myloanuat.kalupurbank.com/kcb-prod-los-admin-api`;
  }
  //  for kadi dev environment ---
  if (hostName === 'knsb-dev.kalolytic.com' || (env === 'Dev' && bankName === 'Kadi')) {
    klassUrl = devURLKlass;
    adminUrl = devURLAdmin;
  }
  //  for kadi bank uat environment
  if (hostName === 'knsb-uat.kalolytic.com' || (env === 'UAT' && bankName === 'Kadi')) {
    klassUrl = `${uatURL}/kl-uat-klass-api`;
    adminUrl = `${uatURL}/kl-uat-los-admin-api`;
  }
  //  for kadi bank environment
  if (hostName === 'knsb.kalolytic.com' || (env === 'Prod' && bankName === 'Kadi')) {
    klassUrl = `${knsbSutexURL}/kadi-prod-klass-api`;
    adminUrl = `${knsbSutexURL}/kadi-prod-los-admin-api`;
  }
  // for sutex dev environment ---
  if (hostName === 'sutex-dev.kalolytic.com' || (env === 'Dev' && bankName === 'Sutex')) {
    klassUrl = devURLKlass;
    adminUrl = devURLAdmin;
  }
  // for sutex uat environment ---
  if (hostName === 'sutex-uat.kalolytic.com' || (env === 'UAT' && bankName === 'Sutex')) {
    klassUrl = `${uatURL}/kl-uat-klass-api`;
    adminUrl = `${uatURL}/kl-uat-los-admin-api`;
  }
  // for sutex environment ---
  if (hostName === 'sutex.kalolytic.com') {
    klassUrl = `${baseURL}/sutex-prod-klass-api`;
    adminUrl = `${baseURL}/sutex-prod-los-admin-api`;
  }

  if (hostName === 'st.kreditgro.com' || (env === 'Prod' && bankName === 'Sutex')) {
    klassUrl = `${sutexURL}/st-prod-klass-api`;
    adminUrl = `${sutexURL}/st-prod-los-admin-api`;
  }

  if (hostName === 'sd.kalolytic.com' || (env === 'Prod' && bankName === 'Sarvodaya')) {
    klassUrl = `${baseURL}/sd-prod-klass-api`;
    adminUrl = `${baseURL}/sd-prod-los-admin-api`;
  }

  //  for lessemi environment ---
  if (hostName === '216.48.179.135') {
    klassUrl = `http://216.48.179.135/less_emi-klass-api`;
    adminUrl = `http://216.48.179.135/less_emi-admin-api`;
  }

  return {
    klassUrl: klassUrl,
    adminUrl: adminUrl
  };
}

export const globalAdminUrl = dynamicKlassAdminUrl().klassUrl;
export const globalAdminUrlAdmin = dynamicKlassAdminUrl().adminUrl;

// *****************************************************************************

// export const globalAdminUrlKudos = 'http://216.48.180.65:8010'; // login api for kudons
// export const PARTNER_TO_ONLINE_URL = 'http://online-uat.kalolytic.com/';

// PRODUCTION - RECOMMENDATION module - PYTHON API BASE URL
export const RECOMMENDATION_PYTHON_PROD_API_BASE_URL = 'http://216.48.180.65:6060';

// for UAT - RECOMMENDATION module - PYTHON API BASE URL
export const RECOMMENDATION_PYTHON_UAT_API_BASE_URL = 'http://216.48.180.65:6060';

// for DEVELOPMENT - RECOMMENDATION module - PYTHON API BASE URL
export const RECOMMENDATION_PYTHON_DEV_API_BASE_URL = 'http://216.48.180.65:6060';

// for PRODUCTION - RECOMMENDATION module - RECOMMENDATION RESULT API endpoint
export const RECOMMENDATION_RESULTS_PROD_API_ENDPOINT = 'recommendationResults';

// for UAT - RECOMMENDATION module - RECOMMENDATION RESULT API endpoint
export const RECOMMENDATION_RESULTS_UAT_API_ENDPOINT = 'recommendationResultsDev';

// for DEVELOPMENT - RECOMMENDATION module - RECOMMENDATION RESULT API endpoint
export const RECOMMENDATION_RESULTS_DEV_API_ENDPOINT = 'recommendationResultsDev';

// for kadi logo  Image
export const kadiLogo = 'http://91.203.134.20:8080/shri-kadi-logo.png';
