const initialState = {
  loginInfo: {}
};
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_INFO':
      return {
        ...state,
        loginInfo: action?.payload?.data
      };

    default:
      return state;
  }
};

export default loginReducer;
