import { Link as RouterLink } from 'react-router-dom';
// material
import { Link, Typography } from '@mui/material';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

export default function BrudCrumbs() {
  const breadcrumbs = useBreadcrumbs();
  return breadcrumbs.map(({ match, breadcrumb }, index) => (
    <span key={match.pathname}>
      {index < breadcrumbs.length - 1 ? (
        <Link underline="none" variant="subtitle2" component={RouterLink} to={match.pathname}>
          {index === 0 ? 'Dashboard' : breadcrumb}
        </Link>
      ) : (
        <Typography variant="body2" component="span">
          {breadcrumb}
        </Typography>
      )}

      {index < breadcrumbs.length - 1 ? <>&nbsp; / &nbsp;</> : ''}
    </span>
  ));
}
