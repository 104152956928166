const initialState = {
  imageArray: []
};
const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IMAGE_UPLOAD_GLOBAL_USE':
      return {
        ...state,
        imageArray: action?.payload
      };
    default:
      return state;
  }
};

export default globalReducer;
