import { lazy, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useRoutes, useHistory, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { getClientId } from './utils/getConfigdetails';
import { dynamicKlassAdminUrl } from './pages/services/globalApi';
import { encryptValue } from './hooks/encryptFile';
import AdminAccess from './pages/Admin/SuperAdmin/AdminAccess/AdminAccess';
import Stmp from './pages/Admin/SMTP/Stmp';
import AddEditAccess from './pages/Admin/SuperAdmin/AdminAccess/AddEditAccess';
import AddAdminUser from './pages/Admin/SuperAdmin/AdminUser/AddAdminUser';
import AddEditAdminUser from './pages/Admin/SuperAdmin/AdminUser/AddEditAdminUser';
// renewal
// import Renewal from './pages/Renewal/Renewal';
// import ActivityReportRenewal from './pages/Renewal/ActivityReportRenewal';
// import DecisionRenewal from './pages/Renewal/DecisionRenewal';
// import ViewRenewal from './pages/Renewal/ViewRenewal';
// import UpdateRenewal from './pages/Renewal/UpdateRenewal';
// import AddEditRenewal from './pages/Renewal/AddEditRenewal';
// import SubmitRenewal from './pages/Renewal/SubmitRenewal';
// import PrintDownloadApplicationRenewal from './pages/Renewal/PrintDownloadApplicationRenewal';
// import ShareBankRenewal from './pages/Renewal/ShareBankRenewal';

// import Cards from './pages/Cards/Cards';
// import AddNewCard from './pages/Cards/AddNewCard';

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          return; // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

// layouts

const AuditReport = lazy(() => lazyRetry(() => import('./pages/Audit/AuditReport')));
const RetailLoanFormat = lazy(() => lazyRetry(() => import('./pages/Audit/RetailLoanFormat')));
const ViewApproveAudit = lazy(() => lazyRetry(() => import('./pages/Audit/ViewApproveAudit')));
const ViewRating = lazy(() => lazyRetry(() => import('./pages/Rating/ViewRating')));
const RatingReport = lazy(() => lazyRetry(() => import('./pages/Rating/RatingReport')));
const Assign = lazy(() => lazyRetry(() => import('./pages/Assign/Assign')));
const DashboardLayout = lazy(() => lazyRetry(() => import('./layouts/dashboard/DashboardLayout')));
const DisbursementLayout = lazy(() =>
  lazyRetry(() => import('./layouts/dashboard/DisbursementLayout'))
);
const ProcessDisbursement = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/ProcessDisbursement'))
);
const Sanctioned = lazy(() => lazyRetry(() => import('./pages/Sanctioned/Sanctioned')));
const SanctionedLetter = lazy(() => lazyRetry(() => import('./pages/Sanctioned/SanctionedLetter')));
const SanctionedMemo = lazy(() => lazyRetry(() => import('./pages/Sanctioned/SanctionedMemo')));
const UpdateSanctioned = lazy(() => lazyRetry(() => import('./pages/Sanctioned/UpdateSanctioned')));
const ViewSanctioned = lazy(() => lazyRetry(() => import('./pages/Sanctioned/ViewSanctioned')));
const SecurityReport = lazy(() => lazyRetry(() => import('./pages/Security/SecurityReport')));
const PreSanctionForm = lazy(() =>
  lazyRetry(() => import('./pages/SecurityForms/PreSanctionForm'))
);
// const PostSanctionedForm = lazy(() =>
//   lazyRetry(() => import('./pages/SecurityForms/PostSanctionedForm'))
// );

// const ValuationForm = lazy(() => lazyRetry(() => import('./pages/SecurityForms/ValuationForm')));
const CallCenterList = lazy(() => lazyRetry(() => import('./pages/CallCenter/CallCenterList')));
const UpdateCallCenter = lazy(() => lazyRetry(() => import('./pages/CallCenter/UpdateCallCenter')));
const DashboardApp = lazy(() => lazyRetry(() => import('./pages/DashboardApp')));
// const MISReports = lazy(() => lazyRetry(() => import('./pages/MIS/MISReports')));
const AddEditLeads = lazy(() => lazyRetry(() => import('./pages/Leads/AddeditLeads')));
const Leads = lazy(() => lazyRetry(() => import('./pages/Leads/Leads')));
const SubmitLeads = lazy(() => lazyRetry(() => import('./pages/Leads/SubmitLeads')));
const UpdateLeads = lazy(() => lazyRetry(() => import('./pages/Leads/UpdateLeads')));
const Login = lazy(() => lazyRetry(() => import('./pages/Login')));
const UpdateLogIn = lazy(() => lazyRetry(() => import('./pages/LogIn/UpdateLogIn')));
const AddEditMaster = lazy(() => lazyRetry(() => import('./pages/Master/AddEditMaster')));
const MasterList = lazy(() => lazyRetry(() => import('./pages/Master/MasterList')));
const AddEditChildMaster = lazy(() =>
  lazyRetry(() => import('./pages/MasterChild/AddEditChildMaster'))
);
const NotFound = lazy(() => lazyRetry(() => import('./pages/Page404')));
const AddEditProspect = lazy(() => lazyRetry(() => import('./pages/Prospect/AddEditProspect')));
const UploadLeadProspect = lazy(() =>
  lazyRetry(() => import('./pages/Prospect/UploadLeadProspect'))
);
const ViewProspect = lazy(() => lazyRetry(() => import('./pages/Prospect/ViewProspect')));
const ReapplyRejected = lazy(() => lazyRetry(() => import('./pages/Rejected/ReapplyRejected')));
const RejectedList = lazy(() => lazyRetry(() => import('./pages/Rejected/RejectedList')));
const DecisionLogIn = lazy(() => lazyRetry(() => import('./pages/LogIn/DecisionLogIn')));
const LogIn = lazy(() => lazyRetry(() => import('./pages/LogIn/LogIn')));
const LoginFieldVisit = lazy(() => lazyRetry(() => import('./pages/LogIn/LoginFieldVisit')));
const LegalApplication = lazy(() => lazyRetry(() => import('./pages/Global/LegalApplication')));
const ValuationApplication = lazy(() =>
  lazyRetry(() => import('./pages/Global/ValuationApplication'))
);

const ViewLogin = lazy(() => lazyRetry(() => import('./pages/LogIn/ViewLogin')));
const ViewCallCenter = lazy(() => lazyRetry(() => import('./pages/CallCenter/ViewCallCenter')));
const ViewApplicationSummary = lazy(() =>
  lazyRetry(() => import('./pages/Leads/ViewApplicationSummary'))
);
const SubmitLogin = lazy(() => lazyRetry(() => import('./pages/LogIn/SubmitLogin')));
const PrivacyPolicyText = lazy(() => lazyRetry(() => import('./pages/PrivacyPolicyText')));
const ProspectListView = lazy(() => lazyRetry(() => import('./pages/Prospect/ProspectListView')));
const ViewRejected = lazy(() => lazyRetry(() => import('./pages/Rejected/ViewRejected')));
const SignUp = lazy(() => lazyRetry(() => import('./pages/SignUp')));
const SignUpCompany = lazy(() => lazyRetry(() => import('./pages/SignUpCompany')));
const SignUpIndividual = lazy(() => lazyRetry(() => import('./pages/SignUpIndividual')));
const SignUpSec = lazy(() => lazyRetry(() => import('./pages/SignUpSec')));
const TermConditionsText = lazy(() => lazyRetry(() => import('./pages/TermConditionsText')));
const ActivityLeads = lazy(() => lazyRetry(() => import('./pages/Leads/ActivityLeads')));
const AppointmentList = lazy(() => lazyRetry(() => import('./pages/Leads/AppointmentList')));
const CommentsLeads = lazy(() => lazyRetry(() => import('./pages/Leads/CommentsLeads')));
const PrintDownloadApplicationLeads = lazy(() =>
  lazyRetry(() => import('./pages/Leads/PrintDownloadApplicationLeads'))
);
const ProcessLeads = lazy(() => lazyRetry(() => import('./pages/Leads/ProcessLeads')));
const ProcessLogin = lazy(() => lazyRetry(() => import('./pages/LogIn/ProcessLogin')));
const DashboardApplication = lazy(() =>
  lazyRetry(() => import('./layouts/dashboard/DashboardApplication'))
);

const LeadApplication = lazy(() => lazyRetry(() => import('./pages/Global/LeadApplication')));
const LeadConfigure = lazy(() => lazyRetry(() => import('./pages/Global/LeadConfigure')));
const RecommendationConfigure = lazy(() =>
  lazyRetry(() => import('./pages/Global/RecommendationConfigure'))
);
const RecommendationApplication = lazy(() =>
  lazyRetry(() => import('./pages/Global/RecommendationApplication'))
);

const ProcessUDisburse = lazy(() => lazyRetry(() => import('./pages/UDisburse/ProcessUDisburse')));
const UDisburse = lazy(() => lazyRetry(() => import('./pages/UDisburse/UDisburse')));
const UpdateUDisburse = lazy(() => lazyRetry(() => import('./pages/UDisburse/UpdateUDisburse')));
const ViewUDisburse = lazy(() => lazyRetry(() => import('./pages/UDisburse/ViewUDisburse')));

const NewRecommendation = lazy(() =>
  lazyRetry(() => import('./pages/Recommendation/NewRecommendation'))
);
const Recommendation = lazy(() => lazyRetry(() => import('./pages/Recommendation/Recommendation')));
const ViewRecommendation = lazy(() =>
  lazyRetry(() => import('./pages/Recommendation/ViewRecommendation'))
);
const PrintRecommendationLetter = lazy(() =>
  lazyRetry(() => import('./pages/Recommendation/PrintRecommendationLetter'))
);

const Disburse = lazy(() => lazyRetry(() => import('./pages/Disburse/Disburse')));
const ProcessDisburse = lazy(() => lazyRetry(() => import('./pages/Disburse/ProcessDisburse')));
const UpdateDisburse = lazy(() => lazyRetry(() => import('./pages/Disburse/UpdateDisburse')));
const ViewDisburse = lazy(() => lazyRetry(() => import('./pages/Disburse/ViewDisburse')));
const USanctionListView = lazy(() =>
  lazyRetry(() => import('./pages/USanction/USanctionListView'))
);

// cam
const CamLayout = lazy(() => lazyRetry(() => import('./layouts/dashboard/CamLayout')));
const CreditTaskBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/CreditTask/CreditTaskBusiness'))
);
const QueryCreditTaskBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/CreditTask/QueryCreditTaskBusiness'))
);
const ViewCreditTaskBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/CreditTask/ViewCreditTaskBusiness'))
);
const ReportManager = lazy(() =>
  lazyRetry(() => import('./pages/CAM/ReportManager/ReportManager'))
);
const CreditRecommendation = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/CreditRecommendation/CreditRecommendation'))
);
const UpdateReportManager = lazy(() =>
  lazyRetry(() => import('./pages/CAM/ReportManager/UpdateReportManager'))
);
const CreditDecision = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/CreditDecision/CreditDecision'))
);
const DigitalKYC = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/DigitalKYC/DigitalKYC'))
);
const FieldReport = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/FieldReport/FieldReport'))
);
const RequestReport = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/RequestReport/RequestReport'))
);
// salaried start here
const RecommendationSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/Recommendation/Recommendation'))
);
const CreditTaskSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/CreditTask/CreditTask'))
);
const QueryCreditTaskSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/CreditTask/QueryCreditTask'))
);
const ApplicationSummarySalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/ApplicationSummary/ApplicationSummary'))
);
const PrintDownloadApplicationSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/ApplicationSummary/PrintDownloadApplication'))
);
const CreditReportSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/CreditReport/CreditReport'))
);
const ProfileReportSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/Profile/ProfileReport'))
);
const FinancialCrimeRiskSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/FinancialCrimeRisk/FinancialCrimeRisk'))
);
const IncomeAnalyticsSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/Income/IncomeAnalytics'))
);
const BankStatementIncomeSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/Income/BankStatementIncome'))
);
const AddBankStatementSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/Income/AddBankStatement'))
);
const AddTaxFileSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/Income/AddTaxFile'))
);
const AddSalarySlipSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/Income/AddSalarySlip'))
);
const CreditAppraisalSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/CreditAppraisal/CreditAppraisal'))
);
const VerificationReportSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/VerificationReport/VerificationReport'))
);
const ValuationReportSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/ValuationReport/ValuationReport'))
);
const FieldInvestigationSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/FieldInvestigation/FieldInvestigation'))
);
const PdVisitsSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/PdVisits/PdVisits'))
);
const LegalReportSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/LegalReport/LegalReport'))
);
const QuerySalaried = lazy(() => lazyRetry(() => import('./pages/CAM/Salaried/Query/Query')));
const CommentsSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/Comments/Comments'))
);
const AuditTrailSalaried = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Salaried/AuditTrail/AuditTrail'))
);

// Disbursement starts here
const DisbursementDecision = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Decision/Decision'))
);
const DocumentDisburse = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/DocumentDisburse'))
);
const Mortgage = lazy(() => lazyRetry(() => import('./pages/Sanctioned/Mortgage/Mortgage')));
const FieldVisit = lazy(() => lazyRetry(() => import('./pages/Sanctioned/FieldVisit/FieldVisit')));
const FieldVisitAccount = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/FieldVisit/FieldVisitAccount'))
);
const Disbursement = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Disbursement/Disbursement'))
);
const DisbursementRePayment = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/PaymentSetup/PaymentSetup'))
);
const Expenses = lazy(() => lazyRetry(() => import('./pages/Sanctioned/Expenses/Expenses')));
const Borrower = lazy(() => lazyRetry(() => import('./pages/Sanctioned/Borrower/Borrower')));
const DisbursementRecommendation = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Recommendation/ViewRecommendation'))
);
const DisbursementCreditTask = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Recommendation/ViewRecommendation'))
);
const DisbursementApplication = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Recommendation/ViewRecommendation'))
);
const DisbursementSecurity = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Recommendation/ViewRecommendation'))
);
const DisbursementCreditAppraisal = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Recommendation/ViewRecommendation'))
);
const Task = lazy(() => lazyRetry(() => import('./pages/Sanctioned/Task/Task')));
const Machinery = lazy(() => lazyRetry(() => import('./pages/Sanctioned/Machinery/Machinery')));

// Disbursement Ends Here

// business start here
const RecommendationBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/Recommendation/Recommendation'))
);

// business start here
const RecommendationBusinessKcb = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/Recommendation/RecommendationBusinessKcb'))
);

// business start here
const RecommendationBusinessOther = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/Recommendation/RecommendationBusinessOther'))
);

const ApplicationSummaryBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/ApplicationSummary/ApplicationSummary'))
);
const PrintDownloadApplicationBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/ApplicationSummary/PrintDownloadApplication'))
);
const ProfileReportBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/Profile/ProfileReport'))
);
const FinancialCrimeRiskBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/FinancialCrimeRisk/FinancialCrimeRisk'))
);
const IncomeAnalyticsBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/Income/IncomeAnalytics'))
);
const BankStatementIncomeBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/Income/BankStatementIncome'))
);
const AddBankStatementBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/Income/AddBankStatement'))
);
const AddTaxFileBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/Income/AddTaxFile'))
);
const AddSalarySlipBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/Income/AddSalarySlip'))
);
const CreditAppraisalKCB = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/CreditAppraisalKCB/CreditAppraisal'))
);
const CreditAppraisalOther = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/CreditAppraisalOther/CreditAppraisal'))
);
const BureauReportCreditAppraisalKCB = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/CreditAppraisalKCB/BureauReport/BureauReport'))
);
const BureauReportCreditAppraisalOther = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/CreditAppraisalOther/BureauReport/BureauReport'))
);
const BankStatementFinancialKCB = lazy(() =>
  lazyRetry(() =>
    import('./pages/CAM/Business/CreditAppraisalKCB/BankStatementFinancial/BankStatementFinancial')
  )
);
const BankStatementFinancialOther = lazy(() =>
  lazyRetry(() =>
    import(
      './pages/CAM/Business/CreditAppraisalOther/BankStatementFinancial/BankStatementFinancial'
    )
  )
);
// const VerificationReportBusiness = lazy(() => import('./pages/CAM/Business/VerificationReport/VerificationReport'));
const ValuationReportBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/ValuationReport/ValuationReport'))
);
const FieldInvestigationBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/FieldInvestigation/FieldInvestigation'))
);
const PdVisitsBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/PdVisits/PdVisits'))
);
const LegalReportBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/LegalReport/LegalReport'))
);
const QueryBusiness = lazy(() => lazyRetry(() => import('./pages/CAM/Business/Query/Query')));
const CommentsBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/Comments/Comments'))
);
const AuditTrailBusiness = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/AuditTrail/AuditTrail'))
);
const FieldVisitsReport = lazy(() =>
  lazyRetry(() => import('./pages/CAM/Business/FieldVisits/FieldVisitsReport'))
);
const DisbursementMemo = lazy(() => lazyRetry(() => import('./pages/Disburse/DisbursementMemo')));
const UpdateUSanctioned = lazy(() =>
  lazyRetry(() => import('./pages/USanction/UpdateUSanctioned'))
);

const DPNotePropietor = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/DPNotePropietor'))
);
const LetterContinuingSecurity = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/LetterContinuingSecurity'))
);
const LetterSoleProprietorship = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/LetterSoleProprietorship'))
);
const GeneralFormGuarantee = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/GeneralFormGuarantee'))
);
const LetterInstallment = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/LetterInstallment'))
);
const DeedHypothecation = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/DeedHypothecation'))
);
const DPNote = lazy(() => lazyRetry(() => import('./pages/Sanctioned/Document/Html/DPNote')));
const DPNoteJointBorrower = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/DPNoteJointBorrower'))
);
const LetterPartnership = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/LetterPartnership'))
);
const LetterDepositingFDR = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/LetterDepositingFDR'))
);
const TakeDeliveryLetter = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/TakeDeliveryLetter'))
);
const UndertakingMaintainVFY = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/UndertakingMaintainVFY'))
);
const LetterBpUndertakingBills = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/LetterBpUndertakingBills'))
);
const LetterHypothecationVehicle = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/LetterHypothecationVehicle'))
);
const LetterPledgeShareSecurities = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/LetterPledgeShareSecurities'))
);
const NominalMembership = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/NominalMembership'))
);
const AgreementCoveringCreditFacilities = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/AgreementCoveringCreditFacilities'))
);
const LetterAuthorityDirectlyDealers = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/LetterAuthorityDirectlyDealers'))
);
const HypothicationMachinery = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/HypothicationMachinery'))
);
const InstrumentHypothecationGoods = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/InstrumentHypothecationGoods'))
);
const HypothecationBookDebts = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Document/Html/HypothecationBookDebts'))
);
const PersonalBioData = lazy(() =>
  lazyRetry(() => import('./pages/Sanctioned/Borrower/PersonalBioData'))
);
const ViewTask = lazy(() => lazyRetry(() => import('./pages/Sanctioned/Task/ViewTask')));
const PrintRecommendationRejection = lazy(() =>
  lazyRetry(() => import('./pages/Recommendation/PrintRecommendationRejection'))
);

// Admin
const DashboardLayoutAdmin = lazy(() =>
  lazyRetry(() => import('./layouts/dashboardAdmin/DashboardLayoutAdmin'))
);
const Cards = lazy(() => lazyRetry(() => import('./pages/Cards/Cards')));
const AddNewCard = lazy(() => lazyRetry(() => import('./pages/Cards/AddNewCard')));
const ManageCards = lazy(() => lazyRetry(() => import('./pages/Cards/ManageCards')));
const Language = lazy(() => lazyRetry(() => import('./pages/Cards/Language')));
const ViewDataCapture = lazy(() => lazyRetry(() => import('./pages/Cards/ViewDataCapture')));
const APIMaster = lazy(() => lazyRetry(() => import('./pages/Admin/APIMaster/APIMaster')));
const AddAPIMaster = lazy(() => lazyRetry(() => import('./pages/Admin/APIMaster/AddAPIMaster')));
const ViewAPIMaster = lazy(() => lazyRetry(() => import('./pages/Admin/APIMaster/ViewAPIMaster')));
const Configure = lazy(() => lazyRetry(() => import('./pages/Admin/DOCUCheck/Configure')));
const DOCUCheck = lazy(() => lazyRetry(() => import('./pages/Admin/DOCUCheck/DOCUCheck')));
const AddJourney = lazy(() => lazyRetry(() => import('./pages/Admin/Journey/AddJourney')));
const Journey = lazy(() => lazyRetry(() => import('./pages/Admin/Journey/Journey')));
const JourneyConfigure = lazy(() =>
  lazyRetry(() => import('./pages/Admin/Journey/JourneyConfigure'))
);
const JourneySetting = lazy(() => lazyRetry(() => import('./pages/Admin/Journey/JourneySetting')));
const JourneyConfigureLanding = lazy(() =>
  lazyRetry(() => import('./pages/Admin/Journey/JourneyConfigureLanding'))
);
const JourneyPublish = lazy(() => lazyRetry(() => import('./pages/Admin/Journey/JourneyPublish')));
const JourneyTemplate = lazy(() =>
  lazyRetry(() => import('./pages/Admin/Journey/JourneyTemplate'))
);
const ViewJourney = lazy(() => lazyRetry(() => import('./pages/Admin/Journey/ViewJourney')));
const ViewJourneyTemplate = lazy(() =>
  lazyRetry(() => import('./pages/Admin/Journey/ViewJourneyTemplate'))
);
const AddEditManageAccess = lazy(() =>
  lazyRetry(() => import('./pages/Admin/Manage/AddEditManageAccess'))
);
const ManageAccessList = lazy(() => lazyRetry(() => import('./pages/Admin/Manage/ManageAccess')));
const AddEditChildMasterAdmin = lazy(() =>
  lazyRetry(() => import('./pages/Admin/MasterChild/AddEditChildMasterAdmin'))
);
const MasterChildList = lazy(() =>
  lazyRetry(() => import('./pages/Admin/MasterChild/MasterChild'))
);
const AddEditParentMaster = lazy(() =>
  lazyRetry(() => import('./pages/Admin/MasterParents/AddeditParentMaster'))
);
const MasterParentsList = lazy(() =>
  lazyRetry(() => import('./pages/Admin/MasterParents/MasterParentList'))
);
const AddEditReports = lazy(() => lazyRetry(() => import('./pages/Admin/Reports/AddEditReports')));
const AddEditReportsKcb = lazy(() =>
  lazyRetry(() => import('./pages/Admin/Reports-kcb/AddEditReports'))
);
const EditCards = lazy(() => lazyRetry(() => import('./pages/Admin/Reports/EditCards')));
const EditCardsKcb = lazy(() => lazyRetry(() => import('./pages/Admin/Reports-kcb/EditCards')));
const Reports = lazy(() => lazyRetry(() => import('./pages/Admin/Reports/Reports')));
const ReportsKcb = lazy(() => lazyRetry(() => import('./pages/Admin/Reports-kcb/Reports')));
const ReportsCards = lazy(() => lazyRetry(() => import('./pages/Admin/Reports/ReportsCards')));
const ReportsCardsKcb = lazy(() =>
  lazyRetry(() => import('./pages/Admin/Reports-kcb/ReportsCards'))
);
const AddEditUser = lazy(() => lazyRetry(() => import('./pages/Admin/Users/AddEditUser')));
const UsersAdmin = lazy(() => lazyRetry(() => import('./pages/Admin/Users/UsersAdmin')));
const DashboardAppAdmin = lazy(() => lazyRetry(() => import('./pages/DashboardAppAdmin')));
const ApiErrorMessage = lazy(() => lazyRetry(() => import('./pages/Global/ApiErrorMessage')));
const Security = lazy(() => import('./pages/Admin/Security/SecurityList'));
const MapDocument = lazy(() => import('./pages/Admin/Security/MapDocument'));
const ManageDocument = lazy(() => import('./pages/Admin/Security/ManageDocument'));
const AddDocument = lazy(() => import('./pages/Admin/Security/AddDocument'));
// ----------------------------------------------------------------------

export default function Router() {
  // cam router parts
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientId = getClientId();
  const [showExpiryPopup, setShowExpiryPopup] = useState(false);
  const hostName = window.location.hostname;
  console.log('hostName', hostName);
  const [urlParams, setUrlParams] = useState('');
  const [allToken, setAllToken] = useState({});
  const [sentUrl, setSentUrl] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  // const history = useHistory();
  const [hideSidebarUpdate, setSidebarUpdate] = useState(null);
  const [openApiError, setOpenApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const userDetails = sessionStorage.getItem('userDetails');
  console.log('userDetails354353', JSON.parse(userDetails));
  const parsedUserDetails = JSON.parse(userDetails);
  const [userTokenExpire, setUserTokenExpire] = useState('false');

  const isLogin = useSelector((state) => state?.loginReducer?.loginInfo);

  const base64UrlDecode = (base64Url) => {
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return decodeURIComponent(atob(base64));
  };
  const [idleTime, setIdleTime] = useState(0);

  const redirectToLoginPage = () => {
    dispatch({
      type: 'LOGIN_INFO',
      payload: { data: {} }
    });
    const loginUrl = '/';
    const anchor = document.createElement('a');
    anchor.href = loginUrl;
    anchor.click();
  };

  const resetIdleTime = useCallback(() => {
    setIdleTime(0);
  }, []);

  const [showInactivityPopup, setShowInactivityPopup] = useState({
    show: false,
    message: '',
    timeToTokenExpiry: null
  });
  const [open, setOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  useEffect(() => {
    const handleActivity = () => {
      resetIdleTime();
    };

    // window.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.body.addEventListener('click', handleActivity);
    // document.addEventListener('scroll', handleActivity);

    const idleInterval = setInterval(() => {
      setIdleTime((prevIdleTime) => prevIdleTime + 1);
    }, 60000); // Increment idle time every minute

    return () => {
      // window.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.body.removeEventListener('click', handleActivity);
      // document.removeEventListener('scroll', handleActivity);
      clearInterval(idleInterval);
    };
  }, [resetIdleTime]);

  useEffect(() => {
    if (isLogin && isLogin.jwt) {
      const JWTtoken = isLogin.jwt;
      const [header, payload, signature] = JWTtoken.split('.');
      const decodedPayloadObject = JSON.parse(base64UrlDecode(payload));
      const expireTimeToken = decodedPayloadObject ? decodedPayloadObject.exp : undefined;
      const convertTime = new Date(expireTimeToken * 1000);
      const currentTime = new Date();

      const timeToExpire = convertTime - currentTime;
      console.log(
        'currentTime:',
        currentTime,
        'convertTime:',
        convertTime,
        'timeToExpire:',
        timeToExpire,
        'idleTime',
        idleTime,
        'isLogin',
        isLogin,
        isLogin.jwt
      );

      const checkTokenExpiry = () => {
        const currentTime = new Date();
        const timeToExpire = convertTime - currentTime;

        if (idleTime > 45) {
          setPopupMessage('You have been idle for a while. Do you want to stay logged in?');
          setShowInactivityPopup({
            show: true,
            message: 'You have been idle for a while. Do you want to stay logged in?',
            timeToTokenExpiry: timeToExpire
          });
        } else if (idleTime >= 45 && timeToExpire < 5 * 60 * 1000) {
          setPopupMessage('Session is going to expire soon. Click Yes to stay logged in.');
          setShowInactivityPopup({
            show: true,
            message: 'Session is going to expire soon. Click Yes to stay logged in.',
            timeToTokenExpiry: timeToExpire
          });
        } else if (timeToExpire <= 5 * 60 * 1000 && idleTime > 10 && isLogin) {
          // Auto regenerate token if idle time is less than 5 minutes and session is about to expire
          regenerateToken();
        }
      };

      // Check token expiry every 1 second
      const timeoutId = setInterval(checkTokenExpiry, 1000);

      // Clear the interval when the component unmounts
      return () => clearInterval(timeoutId);
    }

    console.log('hideSidebarUpdate', hideSidebarUpdate);
  }, [isLogin, hideSidebarUpdate, idleTime]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleStayLoggedIn = () => {
    // setOpen(false);
    setShowInactivityPopup({ show: false });
    // User chose to stay logged in
    regenerateToken();
  };

  const handleLogout = () => {
    setShowInactivityPopup({ show: false });
    // setOpen(false);
    // User chose to log out
    localStorage.clear();
    sessionStorage.clear();
    redirectToLoginPage();
  };

  // useEffect(() => {
  //   if (showInactivityPopup.show) {
  //     setOpen(true);
  //   }
  // }, [showInactivityPopup]);

  const handlePopupOk = () => {
    regenerateToken(); // Function to regenerate the token
    setShowExpiryPopup(false);
  };

  const handlePopupCancel = () => {
    setShowExpiryPopup(false);
  };

  const handlePopupCancel1 = () => {
    setShowInactivityPopup({ show: false, data: null });
  };

  const regenerateToken = async () => {
    try {
      const userId = userDetails?.token?.userId;
      const timestamp = Date.now();
      const min = 1000;
      const max = 9999;
      const randomIv = Math.floor(Math.random() * (max - min + 1)) + min;
      const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      const response = await fetch(`${dynamicKlassAdminUrl().klassUrl}/regenerate-token`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${parsedUserDetails?.token?.jwt}`,
          'request-id': `${encryptValue(
            `${timestamp}_${userId}_${randomNumber}_`,
            randomIv
          )}${randomIv}`
        }
      });

      const data = await response.json();
      console.log('responsegeneratedtoken', data, data?.jwt);
      const newToken = data.jwt;

      // Update the application state with the new token
      // updateLoginState(newToken);

      // Optionally store the new token in localStorage or cookies

      // localStorage.setItem('userDetails', JSON.stringify(updatedUserDetails));
      const existingUserDetails = JSON.parse(sessionStorage.getItem('userDetails'));

      // Check if userDetails and token exist
      if (existingUserDetails && existingUserDetails.token) {
        // Update the jwt value
        existingUserDetails.token.jwt = newToken; // Replace 'newJWTValue' with the new JWT token

        // Save the updated userDetails back to sessionStorage
        sessionStorage.setItem('userDetails', JSON.stringify(existingUserDetails));
      }

      sessionStorage.setItem('userDetailsType', 'klass');

      // setOpenApiError(true);
      // setErrorMessage('Token regenerated successfully');

      setShowPopup(false);
    } catch (error) {
      console.error('Error refreshing token:', error);
      setUserTokenExpire('true');
      setOpenApiError(true);
      setErrorMessage('Failed to refresh session, please login again');
      // navigate('/login'); // Redirect to login screen
    }
  };

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    console.log('useLocation-params1', window.location.href.split('?u'));
    setSentUrl(window.location.href.split('?u')[1]);
    setUrlParams(params);
    const userToken = params.get('u');
    const companyToken = params.get('c');
    const appToken = params.get('a');
    const leadToken = params.get('l');
    const userId = params.get('ui');
    const loanId = params.get('li');
    const loanTypeid = params.get('lti');
    const loanIdNewToken = params.get('lt');
    const subLoanIdToken = params.get('lts');
    const empTypeNewIdToken = params.get('et');
    const subEmpTypeIdToken = params.get('ets');
    const applicationCreationEntryPlatform = params.get('applicationCreationEntryPlatform');

    setAllToken({
      userToken1: userToken,
      companyToken1: companyToken,
      appToken1: appToken,
      leadToken1: leadToken
    });

    sessionStorage.setItem('e13ee06b-72e6-4581-86a3-c0a465e63d74', userToken);
    sessionStorage.setItem('e13ee06b-72e6-4581-86a3-c0a465e63d75', companyToken);
    sessionStorage.setItem('e13ee06b-72e6-4581-86a3-c0a465e63d76', appToken);
    sessionStorage.setItem('e13ee06b-72e6-4581-86a3-c0a465e63d77', leadToken);
    sessionStorage.setItem('e13ee06bu-72e6s-4581e-86a3r-c0a465e63d77', userId);
    sessionStorage.setItem('e13ee06bu-72e6s-4581e-86a3r-loanId', loanId);
    sessionStorage.setItem('e13ee06bu-72e6s-4581e-86a3r-loanTypeid', loanTypeid);
    sessionStorage.setItem('e13ee06bu-72e6s-4581e-86a3r-loanIdNewToken', loanIdNewToken);
    sessionStorage.setItem('e13ee06bu-72e6s-4581e-86a3r-subLoanIdToken', subLoanIdToken);
    sessionStorage.setItem('e13ee06bu-72e6s-4581e-86a3r-empTypeNewIdToken', empTypeNewIdToken);
    sessionStorage.setItem('e13ee06bu-72e6s-4581e-86a3r-subEmpTypeIdToken', subEmpTypeIdToken);
    sessionStorage.setItem('applicationCreationEntryPlatform', applicationCreationEntryPlatform);
  }, []);

  // useEffect(() => {
  //   if (userDetails?.statusCode === '403' || userDetails?.statusCode === null) {
  //     const tokenTimer = setTimeout(() => {
  //       if (!userDetails.jwt) {
  //         setOpenApiError(true);
  //         setErrorMessage('Session Timeout');
  //       }
  //     }, 0);
  //     return () => clearTimeout(tokenTimer);
  //   }
  // }, [userDetails?.jwt]);

  const loginUserType = sessionStorage.getItem('userDetailsType');

  const klassRoutes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: '',
          element: <DashboardApp />,
          children: [{ path: '', element: <DashboardApp /> }]
        }
      ]
    },
    // {
    //   path: '/mis-reports',
    //   element: <DashboardLayout />,
    //   children: [
    //     {
    //       path: '',
    //       element: <MISReports />,
    //       children: [{ path: '', element: <MISReports /> }]
    //     }
    //   ]
    // },
    {
      path: '/prospect',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <ProspectListView /> },
        { path: 'add', element: <AddEditProspect /> },
        { path: 'edit/:rowId', element: <AddEditProspect /> },
        { path: 'view', element: <ViewProspect /> },
        { path: 'upload', element: <UploadLeadProspect /> }
      ]
    },
    {
      path: '/call-center',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <CallCenterList /> },
        { path: 'update', element: <UpdateCallCenter /> },
        { path: 'add', element: <AddEditChildMaster /> },
        { path: 'view', element: <ViewCallCenter /> },
        { path: 'edit/:rowId', element: <AddEditChildMaster /> }
      ]
    },
    {
      path: '/leads',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Leads /> },
        { path: 'add', element: <AddEditLeads /> },
        { path: 'submit', element: <SubmitLeads /> },
        { path: 'update', element: <UpdateLeads /> },
        { path: 'application-summary', element: <ViewApplicationSummary /> },
        { path: 'edit/:rowId', element: <AddEditLeads /> },
        { path: 'appointment', element: <AppointmentList /> },
        {
          path: 'activity',
          children: [
            { path: '', element: <ActivityLeads /> },
            { path: 'update', element: <UpdateLeads /> },
            { path: 'comments', element: <CommentsLeads /> }
          ]
        },
        {
          path: 'process',
          children: [
            { path: '', element: <ProcessLeads /> },
            { path: 'print-download', element: <PrintDownloadApplicationLeads /> }
          ]
        }
      ]
    },
    {
      path: '/recommendation',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Recommendation /> },
        { path: 'new-recommendation', element: <NewRecommendation /> },
        { path: 'view-recommendation', element: <ViewRecommendation /> },
        { path: 'letter-recommendation', element: <PrintRecommendationLetter /> },
        { path: 'letter-rejection', element: <PrintRecommendationRejection /> }
      ]
    },
    {
      path: '/rejected',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <RejectedList /> },
        { path: 'reapply', element: <ReapplyRejected /> },
        { path: 'view', element: <ViewRejected /> }
      ]
    },
    {
      path: '/login',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <LogIn /> },
        { path: 'decision', element: <DecisionLogIn /> },
        { path: 'view', element: <ViewLogin /> },
        { path: 'update', element: <UpdateLogIn /> },
        { path: 'submit', element: <SubmitLogin /> },
        {
          path: 'process',
          children: [{ path: '', element: <ProcessLogin /> }]
        }
      ]
    },
    {
      path: '/credit-task',
      element: <DashboardLayout />,
      children: [
        {
          path: '',
          element: <CreditTaskBusiness sentUrl={sentUrl} moduleName="dashboard/credit-task" />
        },
        { path: 'query', element: <QueryCreditTaskBusiness moduleName="dashboard/credit-task" /> },
        { path: 'view', element: <ViewCreditTaskBusiness moduleName="dashboard/credit-task" /> }
      ]
    },
    {
      path: '/u-sanction',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <USanctionListView /> },
        { path: 'update', element: <UpdateUSanctioned /> },
        { path: 'sanction-letter', element: <SanctionedLetter module="usanction" /> }
      ]
    },
    {
      path: '/sanctioned',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Sanctioned /> },
        { path: 'view', element: <ViewSanctioned /> },
        { path: 'update', element: <UpdateSanctioned /> },
        { path: 'sanction-memo', element: <SanctionedMemo /> },
        { path: 'sanction-letter', element: <SanctionedLetter module="sanctioned" /> }
      ]
    },
    {
      path: '/disburse',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Disburse /> },
        { path: 'decision', element: <DecisionLogIn /> },
        { path: 'view', element: <ViewDisburse /> },
        { path: 'process', element: <ProcessDisburse /> },
        { path: 'disbursement-memo', element: <DisbursementMemo /> },
        { path: 'update', element: <UpdateDisburse /> },
        { path: 'sanction-memo', element: <SanctionedMemo /> },
        { path: 'sanction-letter', element: <SanctionedLetter module="disburse" /> }
      ]
    },
    // {
    //   path: '/renewal',
    //   element: <DashboardLayout />,
    //   children: [
    //     { path: '', element: <Renewal /> },
    //     { path: 'decision', element: <DecisionRenewal /> },
    //     { path: 'view', element: <ViewRenewal /> },
    //     { path: 'update', element: <UpdateRenewal /> },
    //     { path: 'share', element: <ShareBankRenewal /> },
    //     { path: 'add', element: <AddEditRenewal /> },
    //     { path: 'submit', element: <SubmitRenewal /> },
    //     {
    //       path: 'activity-report',
    //       children: [
    //         { path: '', element: <ActivityReportRenewal /> },
    //         { path: 'print-download', element: <PrintDownloadApplicationRenewal /> }
    //       ]
    //     }
    //   ]
    // },

    {
      path: '/audit',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <AuditReport /> },
        {
          path: 'retail-laon-format',
          element: <RetailLoanFormat />
        },
        {
          path: 'view-approve',
          element: <ViewApproveAudit />
        }
      ]
    },
    // {
    //   path: '/security',
    //   element: <DashboardLayout />,
    //   children: [{ path: '', element: <SecurityReport /> }]
    // },
    {
      path: '/pre-sanction-form',
      element: <PreSanctionForm />
    },
    // {
    //   path: '/post-sanctioned-form',
    //   element: <PostSanctionedForm />
    // },
    // {
    //   path: '/valuation-form',
    //   element: <ValuationForm />
    // },
    {
      path: '/field-visits',
      element: <DashboardLayout />,
      children: [{ path: '', element: <FieldVisit /> }]
    },

    {
      path: '/rating',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <RatingReport /> },
        { path: 'view-rating', element: <ViewRating /> }
        // { path: 'view-update-rating', element: <ViewUpdateRating /> },
        // { path: 'view-customer-rating', element: <ViewCustomerRating /> },
        // {
        //   path: 'query',
        //   children: [
        //     { path: '', element: <Query /> },
        //     { path: 'view-query', element: <ViewQuery /> }
        //   ]
        // }
      ]
    },

    {
      path: '/u-disburse',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <UDisburse /> },
        { path: 'view', element: <ViewUDisburse /> },
        { path: 'process', element: <ProcessUDisburse /> },
        { path: 'update', element: <UpdateUDisburse /> },
        { path: 'sanction-memo', element: <SanctionedMemo /> },
        { path: 'sanction-letter', element: <SanctionedLetter module="u-disburse" /> }
      ]
    },
    {
      path: '/',
      element: <Login />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/signup',
      element: <SignUp />
    },
    {
      path: '/signup-form',
      element: <SignUpSec />
    },
    {
      path: '/signup-form/company',
      element: <SignUpCompany />
    },
    {
      path: '/signup-form/individual',
      element: <SignUpIndividual />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyText />
    },
    {
      path: '/term-conditions',
      element: <TermConditionsText />
    },
    {
      path: '/recommendation',
      element: <DashboardApplication />,
      children: [
        { path: 'recommendation-configure', element: <RecommendationConfigure /> },
        { path: 'recommendation-application', element: <RecommendationApplication /> }
      ]
    },
    {
      path: '/bureau-report',
      element: clientId.includes('kcb') ? (
        <BureauReportCreditAppraisalKCB sentUrl={sentUrl} />
      ) : (
        <BureauReportCreditAppraisalOther sentUrl={sentUrl} />
      )
    },
    {
      path: '/bank-statement-financial',
      element: clientId.includes('kcb') ? (
        <BankStatementFinancialKCB sentUrl={sentUrl} />
      ) : (
        <BankStatementFinancialOther sentUrl={sentUrl} />
      )
    },
    {
      path: '/leads',
      element: <DashboardApplication />,
      children: [
        { path: 'lead-configure', element: <LeadConfigure /> },
        { path: 'lead-application', element: <LeadApplication /> }
      ]
    },
    {
      path: '/login',
      element: <DashboardApplication />,
      children: [
        { path: 'pre-sanction-inspection', element: <LoginFieldVisit /> },
        {
          path: 'trigger-for-Valuation',
          element: <ValuationApplication />
        },
        { path: 'legal-application', element: <LegalApplication /> }
      ]
    },
    {
      path: '/admin-user',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <AddAdminUser sentUrl={sentUrl} /> },
        { path: 'add', element: <AddEditAdminUser sentUrl={sentUrl} /> },
        { path: 'edit/:rowId', element: <AddEditAdminUser sentUrl={sentUrl} /> }
      ]
    },

    {
      path: '/admin-access',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <AdminAccess sentUrl={sentUrl} /> },
        { path: 'add', element: <AddEditAccess sentUrl={sentUrl} /> },
        { path: 'edit/:rowId/:rowModuleName', element: <AddEditAccess sentUrl={sentUrl} /> }
      ]
    },

    {
      path: '/smtp',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <Stmp sentUrl={sentUrl} /> }
        // { path: 'add', element: <AddEditAccess sentUrl={sentUrl} /> },
        // { path: 'edit/:rowId/:rowModuleName', element: <AddEditAccess sentUrl={sentUrl} /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> },

    // cam routing starts
    // busienss start
    {
      path: '/login',
      element: <CamLayout sentUrl={sentUrl} />,
      children: [
        {
          path: 'recommendation-cam',
          element: clientId.includes('kcb') ? (
            <RecommendationBusinessKcb sentUrl={sentUrl} />
          ) : (
            <RecommendationBusinessOther sentUrl={sentUrl} />
          )
        },
        { path: 'credit-task', element: <CreditTaskBusiness sentUrl={sentUrl} /> },
        { path: 'credit-task/query', element: <QueryCreditTaskBusiness /> },
        { path: 'credit-task/view', element: <ViewCreditTaskBusiness /> },
        {
          path: 'application-summary',
          element: <ApplicationSummaryBusiness />
        },
        {
          path: 'application-summary/print-download',
          element: <PrintDownloadApplicationBusiness />
        },
        {
          path: 'profile',
          element: <ProfileReportBusiness />
        },
        {
          path: 'financial-crime-risk',
          element: <FinancialCrimeRiskBusiness />
        },
        {
          path: 'income-analytics',
          element: <IncomeAnalyticsBusiness />
        },
        {
          path: 'income-analytics/bank-statement',
          element: <BankStatementIncomeBusiness />
        },
        {
          path: 'income-analytics/add-bank-statement',
          element: <AddBankStatementBusiness />
        },
        {
          path: 'income-analytics/add-tax',
          element: <AddTaxFileBusiness />
        },
        {
          path: 'income-analytics/add-salaryslip',
          element: <AddSalarySlipBusiness />
        },
        {
          path: 'credit-appraisal',
          element: clientId.includes('kcb') ? (
            <CreditAppraisalKCB
              sentUrl={sentUrl}
              parent="credit-appraisal"
              setSidebarUpdate={setSidebarUpdate}
            />
          ) : (
            <CreditAppraisalOther sentUrl={sentUrl} parent="credit-appraisal" />
          )
        },
        {
          path: 'recommendation-cam',
          element: clientId.includes('kcb') ? (
            <RecommendationBusinessKcb sentUrl={sentUrl} />
          ) : (
            <RecommendationBusinessOther sentUrl={sentUrl} />
          )
        },
        {
          path: 'security',
          element: <Mortgage parentComponent="CAM" />,
          children: [{ path: '', element: <Mortgage parentComponent="CAM" /> }]
        },

        {
          path: 'field-visits',
          element: <FieldVisitsReport />
        },
        {
          path: 'valuation-report',
          element: <ValuationReportBusiness />
        },
        {
          path: 'field-investigation',
          element: <FieldInvestigationBusiness />
        },
        {
          path: 'pd-visits',
          element: <PdVisitsBusiness />
        },
        {
          path: 'legal-report',
          element: <LegalReportBusiness />
        },
        {
          path: 'query',
          element: <QueryBusiness />
        },
        {
          path: 'Comments',
          element: <CommentsBusiness />
        },
        {
          path: 'audit-trail',
          element: <AuditTrailBusiness />
        },
        { path: '', element: <DashboardApp /> },
        // { path: 'mis-reports', element: <MISReports /> },

        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      // disbursement routing,
      path: '/sanctioned',
      element: <DisbursementLayout sentUrl={sentUrl} />,
      children: [
        { path: 'decision', element: <DisbursementDecision /> },
        {
          path: 'document',
          element: <DocumentDisburse />
        },
        { path: 'document/dp-note', element: <DPNote /> },
        { path: 'document/dp-note-propietor', element: <DPNotePropietor /> },
        { path: 'document/letter-continuing-security', element: <LetterContinuingSecurity /> },
        { path: 'document/letter-sole-proprietorship', element: <LetterSoleProprietorship /> },
        { path: 'document/general-form-guarantee', element: <GeneralFormGuarantee /> },
        { path: 'document/letter-installment', element: <LetterInstallment /> },
        { path: 'document/deed-hypothecation', element: <DeedHypothecation /> },
        { path: 'document/dp-note-joint-borrower', element: <DPNoteJointBorrower /> },
        { path: 'document/letter-partnership', element: <LetterPartnership /> },
        {
          path: 'document/letter-depositing-fixed-deposit-receipt',
          element: <LetterDepositingFDR />
        },
        { path: 'document/take-delivery-letter', element: <TakeDeliveryLetter /> },
        {
          path: 'document/undertaking-maintain-vehicle-five-years',
          element: <UndertakingMaintainVFY />
        },
        { path: 'document/letter-bp-undertaking-bills', element: <LetterBpUndertakingBills /> },
        { path: 'document/letter-hypothecation-vehicle', element: <LetterHypothecationVehicle /> },
        {
          path: 'document/letter-pledge-share-securities',
          element: <LetterPledgeShareSecurities />
        },
        { path: 'document/nominal-membership', element: <NominalMembership /> },
        {
          path: 'document/agreement-covering-credit-facilities',
          element: <AgreementCoveringCreditFacilities />
        },
        {
          path: 'document/letter-authority-make-payment-directly-dealers',
          element: <LetterAuthorityDirectlyDealers />
        },
        {
          path: 'document/hypothication-machinery',
          element: <HypothicationMachinery />
        },
        {
          path: 'document/instrument-hypothecation-goods',
          element: <InstrumentHypothecationGoods />
        },
        {
          path: 'document/hypothecation-book-debts',
          element: <HypothecationBookDebts />
        },

        {
          path: 'mortgage',
          element: <Mortgage parentComponent="sanctioned" />,
          children: [{ path: '', element: <Mortgage parentComponent="sanctioned" /> }]
        },
        {
          path: 'field-visit',
          element: <FieldVisit />,
          children: [{ path: '', element: <FieldVisit module="field" /> }]
        },
        {
          path: 'account-open',
          element: <FieldVisitAccount />,
          children: [{ path: '', element: <FieldVisit module="accounts" /> }]
        },
        {
          path: 'disbursement',
          element: <Disbursement />,
          children: [{ path: '', element: <Disbursement /> }]
        },
        { path: 'repayment', element: <DisbursementRePayment /> },
        {
          path: 'expenses',
          element: <Expenses />,
          children: [{ path: '', element: <Expenses /> }]
        },
        {
          path: 'borrower',
          element: <Borrower />,
          children: [
            { path: '', element: <Borrower /> },
            { path: 'personal-bio-data', element: <PersonalBioData /> }
          ]
        },
        {
          path: 'task',
          element: <Task sentUrl={sentUrl} />
        },
        {
          path: 'machinery',
          element: <Machinery />
        },
        { path: 'task/view-task', element: <ViewTask /> },
        {
          path: 'recommendation',
          element: <DisbursementRecommendation />
        },
        {
          path: 'creditTask',
          element: <DisbursementRecommendation />
        },
        {
          path: 'application',
          element: <DisbursementRecommendation />
        },
        {
          path: 'creditAppraisal',
          element: <DisbursementRecommendation />
        },
        {
          path: 'security',
          element: <DisbursementRecommendation />
        }
      ]
    },
    {
      // disbursement routing from u-disburse,
      path: '/u-disburse',
      element: <DisbursementLayout sentUrl={sentUrl} />,
      children: [
        { path: 'decision', element: <DisbursementDecision /> },
        {
          path: 'document',
          element: <DocumentDisburse />
        },
        { path: 'document/dp-note', element: <DPNote /> },
        { path: 'document/dp-note-propietor', element: <DPNotePropietor /> },
        { path: 'document/letter-continuing-security', element: <LetterContinuingSecurity /> },
        { path: 'document/letter-sole-proprietorship', element: <LetterSoleProprietorship /> },
        { path: 'document/general-form-guarantee', element: <GeneralFormGuarantee /> },
        { path: 'document/letter-installment', element: <LetterInstallment /> },
        { path: 'document/deed-hypothecation', element: <DeedHypothecation /> },
        { path: 'document/dp-note-joint-borrower', element: <DPNoteJointBorrower /> },
        { path: 'document/letter-partnership', element: <LetterPartnership /> },
        {
          path: 'document/letter-depositing-fixed-deposit-receipt',
          element: <LetterDepositingFDR />
        },
        { path: 'document/take-delivery-letter', element: <TakeDeliveryLetter /> },
        {
          path: 'document/undertaking-maintain-vehicle-five-years',
          element: <UndertakingMaintainVFY />
        },
        { path: 'document/letter-bp-undertaking-bills', element: <LetterBpUndertakingBills /> },
        { path: 'document/letter-hypothecation-vehicle', element: <LetterHypothecationVehicle /> },
        {
          path: 'document/letter-pledge-share-securities',
          element: <LetterPledgeShareSecurities />
        },
        { path: 'document/nominal-membership', element: <NominalMembership /> },
        {
          path: 'document/agreement-covering-credit-facilities',
          element: <AgreementCoveringCreditFacilities />
        },
        {
          path: 'document/letter-authority-make-payment-directly-dealers',
          element: <LetterAuthorityDirectlyDealers />
        },
        {
          path: 'document/hypothication-machinery',
          element: <HypothicationMachinery />
        },
        {
          path: 'document/instrument-hypothecation-goods',
          element: <InstrumentHypothecationGoods />
        },
        {
          path: 'document/hypothecation-book-debts',
          element: <HypothecationBookDebts />
        },

        {
          path: 'mortgage',
          element: <Mortgage />,
          children: [{ path: '', element: <Mortgage /> }]
        },
        {
          path: 'field-visit',
          element: <FieldVisit />,
          children: [{ path: '', element: <FieldVisit /> }]
        },
        {
          path: 'disbursement',
          element: <Disbursement />,
          children: [{ path: '', element: <Disbursement /> }]
        },
        { path: 'repayment', element: <DisbursementRePayment /> },
        {
          path: 'expenses',
          element: <Expenses />,
          children: [{ path: '', element: <Expenses /> }]
        },
        {
          path: 'borrower',
          element: <Borrower />,
          children: [
            { path: '', element: <Borrower /> },
            { path: 'personal-bio-data', element: <PersonalBioData /> }
          ]
        },
        {
          path: 'task',
          element: <Task />,
          children: [
            { path: '', element: <Task /> },
            { path: 'view-task', element: <ViewTask /> }
          ]
        }
      ]
    },
    // salaried start
    {
      path: '/salaried',
      element: <CamLayout />,
      children: [
        { path: 'recommendation-cam', element: <RecommendationSalaried /> },
        { path: 'credit-task', element: <CreditTaskSalaried /> },
        { path: 'credit-task/query', element: <QueryCreditTaskSalaried /> },
        { path: 'application-summary', element: <ApplicationSummarySalaried /> },
        {
          path: 'application-summary/print-download',
          element: <PrintDownloadApplicationSalaried />
        },
        {
          path: 'credit-report',
          element: <CreditReportSalaried />
        },
        {
          path: 'profile',
          element: <ProfileReportSalaried />
        },
        {
          path: 'financial-crime-risk',
          element: <FinancialCrimeRiskSalaried />
        },
        {
          path: 'income-analytics',
          element: <IncomeAnalyticsSalaried />
        },
        {
          path: 'income-analytics/bank-statement',
          element: <BankStatementIncomeSalaried />
        },
        {
          path: 'income-analytics/add-bank-statement',
          element: <AddBankStatementSalaried />
        },
        {
          path: 'income-analytics/add-tax',
          element: <AddTaxFileSalaried />
        },
        {
          path: 'income-analytics/add-salaryslip',
          element: <AddSalarySlipSalaried />
        },
        {
          path: 'credit-appraisal',
          element: <CreditAppraisalSalaried />
        },
        {
          path: 'security-report',
          element: <VerificationReportSalaried />
        },
        {
          path: 'valuation-report',
          element: <ValuationReportSalaried />
        },
        {
          path: 'field-investigation',
          element: <FieldInvestigationSalaried />
        },
        {
          path: 'pd-visits',
          element: <PdVisitsSalaried />
        },
        {
          path: 'legal-report',
          element: <LegalReportSalaried />
        },
        {
          path: 'query',
          element: <QuerySalaried />
        },
        {
          path: 'comments',
          element: <CommentsSalaried />
        },
        {
          path: 'audit-trail',
          element: <AuditTrailSalaried />
        },
        { path: '', element: <DashboardApp /> }
        // { path: 'mis-reports', element: <MISReports /> }
      ]
    },
    {
      path: '/reports',
      element: <ReportManager />
    },
    {
      path: '/update-reports',
      element: <UpdateReportManager />
    },
    {
      path: '/field-report',
      element: <CamLayout />,
      children: [
        {
          path: '',
          element: <FieldReport />
        }
      ]
    },
    {
      path: '/master-parents',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <MasterParentsList sentUrl={sentUrl} /> },
        { path: 'add', element: <AddEditParentMaster /> },
        { path: 'edit/:rowId', element: <AddEditParentMaster /> }
      ]
    },
    {
      path: '/master-child',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <MasterChildList sentUrl={sentUrl} /> },
        { path: 'add', element: <AddEditChildMasterAdmin /> },
        { path: 'edit/:rowId', element: <AddEditChildMasterAdmin /> }
      ]
    },
    {
      path: '/users',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <UsersAdmin sentUrl={sentUrl} /> },
        { path: 'add', element: <AddEditUser sentUrl={sentUrl} /> },
        { path: 'edit/:rowId', element: <AddEditUser sentUrl={sentUrl} /> }
      ]
    },
    {
      path: '/manage-access',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <ManageAccessList sentUrl={sentUrl} /> },
        { path: 'add', element: <AddEditManageAccess sentUrl={sentUrl} /> },
        { path: 'edit/:rowId/:rowModuleName', element: <AddEditManageAccess sentUrl={sentUrl} /> }
      ]
    },
    {
      path: '/cards',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <Cards sentUrl={sentUrl} /> },
        {
          path: 'add',
          element: <AddNewCard parent="/cards" sentUrl={sentUrl} isEdit="false" isView="false" />
        },
        { path: 'edit', element: <AddNewCard parent="/cards" sentUrl={sentUrl} isEdit="true" /> },
        { path: 'manage-cards', element: <ManageCards sentUrl={sentUrl} /> },
        { path: 'language', element: <Language sentUrl={sentUrl} /> },
        {
          path: 'view-data-capture',
          element: <AddNewCard parent="/cards" sentUrl={sentUrl} isView="true" isEdit="false" />
        }
      ]
    },
    {
      path: '/journey',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <Journey sentUrl={sentUrl} /> },
        { path: 'add', element: <AddJourney /> },
        { path: 'setting', element: <JourneySetting sentUrl={sentUrl} parent="journey" /> },
        { path: 'configure', element: <JourneyConfigure sentUrl={sentUrl} parent="journey" /> },
        { path: 'template', element: <JourneyTemplate sentUrl={sentUrl} /> },
        { path: 'landing-page', element: <JourneyConfigureLanding sentUrl={sentUrl} /> },
        { path: 'view', element: <ViewJourney /> },
        { path: 'publish', element: <JourneyPublish /> },
        { path: 'viewTemplate', element: <ViewJourneyTemplate sentUrl={sentUrl} /> }
      ]
    },
    {
      path: '/reports',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        {
          path: '',
          // element: clientId.includes('kcb') ? (
          //   <ReportsKcb sentUrl={sentUrl} />
          // ) : (
          //   <Reports sentUrl={sentUrl} />
          // )
          element: <Reports sentUrl={sentUrl} />
        },
        {
          path: 'add',
          // element: clientId.includes('kcb') ? (
          //   <AddEditReportsKcb sentUrl={sentUrl} />
          // ) : (
          //   <AddEditReports sentUrl={sentUrl} />
          // )
          element: <AddEditReports sentUrl={sentUrl} />
        },
        {
          path: 'cards',
          children: [
            {
              path: '',
              // element: clientId.includes('kcb') ? (
              //   <ReportsCardsKcb sentUrl={sentUrl} />
              // ) : (
              //   <ReportsCards sentUrl={sentUrl} />
              // )
              element: <ReportsCards sentUrl={sentUrl} />
            },
            {
              path: 'edit-cards',
              // element: clientId.includes('kcb') ? (
              //   <EditCardsKcb sentUrl={sentUrl} />
              // ) : (
              //   <EditCards sentUrl={sentUrl} />
              // )
              element: <EditCards sentUrl={sentUrl} />
            }
          ]
        },
        {
          path: 'edit',
          // element: clientId.includes('kcb') ? (
          //   <AddEditReportsKcb sentUrl={sentUrl} />
          // ) : (
          //   <AddEditReports sentUrl={sentUrl} />
          // )
          element: <AddEditReports sentUrl={sentUrl} />
        }
      ]
    },
    {
      path: '/securities', // klass web security
      element: <DashboardLayout />,
      children: [
        { path: '', element: <SecurityReport /> }
        // { path: 'view-securities', element: <ViewSecurities /> }
      ]
    },
    {
      path: '/security',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <Security sentUrl={sentUrl} /> },
        {
          path: 'map-document',
          children: [
            { path: 'add', element: <MapDocument sentUrl={sentUrl} isEdit="false" /> },
            { path: 'edit', element: <MapDocument sentUrl={sentUrl} isEdit="true" /> }
          ]
        },
        // {
        //   path: 'cards',
        //   children: [
        //     { path: '', element: <ReportsCards sentUrl={sentUrl} /> },
        //     { path: 'edit-cards', element: <EditCards sentUrl={sentUrl} /> }
        //   ]
        // },

        {
          path: 'manage-document',
          children: [
            { path: '', element: <ManageDocument sentUrl={sentUrl} /> },
            { path: 'add-document', element: <AddDocument sentUrl={sentUrl} /> }
          ]
        }
      ]
    },
    {
      path: '/api-master',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <APIMaster sentUrl={sentUrl} /> },
        { path: 'add', element: <AddAPIMaster sentUrl={sentUrl} /> },
        { path: 'edit', element: <AddAPIMaster sentUrl={sentUrl} /> },
        { path: 'view', element: <ViewAPIMaster sentUrl={sentUrl} /> }
      ]
    },
    {
      path: '/master',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <MasterList /> },
        { path: 'add', element: <AddEditMaster /> },
        { path: 'edit/:rowId', element: <AddEditMaster /> }
      ]
    },
    {
      path: '/',
      element: <Login />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/docucheck',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <DOCUCheck sentUrl={sentUrl} /> },
        { path: 'configure', element: <Configure sentUrl={sentUrl} /> }
      ]
    },
    {
      path: '/billing',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <NotFound /> },
        { path: '404', element: <NotFound /> }
      ]
    },
    {
      path: '/assign',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        {
          path: '',
          element: <Assign />
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);

  const adminRoutes = useRoutes([
    {
      path: '/master-parents',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <MasterParentsList sentUrl={sentUrl} /> },
        { path: 'add', element: <AddEditParentMaster /> },
        { path: 'edit/:rowId', element: <AddEditParentMaster /> }
      ]
    },
    {
      path: '/master-child',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <MasterChildList sentUrl={sentUrl} /> },
        { path: 'add', element: <AddEditChildMasterAdmin /> },
        { path: 'edit/:rowId', element: <AddEditChildMasterAdmin /> }
      ]
    },
    {
      path: '/users',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <UsersAdmin sentUrl={sentUrl} /> },
        { path: 'add', element: <AddEditUser sentUrl={sentUrl} /> },
        { path: 'edit/:rowId', element: <AddEditUser sentUrl={sentUrl} /> }
      ]
    },
    {
      path: '/manage-access',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <ManageAccessList sentUrl={sentUrl} /> },
        { path: 'add', element: <AddEditManageAccess sentUrl={sentUrl} /> },
        { path: 'edit/:rowId/:rowModuleName', element: <AddEditManageAccess sentUrl={sentUrl} /> }
      ]
    },
    {
      path: '/cards',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <Cards sentUrl={sentUrl} /> },
        {
          path: 'add',
          element: <AddNewCard parent="/cards" sentUrl={sentUrl} isEdit="false" isView="false" />
        },
        { path: 'edit', element: <AddNewCard parent="/cards" sentUrl={sentUrl} isEdit="true" /> },
        { path: 'manage-cards', element: <ManageCards sentUrl={sentUrl} /> },
        { path: 'language', element: <Language sentUrl={sentUrl} /> },
        {
          path: 'view-data-capture',
          element: <AddNewCard parent="/cards" sentUrl={sentUrl} isView="true" isEdit="false" />
        }
      ]
    },
    {
      path: '/journey',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <Journey sentUrl={sentUrl} /> },
        { path: 'add', element: <AddJourney /> },
        { path: 'setting', element: <JourneySetting sentUrl={sentUrl} parent="journey" /> },
        { path: 'configure', element: <JourneyConfigure sentUrl={sentUrl} parent="journey" /> },
        { path: 'template', element: <JourneyTemplate sentUrl={sentUrl} /> },
        { path: 'landing-page', element: <JourneyConfigureLanding sentUrl={sentUrl} /> },
        { path: 'view', element: <ViewJourney /> },
        { path: 'publish', element: <JourneyPublish /> },
        { path: 'viewTemplate', element: <ViewJourneyTemplate sentUrl={sentUrl} /> }
      ]
    },
    {
      path: '/reports',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        {
          path: '',
          // element: clientId.includes('kcb') ? (
          //   <ReportsKcb sentUrl={sentUrl} />
          // ) : (
          //   <Reports sentUrl={sentUrl} />
          // )
          element: <Reports sentUrl={sentUrl} />
        },
        {
          path: 'add',
          // element: clientId.includes('kcb') ? (
          //   <AddEditReportsKcb sentUrl={sentUrl} />
          // ) : (
          //   <AddEditReports sentUrl={sentUrl} />
          // )
          element: <AddEditReports sentUrl={sentUrl} />
        },
        {
          path: 'cards',
          children: [
            {
              path: '',
              // element: clientId.includes('kcb') ? (
              //   <ReportsCardsKcb sentUrl={sentUrl} />
              // ) : (
              //   <ReportsCards sentUrl={sentUrl} />
              // )
              element: <ReportsCards sentUrl={sentUrl} />
            },
            {
              path: 'edit-cards',
              // element: clientId.includes('kcb') ? (
              //   <EditCardsKcb sentUrl={sentUrl} />
              // ) : (
              //   <EditCards sentUrl={sentUrl} />
              // )
              element: <EditCards sentUrl={sentUrl} />
            }
          ]
        },
        {
          path: 'edit',
          // element: clientId.includes('kcb') ? (
          //   <AddEditReportsKcb sentUrl={sentUrl} />
          // ) : (
          //   <AddEditReports sentUrl={sentUrl} />
          // )
          element: <AddEditReports sentUrl={sentUrl} />
        }
      ]
    },
    {
      path: '/security',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <Security sentUrl={sentUrl} /> },
        {
          path: 'map-document',
          children: [
            { path: 'add', element: <MapDocument sentUrl={sentUrl} isEdit="false" /> },
            { path: 'edit', element: <MapDocument sentUrl={sentUrl} isEdit="true" /> }
          ]
        },
        // {
        //   path: 'cards',
        //   children: [
        //     { path: '', element: <ReportsCards sentUrl={sentUrl} /> },
        //     { path: 'edit-cards', element: <EditCards sentUrl={sentUrl} /> }
        //   ]
        // },

        {
          path: 'manage-document',
          children: [
            { path: '', element: <ManageDocument sentUrl={sentUrl} /> },
            { path: 'add-document', element: <AddDocument sentUrl={sentUrl} /> }
          ]
        }
      ]
    },
    {
      path: '/api-master',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <APIMaster sentUrl={sentUrl} /> },
        { path: 'add', element: <AddAPIMaster sentUrl={sentUrl} /> },
        { path: 'edit', element: <AddAPIMaster sentUrl={sentUrl} /> },
        { path: 'view', element: <ViewAPIMaster sentUrl={sentUrl} /> }
      ]
    },
    {
      path: '/master',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <MasterList /> },
        { path: 'add', element: <AddEditMaster /> },
        { path: 'edit/:rowId', element: <AddEditMaster /> }
      ]
    },
    {
      path: '/',
      element: <Login />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/docucheck',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <DOCUCheck sentUrl={sentUrl} /> },
        { path: 'configure', element: <Configure sentUrl={sentUrl} /> }
      ]
    },
    {
      path: '/billing',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        { path: '', element: <NotFound /> },
        { path: '404', element: <NotFound /> }
      ]
    },
    {
      path: '/assign',
      element: <DashboardLayoutAdmin sentUrl={sentUrl} />,
      children: [
        {
          path: '',
          element: <Assign />
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);

  return (
    <>
      {' '}
      {/* {showPopup && (
        <div className="popup">
          <p>Your session is about to expire. Do you want to extend your session?</p>
          <button onClick={handlePopupOk}>OK</button>
          <button onClick={handlePopupCancel}>Cancel</button>
        </div>
      )} */}
      <Box>
        <Dialog open={showInactivityPopup.show} onClose={handleClose}>
          <DialogTitle>Session Timeout Warning</DialogTitle>
          <DialogContent>{popupMessage}</DialogContent>
          <DialogActions>
            <Button onClick={handleStayLoggedIn} color="primary">
              Yes
            </Button>
            <Button onClick={handleLogout} color="secondary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <ApiErrorMessage
        openApiError={openApiError}
        setOpenApiError={setOpenApiError}
        errorMessage={errorMessage}
        userTokenExpire={userTokenExpire}
        sessionTimeout="sessiontimeout"
      />
      {/* {loginUserType === 'klass' ? klassRoutes : adminRoutes} */}
      {klassRoutes}
    </>
  );
}
