import React from 'react';

import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';

function DailogBox({ openpopup, setOpenpop, setEmailData, emailData, handleSubmitEmail }) {
  return (
    <Box>
      <Dialog
        open={openpopup}
        onClose={(e) => setOpenpop(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent fullWidth>
          <DialogContentText id="alert-dialog-description">
            <TextField
              fullWidth
              inputProps={{ className: 'textInput' }}
              type="email"
              placeholder="Enter your email "
              value={emailData}
              onChange={(e) => {
                setEmailData(e.target.value);
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="mainBtn"
            onClick={(e) => {
              handleSubmitEmail(e);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DailogBox;
