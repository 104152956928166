import { useState, Suspense } from 'react';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import Loader from './pages/Global/Loader';

// ----------------------------------------------------------------------

export default function App() {
  const [openLoader, setOpenLoader] = useState(true);
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Suspense fallback={<Loader openLoader={openLoader} />}>
        <Router />
      </Suspense>
    </ThemeConfig>
  );
}
