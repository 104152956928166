const initialState = {
  reportTopics: [],
  isReportTopicsTrue: '',
  reportTopicsTabs: {},
  proposalApprovalDetails: {},
  reGenerate: null
};
const camReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REPORT_TOPICS':
      return {
        ...state,
        reportTopics: action?.payload?.data
      };
    case 'REPORT_TOPICS_TRUE':
      return {
        ...state,
        reportTopics: action?.payload?.data
      };
    case 'REPORT_TOPICS_TABS':
      return {
        ...state,
        reportTopicsTabs: action?.payload?.data
      };
    case 'PROPOSAL_APPROVAL_DETAILS':
      return {
        ...state,
        proposalApprovalDetails: action?.payload?.data
      };
    case 'DISABLE_MENU':
      console.log('isMenuDissable', action?.payload?.data);
      return {
        ...state,
        reGenerate: action?.payload?.data
      };

    default:
      return state;
  }
};

export default camReducer;
