import { format, formatDistanceToNow, parse } from 'date-fns';
import { date } from 'yup/lib/locale';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMM yyyy');
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  console.log(
    'formattime423432',
    `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${meridiem}`
  );
  return `${day}-${month}-${year} ( ${hours}:${minutes}:${seconds} ${meridiem} )`;
}

export function fDateOfBirth(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function changeDateFormat(inputDate) {
  const parts = inputDate?.split('-'); // Tareekh ko "-" se alag karen
  if (parts?.length === 3) {
    const [year, month, day] = parts;
    const newDate = `${day}/${month}/${year}`;
    return newDate;
  } else {
    return 'Invalid Date'; // Agar input tareekh sahi format mein nahi hai
  }
}

export function fDate1(date) {
  return format(new Date(date), 'dd MMM, yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTime3(date) {
  return format(new Date(date), 'dd MMM yyyy, hh:mm:ssa');
}

export function fDateTime1(date) {
  return format(new Date(date), 'dd MMM, yyyy/hh:mm:ss a');
}
export function fDateTime5(date) {
  return format(new Date(date), 'yyyy-MM-dd#  (h:mm:ss a)').replace('#', '');
}

export function fDateTime2(date) {
  return format(new Date(date), 'yyyy-MM-dd#HH:mm:ss').replace('#', 'T');
}

export function fDateTime4(date) {
  return format(new Date(date), 'yyyy-MM-dd#HH:mm').replace('#', 'T');
}

export function fDate2(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDate3(date) {
  return format(new Date(date), 'MMM yyyy');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function duration(t0, t1) {
  // eslint-disable-next-line
  const EVAL_IS_BAD__AVOID_THIS = eval;
  const d = new Date(t1) - new Date(t0);
  const weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
  const days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
  const hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
  const minutes = Math.floor(d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60);
  const seconds = Math.floor(
    d / 1000 - weekdays * 7 * 24 * 60 * 60 - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60
  );
  const milliseconds = Math.floor(
    d -
      weekdays * 7 * 24 * 60 * 60 * 1000 -
      days * 24 * 60 * 60 * 1000 -
      hours * 60 * 60 * 1000 -
      minutes * 60 * 1000 -
      seconds * 1000
  );
  const t = {};
  ['weekdays', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'].forEach((q) => {
    if (EVAL_IS_BAD__AVOID_THIS(q) > 0) {
      t[q] = EVAL_IS_BAD__AVOID_THIS(q);
    }
  });
  return t;
}
